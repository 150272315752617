import React, { useState, useEffect, useContext } from "react";
import SimpleBar from 'simplebar-react';
import { useLocation } from "react-router-dom";
import { isMobile } from "react-device-detect";
import { CSSTransition } from 'react-transition-group';
import { ArchiveIcon, CalendarIcon, ChartBarIcon, ChartPieIcon, ChevronRightIcon, LoginIcon, BriefcaseIcon, ClipboardListIcon, CogIcon, CreditCardIcon, InboxIcon, InformationCircleIcon, LocationMarkerIcon, NewspaperIcon, TableIcon, TemplateIcon, UsersIcon, FolderDownloadIcon, XIcon, OfficeBuildingIcon, DocumentDownloadIcon } from "@heroicons/react/solid";
import { LogoutIcon } from "@heroicons/react/outline";
import { Nav, Badge, Image, Button, Dropdown, Navbar, Collapse, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { Auth } from 'aws-amplify';
import { useHistory } from "react-router-dom";
import { Routes } from "routes";
import ReactHero from "assets/img/brand/harvast-logo.svg";
import ProfilePicture from "assets/img/team/profile-picture-3.jpg";
import { UserContext } from 'Context/UserContext';
import { LeadsDataContext } from "Context/LeadsDataContext";
import { AgencyContext } from "Context/AgencyContext";
import { AppointmentsDataContext } from "Context/AppointmentsDataContext";
import { AgencyLeadsDataContext } from "Context/AgencyLeadsContext";

export default (props = {}) => {
  const location = useLocation();
  const { pathname } = location;
  const [show, setShow] = useState(false);
  const [collapsedItems, setCollapsedItems] = useState([pathname]);
  const contracted = props.contracted ? "contracted" : "";
  const showClass = show ? "show" : "";
  const [loggedUser, setLoggedUser] = useContext(UserContext);
  const [leadsData, setLeadsData] = useContext(LeadsDataContext);
  const [AgencyAgentInfo, setAgencyAgentInfo] = useContext(AgencyContext);
  const [appointmentsData, setAppointmentsData] = useContext(AppointmentsDataContext);
  const [agencyleadsData, setAgencyLeadsData] = useContext(AgencyLeadsDataContext);
  const history = useHistory();

  const onCollapse = () => setShow(!show);
  const onMouseEnter = () => props.onMouseEnter && props.onMouseEnter();
  const onMouseLeave = () => props.onMouseLeave && props.onMouseLeave();

  const onNavItemCollapse = (itemKey) => {
    const isCollapsed = collapsedItems.some(item => item.includes(itemKey));
    const newCollapsedItems = isCollapsed ? collapsedItems.filter(item => !item.includes(itemKey)) : [...collapsedItems, itemKey];
    setCollapsedItems(newCollapsedItems);
  };

  // const loggedUserName = loggedUser.licenseDetails.customerName;

  const events = isMobile ? {} : { onMouseEnter, onMouseLeave };

  const CollapsableNavItem = (props) => {
    const { eventKey, title, icon: NavItemIcon, children = null } = props;
    const isOpened = collapsedItems.some(item => item.includes(eventKey));

    return (
      <Nav.Item>
        <Nav.Link
          onClick={() => onNavItemCollapse(eventKey)}
          aria-expanded={isOpened}
          aria-controls={eventKey}
          className="d-flex justify-content-between align-items-center"
        >
          <span>
            <span className="sidebar-icon">
              <NavItemIcon className="icon icon-xs me-2" />
            </span>
            <span className="sidebar-text">
              {title}
            </span>
          </span>
          <span className="link-arrow">
            <ChevronRightIcon className="icon icon-sm" />
          </span>
        </Nav.Link>
        <Collapse in={isOpened} className="multi-level">
          <div id={eventKey}>
            {children}
          </div>
        </Collapse>
      </Nav.Item>
    );
  };

  const NavItem = (props) => {
    const { title, link, target, icon: NavItemIcon, image, badgeText, badgeBg, badgeColor = "white" } = props;
    const classNames = badgeText ? "d-flex align-items-center justify-content-between" : "";
    const navItemClassName = link === pathname ? "active" : "";

    return (
      <Nav.Item className={navItemClassName} onClick={() => setShow(false)}>
        <Nav.Link as={Link} to={link} target={target} className={classNames}>
          <span>
            {NavItemIcon && (
              <span className="sidebar-icon">
                <NavItemIcon className="icon icon-xs me-2" />
              </span>
            )}

            {image ? <Image src={image} width={20} height={20} className="sidebar-icon svg-icon" /> : null}

            {!show && contracted && !NavItemIcon && !image ? (
              <span className="sidebar-text-contracted">
                {title[0]}
              </span>
            ) : null}

            <span className="sidebar-text">{title}</span>
          </span>

          {badgeText ? (
            <Badge pill bg={badgeBg} text={badgeColor} className="badge-sm notification-count">
              {badgeText}
            </Badge>
          ) : null}
        </Nav.Link>
      </Nav.Item>
    );
  };

    const userSubscriptionStatus = () => {

      try{
        if (loggedUser.licenseDetails.allotedSeats > 1 && !loggedUser.licenseDetails.subscriptionStatus.toLowerCase().includes("cancelled") && !loggedUser.licenseDetails.licenseType.includes("free")){
          return <Nav className="flex-column pt-3 pt-md-0">
          {/* <NavItem title="Qanvass Dashboard" link={Routes.DashboardOverview.path} image={ReactHero} /> */}
    
          {/* <CollapsableNavItem eventKey="dashboard/" title="Dashboard" icon={ChartBarIcon}>
            <NavItem title="Overview" link={Routes.DashboardOverview.path} />
            <NavItem title="All Traffic" link={Routes.DashboardTraffic.path} />
            <NavItem title="Product Analysis" link={Routes.DashboardProductAnalysis.path} />
          </CollapsableNavItem> */}

          <CollapsableNavItem eventKey="dashboard/" title="Dashboard" icon={ChartBarIcon}>
            <NavItem title="My Stats" icon={ChartBarIcon} link={Routes.DashboardOverview.path} />
            <NavItem title="My Agency" icon={OfficeBuildingIcon} link={Routes.DashboardAgencies.path} />
          </CollapsableNavItem>
    
          {/* <NavItem title="Kanban" icon={ViewGridIcon} link={Routes.Kanban.path} /> */}
          {/* <NavItem title="Messages" icon={InboxIcon} badgeText="4" badgeBg="danger" link={Routes.Messages.path} /> */}
          {/* <NavItem title="Dashboard" icon={ChartBarIcon} link={Routes.DashboardOverview.path} /> */}
          {/* <NavItem title="Manage Users" icon={UsersIcon} link={Routes.Users.path} /> */}
          <NavItem title="Manage Team" icon={UsersIcon} link={Routes.ManageTeam.path} />
          <NavItem title="Payment History" icon={CreditCardIcon} link={Routes.Transactions.path} />
          <NavItem title="Profile" icon={CogIcon} link={Routes.Settings.path} />
          {/* <NavItem title="Invoice" icon={BriefcaseIcon} link={Routes.Invoice.path} /> */}
          {/* <NavItem title="Task List" icon={ClipboardListIcon} link={Routes.Tasks.path} /> */}
          {/* <NavItem title="Calendar" icon={CalendarIcon} link={Routes.Calendar.path} /> */}
          {/* <NavItem title="Map" icon={LocationMarkerIcon} link={Routes.Map.path} /> */}
          {/* <NavItem title="Widgets" icon={TemplateIcon} link={Routes.Widgets.path} /> */}
    
          {/* <CollapsableNavItem eventKey="tables/" title="Tables" icon={TableIcon}>
            <NavItem title="DataTables" link={Routes.Datatables.path} />
            <NavItem title="Bootstrap Tables" link={Routes.BootstrapTables.path} />
          </CollapsableNavItem> */}
    
          {/* <CollapsableNavItem eventKey="examples/" title="Page Examples" icon={NewspaperIcon}>
            <NavItem title="Pricing" link={Routes.Pricing.path} />
            <NavItem title="Billing" link={Routes.Billing.path} />
            <NavItem title="Invoice" link={Routes.Invoice.path} />
            <NavItem title="Sign In" link={Routes.Signin.path} />
            <NavItem title="Sign Up" link={Routes.Signup.path} />
            <NavItem title="Forgot password" link={Routes.ForgotPassword.path} />
            <NavItem title="Reset password" link={Routes.ResetPassword.path} />
            <NavItem title="Lock" link={Routes.Lock.path} />
            <NavItem title="404 Not Found" link={Routes.NotFound.path} />
            <NavItem title="500 Server Error" link={Routes.ServerError.path} />
          </CollapsableNavItem> */}
    
          <Dropdown.Divider className="my-3 border-indigo" />
    
          {/* <CollapsableNavItem eventKey="documentation/" title="Getting Started" icon={InformationCircleIcon}>
            <NavItem title="Overview" link={Routes.DocsOverview.path} />
            <NavItem title="Download" link={Routes.DocsDownload.path} />
            <NavItem title="Quick Start" link={Routes.DocsQuickStart.path} />
            <NavItem title="License" link={Routes.DocsLicense.path} />
            <NavItem title="Folder Structure" link={Routes.DocsFolderStructure.path} />
            <NavItem title="Build Tools" link={Routes.DocsBuild.path} />
            <NavItem title="Changelog" link={Routes.DocsChangelog.path} />
          </CollapsableNavItem> */}
    
          {/* <CollapsableNavItem eventKey="components/" title="Components" icon={ArchiveIcon}>
            <NavItem title="Accordion" link={Routes.Accordions.path} />
            <NavItem title="Alerts" link={Routes.Alerts.path} />
            <NavItem title="Badges" link={Routes.Badges.path} />
            <NavItem title="Breadcrumbs" link={Routes.Breadcrumbs.path} />
            <NavItem title="Buttons" link={Routes.Buttons.path} />
            <NavItem title="Forms" link={Routes.Forms.path} />
            <NavItem title="Modals" link={Routes.Modals.path} />
            <NavItem title="Navbars" link={Routes.Navbars.path} />
            <NavItem title="Navs" link={Routes.Navs.path} />
            <NavItem title="Pagination" link={Routes.Pagination.path} />
            <NavItem title="Popovers" link={Routes.Popovers.path} />
            <NavItem title="Progress" link={Routes.Progress.path} />
            <NavItem title="Tables" link={Routes.Tables.path} />
            <NavItem title="Tabs" link={Routes.Tabs.path} />
            <NavItem title="Toasts" link={Routes.Toasts.path} />
            <NavItem title="Tooltips" link={Routes.Tooltips.path} />
            <NavItem title="Widgets" link={Routes.WidgetsComponent.path} />
          </CollapsableNavItem> */}
    
          {/* <CollapsableNavItem eventKey="plugins/" title="Plugins" icon={ChartPieIcon}>
            <NavItem title="Charts" link={Routes.PluginCharts.path} />
            <NavItem title="Calendar" link={Routes.PluginCalendar.path} />
            <NavItem title="DataTable" link={Routes.PluginDatatable.path} />
            <NavItem title="Map" link={Routes.PluginMap.path} />
            <NavItem title="DropZone" link={Routes.PluginDropzone.path} />
            <NavItem title="SweetAlert" link={Routes.PluginSweetAlert.path} />
          </CollapsableNavItem> */}
    
        </Nav>;
       }else if (loggedUser.licenseDetails.allotedSeats == 1 && !loggedUser.licenseDetails.subscriptionStatus.toLowerCase().includes("cancelled") && !loggedUser.licenseDetails.licenseType.includes("free")){
         return <Nav className="flex-column pt-3 pt-md-0">
         <CollapsableNavItem eventKey="dashboard/" title="Dashboard" icon={ChartBarIcon}>
            <NavItem title="My Stats" icon={ChartBarIcon} link={Routes.DashboardOverview.path} />
          </CollapsableNavItem>
         <NavItem title="Payment History" icon={CreditCardIcon} link={Routes.Transactions.path} />
         <NavItem title="Profile" icon={CogIcon} link={Routes.Settings.path} />
         <Dropdown.Divider className="my-3 border-indigo" />
       </Nav>;
       } else if ((loggedUser.licenseDetails.allotedSeats == 1 && loggedUser.licenseDetails.subscriptionStatus.toLowerCase().includes("cancelled")) || (loggedUser.licenseDetails.allotedSeats > 1 && loggedUser.licenseDetails.subscriptionStatus.toLowerCase().includes("cancelled")) ){
         return <NavItem title="Cancelled" icon={ChartBarIcon} link={Routes.DashboardOverview.path} />
       } else if (loggedUser.licenseDetails.allotedSeats == 1 && !loggedUser.licenseDetails.subscriptionStatus.toLowerCase().includes("cancelled") && loggedUser.licenseDetails.licenseType.includes("free")){
        return <Nav className="flex-column pt-3 pt-md-0">
          <NavItem title="My Stats" icon={ChartBarIcon} link={Routes.DashboardOverview.path} />
          <NavItem title="Welcome" icon={DocumentDownloadIcon} link={Routes.Welcome.path} />
          <NavItem title="Profiles" icon={CogIcon} link={Routes.Settings.path} />
          <Dropdown.Divider className="my-3 border-indigo" />
      </Nav>;
      } else{
         return <Nav className="flex-column pt-3 pt-md-0">
         <NavItem title="Payment History" icon={CreditCardIcon} link={Routes.Transactions.path} />
         <NavItem title="My Stats" icon={ChartBarIcon} link={Routes.DashboardOverview.path} />
         <Dropdown.Divider className="my-3 border-indigo" />
       </Nav>;
       }
      }catch(error){
        return <Nav className="flex-column pt-3 pt-md-0">
          <NavItem title="Welcome" icon={CreditCardIcon} link={Routes.Welcome.path} />
         <Dropdown.Divider className="my-3 border-indigo" />
       </Nav>;
      }
  }

  async function signOut() {
    try {
        await Auth.signOut();
        await setLoggedUser({
          isAuthenticated: false,
          user: null,
          licenseDetails: null
        });
        await setLeadsData(null);
        await setAgencyAgentInfo({});
        await setAppointmentsData(null);
        await setAgencyLeadsData(null);
        history.push(Routes.Signin.path);
    } catch (error) {
        console.log('error signing out: ', error);
    }
  }

  return (
    <>
      <Navbar as={Col} xs={12} expand={false} collapseOnSelect variant="dark" className="navbar-theme-primary px-4 d-lg-none">
        <Navbar.Brand as={Link} to={Routes.DashboardOverview.path} className="me-lg-5">
          <Image src={ReactHero} className="navbar-brand-dark" />
        </Navbar.Brand>
        <div className="d-flex align-items-center">
          <Navbar.Toggle as={Button} onClick={onCollapse}>
            <span className="navbar-toggler-icon" />
          </Navbar.Toggle>
        </div>
      </Navbar>
      <CSSTransition timeout={300} in={show} classNames="sidebar-transition">
        <SimpleBar {...events} className={`${showClass} sidebar d-lg-block bg-gray-800 text-white collapse`}>
          <div className="sidebar-inner px-4 pt-3">
            <div className="user-card d-flex d-md-none justify-content-between justify-content-md-center pb-4">
              <div className="d-flex align-items-center">
                <div className="avatar-lg me-4">
                  <Image src={ProfilePicture} className="card-img-top rounded-circle border-white" />
                </div>
                <div className="d-block">
                  <h5 className="mb-3">Hi,</h5>
                  <Button variant="secondary" size="sm" onClick={signOut} className="d-inline-flex align-items-center">
                    <LogoutIcon className="icon icon-xxs me-1" /> Sign Out
                  </Button>
                </div>
              </div>
              <Nav.Link className="collapse-close d-md-none" onClick={onCollapse}>
                <XIcon className="icon icon-xs" />
              </Nav.Link>
            </div>
            {/* display menu depending on subsribtion status */}
            {
                userSubscriptionStatus()
            }
          </div>
        </SimpleBar>
      </CSSTransition>
    </>
  );
};
