
import React, { useState, useContext, useEffect } from 'react';
import { Route, Switch, Redirect } from "react-router-dom";
import { Routes } from "routes";
import { Auth } from "aws-amplify"


// pages
import Presentation from "pages/Presentation";
import DashboardOverview from "pages/dashboard/DashboardOverview";
import DashboardAgencies from "pages/dashboard/DashboardAgencies";
import DashboardTraffic from "pages/dashboard/DashboardTraffic";
import DashboardProductAnalysis from "pages/dashboard/DashboardProductAnalysis";
import AgentDashboard from "pages/agent/Stats";
import Welcome from "pages/welcome/Welcome";
import Kanban from 'pages/Kanban';
import Messages from "pages/Messages";
import SingleMessage from "pages/SingleMessage";
import Users from "pages/Users";
import Transactions from "pages/Transactions";
import CreateAgent from "pages/CreateAgent";
import PaymentSuccesful from "pages/PaymentSuccesful";
import CreateAgency from "pages/CreateAgency";
import CreateAgencyAgent from "pages/CreateAgencyAgent";
import ManageTeam from "pages/ManageTeam";
import CreateTeam from "pages/Agency/CreateTeam";
import Cancelled from "pages/Cancelled";
import Tasks from "pages/Tasks";
import Settings from "pages/Settings";
import Calendar from "pages/Calendar";
import MapPage from "pages/Map";
import Datatables from "pages/tables/Datatables";
import BootstrapTables from "pages/tables/BootstrapTables";
import Pricing from "pages/examples/Pricing";
import Billing from "pages/examples/Billing";
import Invoice from "pages/examples/Invoice";
import Signin from "pages/Auth/Signin";
import Signup from "pages/Auth/Signup";
import PreOrder from "pages/pre-order/Register";
import ForgotPassword from "pages/Auth/ForgotPassword";
import ResetPassword from "pages/Auth/ResetPassword";
import GetCode from "pages/Auth/GetCode";
import SendCode from "pages/Auth/SendCode";
import Lock from "pages/examples/Lock";
import Widgets from "pages/examples/Widgets";
import NotFoundPage from "pages/examples/NotFound";
import ServerError from "pages/examples/ServerError";

// documentation pages
import DocsOverview from "pages/documentation/DocsOverview";
import DocsDownload from "pages/documentation/DocsDownload";
import DocsQuickStart from "pages/documentation/DocsQuickStart";
import DocsLicense from "pages/documentation/DocsLicense";
import DocsFolderStructure from "pages/documentation/DocsFolderStructure";
import DocsBuild from "pages/documentation/DocsBuild";
import DocsChangelog from "pages/documentation/DocsChangelog";

// plugin pages
import PluginCharts from "pages/plugins/Charts";
import PluginCalendar from "pages/plugins/Calendar";
import PluginDatatable from "pages/plugins/Datatable";
import PluginMap from "pages/plugins/Map";
import PluginDropzone from "pages/plugins/Dropzone";
import PluginSweetAlert from "pages/plugins/SweetAlert";

// components
import Sidebar from 'components/Sidebar';
import Footer from 'components/Footer';
import Topbar from 'components/Topbar';

import Accordion from "pages/components/Accordion";
import Alerts from "pages/components/Alerts";
import Badges from "pages/components/Badges";
import Breadcrumbs from "pages/components/Breadcrumbs";
import Buttons from "pages/components/Buttons";
import Forms from "pages/components/Forms";
import Modals from "pages/components/Modals";
import Navs from "pages/components/Navs";
import Navbars from "pages/components/Navbars";
import Pagination from "pages/components/Pagination";
import Popovers from "pages/components/Popovers";
import Progress from "pages/components/Progress";
import Tables from "pages/components/Tables";
import Tabs from "pages/components/Tabs";
import Tooltips from "pages/components/Tooltips";
import Toasts from "pages/components/Toasts";
import WidgetsComponent from "pages/components/Widgets";
import { UserContext, UserProvider } from 'Context/UserContext';
import { IndividualAgentProvider } from "Context/IndividualAgentContext";
import { AgencyContextProvider } from 'Context/AgencyContext';
import { LeadsDataContextProvider } from 'Context/LeadsDataContext';
import { AgencyLeadsDataContextProvider } from 'Context/AgencyLeadsContext';
import { AppointmentsDataContextProvider } from 'Context/AppointmentsDataContext';

const RouteWithSidebar = ({ component: Component, authorize, ...rest }) => {
  
  const resize = () => {
    var resize = setInterval(() => {
      window.dispatchEvent(new Event('resize'));
    }, 10);
    setTimeout(function () {
      clearInterval(resize);
    }, 301);
  }

  const localStorageIsContracted = () => {
    return localStorage.getItem('sidebarContracted') === 'false' ? false : true
  }

  const localStorageIsSettingsVisible = () => {
    return localStorage.getItem('settingsVisible') === 'false' ? false : true
  }


  const [contracted, setContracted] = useState(localStorageIsContracted());
  const [contractSidebar, setContractSidebar] = useState(localStorageIsContracted());
  const [showSettings, setShowSettings] = useState(localStorageIsSettingsVisible);
  const [isAuthenticating, setIsAuthenticating] = useState(true);
  const [permissions_set, setPermissions_Set] = useState(1);
  const [loggedUser, setLoggedUser] = useContext(UserContext);
  const [fetcherror, setFetchError] = useState(null);

    useEffect(() => {
      checkAuthStatus();
    }, []);

    useEffect(() => {
      if(loggedUser.user === null){
        return
      }
       getSubscriptions(); 
    }, [loggedUser.user]);

    console.log(loggedUser);


    async function checkAuthStatus () {
      try{
          const session = await Auth.currentSession();
          const user = await Auth.currentAuthenticatedUser();
          setLoggedUser({
            ...loggedUser,
            isAuthenticated: true,
            user: user
        })
      }catch(error){
          console.log(error);
          setIsAuthenticating(false);
      }
  }

  async function getSubscriptions() {
    let token = loggedUser.user.signInUserSession.idToken.jwtToken;
    let phoneData = loggedUser.user.attributes.phone_number;
    phoneData = phoneData.substring(1);
    try {
        await fetch(`${process.env.REACT_APP_BASE_URL}/subscriptions?phone=%2B${phoneData}`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            'Accept': 'application/json',
            'Authorization': `Bearer ${token}`
          },
          body: JSON.stringify(),
        })
        .then(res => {
          if (res.ok) return res.json()
          // If there is an error then make sure we catch that
          return res.json().then(e => Promise.reject(e))
        }).then(({subscription}) => {
          setLoggedUser({
            ...loggedUser,
            licenseDetails: subscription
          })
          if(subscription.allotedSeats > 1 && !subscription.subscriptionStatus.toLowerCase().includes("cancelled")){
            setPermissions_Set(3);
          } else if (subscription.allotedSeats === 1 && !subscription.subscriptionStatus.toLowerCase().includes("cancelled")){
            setPermissions_Set(2);
          } else if((subscription.allotedSeats === 1 && subscription.subscriptionStatus.toLowerCase().includes("cancelled")) || (subscription.allotedSeats > 1 && subscription.subscriptionStatus.toLowerCase().includes("cancelled")) ){
            setPermissions_Set(0);
          } else{
             setPermissions_Set(1);
          }
          setIsAuthenticating(false);
        })
      } catch (error) {
        if(error.message === "Subscription not found"){
          createAgent();
        } else{
          setFetchError(error.message);
          setIsAuthenticating(false); 
        }
      }
  }

  // create agent when we have none
  async function createAgent() {
    let token = loggedUser.user.signInUserSession.idToken.jwtToken;
    try {
      await fetch(`${process.env.REACT_APP_BASE_URL}/agent`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          'Accept': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify(
          {
            "firstName": loggedUser.user.attributes.given_name,
            "lastName": loggedUser.user.attributes.family_name,
            "phone": loggedUser.user.attributes.phone_number,
            "email": loggedUser.user.attributes.email,
            "uuid": loggedUser.user.attributes.sub,
            "accountType": "agent"
         }),
      })
      .then(res => {
        if (res.ok) return res.json()
        // If there is an error then make sure we catch that
        return res.json().then(e => Promise.reject(e))
      }).then(({ agent, message }) => {
        setIsAuthenticating(false); 
      })
    } catch (error) {
       console.log(error);
       setIsAuthenticating(false);
    }
  }

  const toggleMouseOver = () => {
    if (contracted) {
      setContractSidebar(!contractSidebar);
    }
    resize();
  };

  const toggleContracted = () => {
    setContracted(!contracted);
    setContractSidebar(!contracted);
    localStorage.setItem('sidebarContracted', !contracted);
    resize();
  };

  const toggleSettings = () => {
    setShowSettings(!showSettings);
    localStorage.setItem('settingsVisible', !showSettings);
  }

  const hasPermission = () => {
    if (permissions_set >= authorize) {
      return true
    }

     return false;
  }

  return (
      !isAuthenticating &&
      <Route {...rest} render={props =>  loggedUser.isAuthenticated && hasPermission() ? (
        <>
          <Sidebar
            contracted={contractSidebar}
            onMouseEnter={toggleMouseOver}
            onMouseLeave={toggleMouseOver}
          />

          <main className="content">
            <Topbar toggleContracted={toggleContracted} />
            <Component {...props} />
            <Footer toggleSettings={toggleSettings} showSettings={showSettings} />
          </main>
        </>
      ) : loggedUser.isAuthenticated && !hasPermission() ? (
        <>
          <Sidebar
            contracted={contractSidebar}
            onMouseEnter={toggleMouseOver}
            onMouseLeave={toggleMouseOver}
          />

          <main className="content">
            <Topbar toggleContracted={toggleContracted} />
              {
                fetcherror && <div className="text-center text-md-center text-danger">Error: {fetcherror}</div>
              }
              <div className='my-2'>
                <h2>Upgrade your license</h2>
              </div>
            <Footer toggleSettings={toggleSettings} showSettings={showSettings} />
          </main>
        </>
      )
      : <Redirect to={Routes.Signin.path}/>
      }
      />
   
  );
};

export default () => (
  <UserProvider>
       <IndividualAgentProvider>
        <AgencyContextProvider>
          <AgencyLeadsDataContextProvider>
            <AppointmentsDataContextProvider>
              <LeadsDataContextProvider>
                <Switch>
                  <Route exact path={Routes.Presentation.path} component={Presentation} />
                  <Route exact path={Routes.Signin.path} component={Signin} />
                  <Route exact path={Routes.Signup.path} component={Signup} />
                  <Route exact path={Routes.PreOrder.path} component={PreOrder} />
                  <Route exact path={Routes.ForgotPassword.path} component={ForgotPassword} />
                  <Route exact path={Routes.ResetPassword.path} component={ResetPassword} />
                  <Route exact path={Routes.GetCode.path} component={GetCode} />
                  <Route exact path={Routes.SendCode.path} component={SendCode} />
                  <Route exact path={Routes.Lock.path} component={Lock} />
                  <Route exact path={Routes.NotFound.path} component={NotFoundPage} />
                  <Route exact path={Routes.ServerError.path} component={ServerError} />

                  {/* pages */}
                  <RouteWithSidebar exact path={Routes.DashboardOverview.path} component={DashboardOverview} authorize="2"/>
                  <RouteWithSidebar exact path={Routes.DashboardAgencies.path} component={DashboardAgencies}  authorize="2"/>
                  <RouteWithSidebar exact path={Routes.DashboardTraffic.path} component={DashboardTraffic}  authorize="2"/>
                  <RouteWithSidebar exact path={Routes.DashboardProductAnalysis.path} component={DashboardProductAnalysis} authorize="2"/>
                  <RouteWithSidebar exact path={Routes.AgentDashboard.path} component={AgentDashboard} authorize="2"/>
                  <RouteWithSidebar exact path={Routes.Welcome.path} component={Welcome} authorize="0"/>
                  <RouteWithSidebar exact path={Routes.Kanban.path} component={Kanban} authorize="2"/>
                  <RouteWithSidebar exact path={Routes.Messages.path} component={Messages} authorize="1"/>
                  <RouteWithSidebar exact path={Routes.SingleMessage.path} component={SingleMessage} authorize="1"/>
                  <RouteWithSidebar exact path={Routes.Users.path} component={Users} authorize="1" />
                  <RouteWithSidebar exact path={Routes.Transactions.path} component={Transactions} authorize="1"/>
                  <RouteWithSidebar exact path={Routes.ManageTeam.path} component={ManageTeam} authorize="2"/>
                  <RouteWithSidebar exact path={Routes.CreateTeam.path} component={CreateTeam} authorize="2"/>
                  <RouteWithSidebar exact path={Routes.Cancelled.path} component={Cancelled} authorize="0"/>
                  <RouteWithSidebar exact path={Routes.CreateAgent.path} component={CreateAgent} authorize="2"/>
                  <RouteWithSidebar exact path={Routes.CreateAgencyAgent.path} component={CreateAgencyAgent} authorize="2"/>
                  <RouteWithSidebar exact path={Routes.PaymentSuccesful.path} component={PaymentSuccesful} authorize="2"/>
                  <RouteWithSidebar exact path={Routes.CreateAgency.path} component={CreateAgency} authorize="2"/>
                  <RouteWithSidebar exact path={Routes.Tasks.path} component={Tasks} />
                  <RouteWithSidebar exact path={Routes.Settings.path} component={Settings} authorize="2"/>
                  <RouteWithSidebar exact path={Routes.Calendar.path} component={Calendar} />
                  <RouteWithSidebar exact path={Routes.Map.path} component={MapPage} authorize="1"/>
                  <RouteWithSidebar exact path={Routes.Datatables.path} component={Datatables} authorize="1"/>
                  <RouteWithSidebar exact path={Routes.BootstrapTables.path} component={BootstrapTables} authorize="1"/>
                  <RouteWithSidebar exact path={Routes.Pricing.path} component={Pricing} />
                  <RouteWithSidebar exact path={Routes.Billing.path} component={Billing} />
                  <RouteWithSidebar exact path={Routes.Invoice.path} component={Invoice} authorize="3"/>

                  {/* components */}
                  <RouteWithSidebar exact path={Routes.Accordions.path} component={Accordion} authorize="1" />
                  <RouteWithSidebar exact path={Routes.Alerts.path} component={Alerts} authorize="1"  />
                  <RouteWithSidebar exact path={Routes.Badges.path} component={Badges} authorize="1" />
                  <RouteWithSidebar exact path={Routes.Widgets.path} component={Widgets} authorize="1"  />
                  <RouteWithSidebar exact path={Routes.Breadcrumbs.path} component={Breadcrumbs} authorize="1"  />
                  <RouteWithSidebar exact path={Routes.Buttons.path} component={Buttons} authorize="1" />
                  <RouteWithSidebar exact path={Routes.Forms.path} component={Forms} authorize="1" />
                  <RouteWithSidebar exact path={Routes.Modals.path} component={Modals} authorize="1" />
                  <RouteWithSidebar exact path={Routes.Navs.path} component={Navs} authorize="1" />
                  <RouteWithSidebar exact path={Routes.Navbars.path} component={Navbars} authorize="1" />
                  <RouteWithSidebar exact path={Routes.Pagination.path} component={Pagination} authorize="1" />
                  <RouteWithSidebar exact path={Routes.Popovers.path} component={Popovers} authorize="1" />
                  <RouteWithSidebar exact path={Routes.Progress.path} component={Progress} authorize="1" />
                  <RouteWithSidebar exact path={Routes.Tables.path} component={Tables} authorize="1" />
                  <RouteWithSidebar exact path={Routes.Tabs.path} component={Tabs} authorize="1" />
                  <RouteWithSidebar exact path={Routes.Tooltips.path} component={Tooltips} authorize="1" />
                  <RouteWithSidebar exact path={Routes.Toasts.path} component={Toasts} authorize="1" />
                  <RouteWithSidebar exact path={Routes.WidgetsComponent.path} component={WidgetsComponent} authorize="1"/>

                  {/* documentation */}
                  <RouteWithSidebar exact path={Routes.DocsOverview.path} component={DocsOverview} />
                  <RouteWithSidebar exact path={Routes.DocsDownload.path} component={DocsDownload} />
                  <RouteWithSidebar exact path={Routes.DocsQuickStart.path} component={DocsQuickStart} />
                  <RouteWithSidebar exact path={Routes.DocsLicense.path} component={DocsLicense} />
                  <RouteWithSidebar exact path={Routes.DocsFolderStructure.path} component={DocsFolderStructure} />
                  <RouteWithSidebar exact path={Routes.DocsBuild.path} component={DocsBuild} />
                  <RouteWithSidebar exact path={Routes.DocsChangelog.path} component={DocsChangelog} />

                  {/* plugins */}
                  <RouteWithSidebar exact path={Routes.PluginCharts.path} component={PluginCharts} />
                  <RouteWithSidebar exact path={Routes.PluginCalendar.path} component={PluginCalendar} />
                  <RouteWithSidebar exact path={Routes.PluginDatatable.path} component={PluginDatatable} />
                  <RouteWithSidebar exact path={Routes.PluginMap.path} component={PluginMap} />
                  <RouteWithSidebar exact path={Routes.PluginDropzone.path} component={PluginDropzone} />
                  <RouteWithSidebar exact path={Routes.PluginSweetAlert.path} component={PluginSweetAlert} />

                  <Redirect to={Routes.NotFound.path} />
                </Switch>
              </LeadsDataContextProvider>
            </AppointmentsDataContextProvider>
          </AgencyLeadsDataContextProvider>
        </AgencyContextProvider>
      </IndividualAgentProvider>
  </UserProvider>
);
