import React, { useState, useEffect, useContext } from "react";
import { CloudUploadIcon, CollectionIcon, FireIcon, PlusIcon, ShieldExclamationIcon, UserAddIcon } from "@heroicons/react/solid";
import { Col, Row, Alert } from 'react-bootstrap';
import moment from "moment-timezone";

import { CustomersWidget, RevenueWidget, UsersWidget, WeeklyReportWidget, TopAuthorsWidget, TeamMembersWidget, ProgressTrackWidget, EventsWidget, RankingWidget, VisitsMapWidget, SalesValueWidget, AppointmentsWidget, AcquisitionWidget, TimelineWidget, CardWidget } from "components/Widgets";
import { PageVisitsTable } from "components/Tables";
import { UserContext } from "Context/UserContext";
import { LeadsDataContext } from "Context/LeadsDataContext";
import { AppointmentsDataContext } from "Context/AppointmentsDataContext";


export default () => {
  const [loggedUser, setLoggedUser] = useContext(UserContext);
  const [leadsData, setLeadsData] = useContext(LeadsDataContext);
  const [appointmentsData, setAppointmentsData] = useContext(AppointmentsDataContext);
  const [paymentAlert, setPaymentAlert] = useState(false);
  const [pageLoading, setPageLoading] = useState(true);

  const [leadStatus, setleadStatus] = useState({
    total_leads : null,
    total_contacts : null,
    total_appointments : null,
    total_doorsKnocked : null,
    leadRatePercentage: null
  });

  const [appointmentStatus, setAppointmentStatus] = useState({
    total_appointments : null,
    leadRatePercentage: null
  })


   useEffect(() => {
    if ((loggedUser.licenseDetails.subscriptionStatus == "pending_cancellation" && loggedUser.licenseDetails.paymentStatus == "paid")){
      setPaymentAlert(true);
    }
    getLeads();
   }, []);


   useEffect(() => {
    if (leadsData === null ) {
      return
    }
    checkLead()
  }, [leadsData]);

  useEffect(() => {
    if (appointmentsData === null ) {
      return
    }
    checkAppointment()
  }, [appointmentsData]);

   const token = loggedUser.user.signInUserSession.idToken.jwtToken;

   const agentUuid = loggedUser.user.username;


   async function getLeads() {
    try {
      const values = await fetch(`${process.env.REACT_APP_BASE_URL}/agent/${agentUuid}/locations`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          'Accept': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify()
      })
      .then(res => {
        if (res.ok) return res.json()
        // If there is an error then make sure we catch that
        return res.json().then(
          e => Promise.reject(e)
        )
      }).then(({ locations }) => {
         sessionStorage.removeItem('leadsLocation');
         sessionStorage.setItem("leadsLocation", JSON.stringify(locations));
         //replace this with saving data on the local storage to prevent the data from being manipulated 
         setLeadsData(locations); 
      })
      setPageLoading(false);
    } catch (error) {
      setPageLoading(false);
      console.log(error);
    }
  }
    
  // check leads status
  function checkLead() {    
      let leads = 0;
      let contacts = 0;
      let appointments = 0;
      let doorsKnocked = leadsData.length;

      leadsData.forEach(element => {
        if (element.interestLevel === 'Interested') {
          leads += 1;
        }
        if (element.interestLevel === 'Interested' || element.interestLevel === 'Not Interested') {
          contacts += 1;
        }
        if (element.followupSettingsAsJson.appointment === true) {
          appointments += 1;
        }
      });

      let generationRateRaw =  leads / contacts * 100;
      let generationRate =  Math.round(generationRateRaw);


      setleadStatus({
        total_leads : leads,
        total_contacts : contacts,
        total_appointments : appointments,
        total_doorsKnocked : doorsKnocked,
        leadRatePercentage: generationRate
      });
  }

  function checkAppointment() {    
    let appointLead = 0;
    let totalAppointment = 0;

    appointmentsData.forEach(element => {
      if (element.interestLevel === 'Interested') {
        appointLead += 1;
      }
      totalAppointment += 1;
    });

    let AppointmentsRateRaw = totalAppointment / appointLead * 100;
    let AppointmentsRate = Math.round(AppointmentsRateRaw);

    setAppointmentStatus({
      total_appointments : totalAppointment,
      appointmentsRatePercentage: AppointmentsRate
    })
  }


  const today = moment();
  const lastSevenDays = moment().subtract(7, 'days');
  const lastThirtyDays = moment().subtract(30, 'days');


  function monthAgo(lead) {    
     return  moment(lead.createdAt).isBetween(lastThirtyDays, today, 'day', '[]'); 
  }


  return (
    <>
     
     {
       paymentAlert &&
        <React.Fragment>
          <Col xs={12} lg={12} xl={12} className="mt-4 mb-lg-0">
            <Alert variant="danger">
              Your subscription is pending for cancellation.
            </Alert>
          </Col>
       </React.Fragment>
     }

      {
      pageLoading && leadsData === null ? <div className="page-loading">
      <div className="d-flex justify-content-center">
          <div className="spinner-border" role="status">
            <span className="visually-hidden">Loading...</span>
          </div>
        </div>
     </div>
    :
    <>

    <div className="mt-4 mb-lg-0">
      <h2 className="fw-extrabold display-4 mb-4">My Stats</h2>
    </div>

    <Row className="mb-4"> 

      <Col xs={12} sm={6} lg={3} className="mb-4 mb-xxl-0">
        <CardWidget
          title="Leads Created"
          value={leadStatus.total_leads}
        />
      </Col>

      <Col xs={12} sm={6} lg={3} className="mb-4 mb-xxl-0">
        <CardWidget
          title="Contacts"
          value={leadStatus.total_contacts}
        />
      </Col>

      <Col xs={12} sm={6} lg={3} className="mb-4 mb-xxl-0">
        <CardWidget
          title="Doors Knocked"
          value={leadStatus.total_doorsKnocked}
        />
      </Col>

      <Col xs={12} sm={6} lg={3} className="mb-4 mb-xxl-0">
        <CardWidget
          title="Appointments"
          value={leadStatus.total_appointments}
        />
      </Col>

    </Row>

    <Row className="justify-content-lg-center">
      <Col xs={12} className="mb-4">
        <SalesValueWidget
          title="Lead Generation"
          value={leadStatus.total_leads}
          percentage={leadStatus.leadRatePercentage}
        />
      </Col>
    </Row>

    <Row className="justify-content-lg-center">
      <Col xs={12} className="mb-4">
        <AppointmentsWidget
          title="Appointments Generation"
          value={appointmentStatus.total_appointments}
          percentage={appointmentStatus.appointmentsRatePercentage}
        />
      </Col>
    </Row>
    
    </>
      }
       

    </>
  );
};
