
import React, {useState} from "react";
import { ArrowNarrowLeftIcon } from "@heroicons/react/solid";
import { Col, Row, Form, Card, Button, Container, InputGroup } from 'react-bootstrap';
import { Link, Redirect} from 'react-router-dom';
import { Auth } from "aws-amplify";
import { Routes } from "routes";
import {useForm, Controller} from "react-hook-form";
import { confirmationcodeFormOptions } from "components/validator/validator";

import PhoneInput, { isValidPhoneNumber } from "react-phone-number-input";
import "react-phone-number-input/style.css";

export default () => {
  const [PhoneInfo, setPhoneInfo] = useState({
    phoneNo: "",
    redirect: false,
    error: ''
  });

  const { 
    register, 
    handleSubmit, 
    reset, 
    control,
    formState: { errors } 
  } = useForm(confirmationcodeFormOptions);


  async function resendConfirmationCode(data) {
    try {
        await Auth.resendSignUp(data.phone);
        console.log('code resent successfully');
        setPhoneInfo({
            ...PhoneInfo,
            phoneNo: data.phone,
            redirect: true,
            error: ''
        });
    } catch (err) {
        setPhoneInfo({
            ...PhoneInfo,
            phoneNo: '',
            redirect: false,
            error: err.message
        });
    }
  }

  return (
    <main>
      <section className="vh-lg-100 mt-5 mt-lg-0 bg-soft d-flex align-items-center">
        <Container>
          <Row className="justify-content-center">
            <p className="text-center">
              <Card.Link as={Link} to={Routes.Signin.path} className="d-flex align-items-center justify-content-center">
                <ArrowNarrowLeftIcon className="icon icon-xs me-2" /> Back to sign in
              </Card.Link>
            </p>
            <Col xs={12} className="d-flex align-items-center justify-content-center">
              <div className="signin-inner my-3 my-lg-0 bg-white shadow border-0 rounded p-4 p-lg-5 w-100 fmxw-500">
                <h3>Get Verification Code</h3>
                <p className="mb-4">Type in your Phone Number and we will send you a code to verify your account!</p>
                <Form onSubmit={handleSubmit(resendConfirmationCode)}>
                  <div className="mb-4">
                    <Form.Group id="phone">
                        <Form.Label>Your Phone Number</Form.Label>

                        {errors.phone && <label className="text-danger d-block">{errors.phone?.message}</label>}

                        <InputGroup>
                        <Controller
                            name="phone"
                            control={control}
                            rules={{
                            validate: (value) => isValidPhoneNumber(value)
                            }}
                            render={({ field: { onChange, value } }) => (
                            <PhoneInput
                            value={value}
                            placeholder="Phone Number"
                            onChange={onChange}
                            defaultCountry="CA"
                            id="phone-input"
                            />
                            )}
                        />
                        
                        </InputGroup>
                    </Form.Group>
                  </div>

                    {
                        PhoneInfo.error && <div className="mb-4 text-center">
                            <p className="text-danger d-block">{PhoneInfo.error}</p>
                        </div>
                    }
                  

                  <div className="d-grid">
                    <Button variant="gray-800" type="submit">
                      Get Code
                    </Button>
                  </div>
                </Form>

                {PhoneInfo.redirect && (
                    <Redirect
                      to={{
                        pathname: Routes.SendCode.path,
                        phoneId: PhoneInfo.phoneNo
                      }}
                    />
                )}

              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </main>
  );
};
