import React, { useState, useEffect, useContext } from "react";
import { Col, Row, Alert, Button } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import moment from "moment-timezone";
import { ArrowSmLeftIcon } from "@heroicons/react/solid";
import { CustomersWidget, RevenueWidget, UsersWidget, AgencyLeadsWidget, AgencyAppointmentsWidget, TopDoorsWidget, TopAppointmentsWidget, TopLeadsWidget, CardWidget } from "components/Widgets";
import { PageVisitsTable } from "components/Tables";
import { UserContext } from "Context/UserContext";
import { AgencyLeadsDataContext } from "Context/AgencyLeadsContext";
import { AppointmentsDataContext } from "Context/AppointmentsDataContext";
import { Routes } from "routes";


export default () => {
  const [loggedUser, setLoggedUser] = useContext(UserContext);
  const [agencyleadsData, setAgencyLeadsData] = useContext(AgencyLeadsDataContext);
  const [appointmentsData, setAppointmentsData] = useContext(AppointmentsDataContext);
  const [paymentAlert, setPaymentAlert] = useState(false);
  const [pageLoading, setPageLoading] = useState(true);
  const [appointmentStatus, setAppointmentStatus] = useState({
    total_appointments : null,
    leadRatePercentage: null
  })
  const history = useHistory();

  const [leadStatus, setleadStatus] = useState({
    leadRatePercentage: null
})


   useEffect(() => {
    if ((loggedUser.licenseDetails.subscriptionStatus == "pending_cancellation" && loggedUser.licenseDetails.paymentStatus == "paid")){
      setPaymentAlert(true);
    }
    // clear exsiting agents data
    setAgencyLeadsData(null);
    // get selected agent stats
    getAgentstats();
   }, []);


   useEffect(() => {
    if (agencyleadsData === null) {
      return
    }
    checkLead()
    checkAppointment()
  }, [agencyleadsData]);



   const token = loggedUser.user.signInUserSession.idToken.jwtToken;

   const agencyUuid = loggedUser.licenseDetails.agencyUuid;

   // loaded agent details
  var loadedAgentData = JSON.parse(sessionStorage.SelectedAgentDetails);
  const agentUuid = loadedAgentData.uuid;
  

    // get Agent stats
  async function getAgentstats() {
    try {
        await fetch(`${process.env.REACT_APP_BASE_URL}/agent/${agentUuid}/stats`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          'Accept': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify()
      })
      .then(res => {
        if (res.ok) return res.json()
        // If there is an error then make sure we catch that
        return res.json().then(
          e => Promise.reject(e)
        )
      })
      .then(({ stats }) => {
        sessionStorage.removeItem('leadsStats');
        sessionStorage.setItem("leadsStats", JSON.stringify(stats));
        //replace this with saving data on the local storage to prevent the data from being manipulated 
        setAgencyLeadsData({
          doorsKnocked: stats.chartData.knocks.totals.year || 0,
          totalAppointments: stats.chartData.appointments.totals.year || 0,
          totalContacts: stats.chartData.contacts.totals.year || 0,
          totalLeads: stats.chartData.leads.totals.year || 0
        });
     })
      setPageLoading(false);
    } catch (error) {
      setPageLoading(false);
      console.log(error);
    }
  }
  
//   check leads status
  function checkLead() {    
      let generationRateRaw =  agencyleadsData.totalLeads /  agencyleadsData.totalContacts * 100;
      let generationRate =  Math.round(generationRateRaw);
      setleadStatus({
        leadRatePercentage: generationRate
      })
  }

  function checkAppointment() { 
    let totalAppointment = 0;

    let AppointmentsRateRaw = agencyleadsData.totalAppointments / agencyleadsData.totalLeads * 100;
    let AppointmentsRate = Math.round(AppointmentsRateRaw);

    setAppointmentStatus({
      total_appointments : totalAppointment,
      appointmentsRatePercentage: AppointmentsRate
    })
  }



  return (
    <>
    {
      paymentAlert &&
        <React.Fragment>
          <Col xs={12} lg={12} xl={12} className="mt-4 mb-lg-0">
            <Alert variant="danger">
              Your subscription is pending for cancellation.
            </Alert>
          </Col>
      </React.Fragment>
    }

    {
      pageLoading && agencyleadsData === null ? <div className="page-loading">
      <div className="d-flex justify-content-center">
          <div className="spinner-border" role="status">
            <span className="visually-hidden">Loading...</span>
          </div>
        </div>
      </div>
    :
    <>

    <Button 
    variant="gray-700" 
    className="mt-3"
    onClick={
      () =>  history.push({
        pathname: Routes.DashboardAgencies.path
    })
    } 
    >
      <ArrowSmLeftIcon 
      className="icon icon-xs me-1" 
      size="sm" 
      /> Back
    </Button>

    <div className="mt-4 mb-lg-0">
        <h2 className="fw-extrabold display-4 mb-4">{`${loadedAgentData.firstName} ${loadedAgentData.lastName}`} Stats</h2>
    </div>

    <Row className="mb-4"> 

        <Col xs={12} sm={6} lg={3} className="mb-4 mb-xxl-0">
            <CardWidget
            title="Leads Created"
            value={agencyleadsData.totalLeads}
            />
        </Col>

        <Col xs={12} sm={6} lg={3} className="mb-4 mb-xxl-0">
            <CardWidget
            title="Contacts"
            value={agencyleadsData.totalContacts}
            />
        </Col>

        <Col xs={12} sm={6} lg={3} className="mb-4 mb-xxl-0">
            <CardWidget
            title="Doors Knocked"
            value={agencyleadsData.doorsKnocked}
            />
        </Col>

        <Col xs={12} sm={6} lg={3} className="mb-4 mb-xxl-0">
            <CardWidget
            title="Appointments"
            value={agencyleadsData.totalAppointments}
            />
        </Col>

    </Row>

    <Row className="justify-content-lg-center">
        <Col xs={12} className="mb-4">
        <AgencyLeadsWidget 
            title="Lead Generation"
            value={agencyleadsData.totalLeads}
            percentage={leadStatus.leadRatePercentage}
        />
        </Col>
    </Row>

    <Row className="justify-content-lg-center">
        <Col xs={12} className="mb-4">
        <AgencyAppointmentsWidget
            title="Appointments Generation"
            value={agencyleadsData.totalAppointments}
            percentage={appointmentStatus.appointmentsRatePercentage}
        />
        </Col>
    </Row>

    </>
    }
    </>
  );
};
