import React, { useState, useContext, useEffect} from "react";
import { useHistory } from "react-router-dom";
import { Routes } from "routes";
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { AdjustmentsIcon, CheckIcon, CogIcon, HomeIcon, PlusIcon, SearchIcon , MailIcon, LockClosedIcon} from "@heroicons/react/solid";
import { Col, Row, Form, Button, ButtonGroup, Breadcrumb, InputGroup, Dropdown, Card, Modal, } from 'react-bootstrap';
import { addAgentformOptions, createTeamformOptions } from "components/validator/validator";
import { UserContext } from "Context/UserContext";
import {useForm, Controller} from "react-hook-form";

import { UsersTable } from "components/Tables";
import USERS_DATA from "data/test";

import PhoneInput, { isValidPhoneNumber } from "react-phone-number-input";
import "react-phone-number-input/style.css";

const SwalWithBootstrapButtons = withReactContent(Swal.mixin({
    customClass: {
      confirmButton: 'btn btn-primary me-3',
      cancelButton: 'btn btn-gray'
    },
    buttonsStyling: false
}));

export default () => {
  // const [users, setUsers] = useState(USERS_DATA.map(u => ({ ...u, isSelected: false, show: true })));
  const [users, setUsers] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  const [statusFilter, setStatusFilter] = useState("all");
  const [activeTeamUuid, setActiveTeamUuid] = useState("");
  const [teamData, setTeamData] = useState({});
  const selectedUsersIds = users.filter(u => u.isSelected).map(u => u.uuid);
  const totalUsers = users.length;
  const allSelected = selectedUsersIds.length === totalUsers;
  const [loggedUser, setLoggedUser] = useContext(UserContext);
  const [showSignup, setShowSignup] = useState(false);
  const [agencyData, setAgencyData] = useState({});
  const [pageLoading, setPageLoading] = useState(true);
  const [modalLoading, setModalLoading] = useState(true);
  const [addingAgentStatus, setAddingAgentStatus] = useState('initial');
  const [btnloading, setbtnLoading] = useState(false);
  const [agentExists, setAgentExists] = useState(false);
  const [fetcherror, setFetchError] = useState(null);
  const [pageReload, setPageReload] = useState(true);
  const [teambtnloading, setTeambtnLoading] = useState(false);
  const [teamFormState, setTeamFormState] = useState(true);
  const [showAddTeam, setShowAddTeam] = useState(false);
  const handleCloseTeamModal = () => setShowAddTeam(false);
  const handleClose = () => setShowSignup(false);
  const history = useHistory();

  const { 
    register,
    handleSubmit, 
    reset, 
    control,
    formState: { errors } 
  } = useForm(addAgentformOptions);

  const { 
    register:register1, 
    handleSubmit:handleSubmit1, 
    reset:reset1,
    control: control1,
    formState: { errors: errors1 }  
  } = useForm(createTeamformOptions); 

  const token = loggedUser.user.signInUserSession.idToken.jwtToken;

  useEffect(() => {
    if (Object.keys(teamData).length === 0){
  
    }
    getAgency();
  }, []);

  const noTeamAlert = () => {
      
    const result = SwalWithBootstrapButtons.fire({
      title: 'No Team Detected',
      text: "Create Your Team",
      icon: 'warning',
      confirmButtonColor: '#3085d6',
      confirmButtonText: 'Yes, Create!'
    }).then(function(){ 
      history.push({pathname: Routes.CreateTeam.path, from:'manageTeam'});
      });
  };
  
   // get subscription details
  async function getSubscriptions() {
    setAddingAgentStatus('details');
    setModalLoading(true);
    let phoneData = loggedUser.user.attributes.phone_number;
    phoneData = phoneData.substring(1);
    try {
      await fetch(`${process.env.REACT_APP_BASE_URL}/subscriptions?phone=%2B${phoneData}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          'Accept': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify(),
      })
        .then(res => {
          if (res.ok) return res.json()
          // If there is an error then make sure we catch that
          return res.json().then(e => Promise.reject(e))
        }).then(({ subscription }) => {
          let agentSeats = subscription.allotedSeats - subscription.usedSeats;
          if (agentSeats <= 0) {
            modifyLicense(subscription);
          } else {
            getAgency();
          }
        })
    } catch (error) {
      setFetchError(error.message);
    }
  }

  async function modifyLicense(subscription) {
    setAddingAgentStatus('billing');
    try {
        await fetch(`${process.env.REACT_APP_BASE_URL}/subscriptions/${subscription.subscriptionId}/modifyLicense`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          'Accept': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify({
          "additionalSeats": 1
        }),
      })
        .then(res => {
          if (res.ok) return getAgency();
          // If there is an error then make sure we catch that
          return res.json().then(e => Promise.reject(e))
        })
    } catch (error) {
      setFetchError(error.message);
    }
  }


  async function getAgency() {
    setAddingAgentStatus('agency');
    const agencyId = loggedUser.licenseDetails.agencyUuid;
    try {
      await fetch(`${process.env.REACT_APP_BASE_URL}/agency/${agencyId}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          'Accept': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify(),
      })
      .then(res => {
        if (res.ok) return res.json()
        // If there is an error then make sure we catch that
        return res.json().then(e => Promise.reject(e))
      }).then(({ agency }) => {
        setAgencyData(agency);
        if (agency.teams.length === 0 || agency.teams.uuid === null){
          return noTeamAlert()
        } else{
          //check if the uuid is null or same with the current state
          console.log(activeTeamUuid);
          if (activeTeamUuid == agency.teams[0].uuid || activeTeamUuid === ""){
            setActiveTeamUuid(agency.teams[0].uuid);
          }     
          getTeam(agency);
        }
      })
    } catch (error) {
      console.log(error)
      if (error.message === 'Agency not found'){
        history.push({pathname: Routes.CreateAgency.path});
      }
    }
  }

  async function getTeam(agency_data) { 
    setAddingAgentStatus('team');
    const teams = agency_data.teams[0].uuid;
    try {
      await fetch(`${process.env.REACT_APP_BASE_URL}/team/${teams}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          'Accept': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify(),
      })
      .then(res => {
        if (res.ok) return res.json()
        // If there is an error then make sure we catch that
        return res.json().then(e => Promise.reject(e))
      }).then(({ team }) => {
        //  console.log(team);
         const dataTest = team.agents;
         setTeamData(team);
         setUsers(dataTest.map(u => ({ ...u, isSelected: false, show: true })));
         setAddingAgentStatus('agent');
         setPageLoading(false);
      })
    } catch (error) {
      console.log(error)
      setPageLoading(false);
    }
  }

  // change selected team
  async function getSelectedTeam(newTeamUuid) { 
    setAddingAgentStatus('team');
    try {
      await fetch(`${process.env.REACT_APP_BASE_URL}/team/${newTeamUuid}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          'Accept': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify(),
      })
      .then(res => {
        if (res.ok) return res.json()
        // If there is an error then make sure we catch that
        return res.json().then(e => Promise.reject(e))
      }).then(({ team }) => {
         console.log(team);
         const dataTest = team.agents;
         setTeamData(team);
         setUsers(dataTest.map(u => ({ ...u, isSelected: false, show: true })));
         setAddingAgentStatus('agent');
         setPageLoading(false);
      })
    } catch (error) {
      console.log(error)
      setPageLoading(false);
    }
  }

  // create, add team
  async function createTeam(data) {
        setTeambtnLoading(true);
        try {
          const createAgentResponse = await fetch(`${process.env.REACT_APP_BASE_URL}/team`, {
            method: "PUT",
            headers: {
              "Content-Type": "application/json",
              'Accept': 'application/json',
              'Authorization': `Bearer ${token}`
            },
            body: JSON.stringify(
              {
                "teamName": data.team_name,
                "description": data.description,
                "phone": data.phone,
                "email": data.email,
                "address1": data.address1,
                "address2": data.address2,
                "city": data.city,
                "province": data.province,
                "agencyUuid": loggedUser.licenseDetails.agencyUuid
             }),
          })
          .then(res => {
            if (res.ok) return res.json()
            // If there is an error then make sure we catch that
            return res.json().then(e => Promise.reject(e))
          }).then(({ team }) => {
            setTeambtnLoading(false);
            setTeamFormState(false)
            console.log(team)
          })
        } catch (error) {
           console.log(error)
           setTeambtnLoading(false);
        }
    }

  // adding agent when clicked
  async function sendEmail(data) {
    setbtnLoading(true);
    try {
        await fetch(`${process.env.REACT_APP_BASE_URL}/email`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          'Accept': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify(
          {
            "subscriptionId": loggedUser.licenseDetails.subscriptionId,
            "action": "notifyInvitedAgent",
            "sendTo": data.email
          }),
      })
      .then(res => {
        if (res.ok) return addAgent(data);
        // If there is an error then make sure we catch that
        return res.json().then(e => Promise.reject(e))
      })
    } catch (error) {
       console.log(error)
       setbtnLoading(false);
    }
  }

  async function addAgent(data) {
    setAgentExists(false);
    try {
        await fetch(`${process.env.REACT_APP_BASE_URL}/agent`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          'Accept': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify(
          {
            "firstName": data.firstName,
            "lastName": data.lastName,
            "phone": data.phone,
            "email": data.email,
            "teamUuid": activeTeamUuid
        }),
      })
      .then(res => {
        if (res.ok) return res.json()
        // If there is an error then make sure we catch that
        return res.json().then(e => Promise.reject(e))
      }).then(({ agent, message }) => {
        setModalLoading(false);
        setbtnLoading(false);
      })
    } catch (error) {
      console.log(error)
      if(error.message === "agent already exists"){
        setAgentExists(true);
      }
      setbtnLoading(false);
    }
  }

  // delete agent from team
  async function deleteAgent(id) {
    try {
        await fetch(`${process.env.REACT_APP_BASE_URL}/team/${teamData.uuid}/${id}`, {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          'Accept': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify(),
      })
      .then(res => {
        if (res.ok) return res.json()
        // If there is an error then make sure we catch that
        return res.json().then(e => Promise.reject(e))
      })
    } catch (error) {
        console.log(error)
    }
  }

  // refresh team
  async function refreshTeam() { 
    const teams = agencyData.teams[0].uuid;
    try {
      await fetch(`${process.env.REACT_APP_BASE_URL}/team/${teams}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          'Accept': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify(),
      })
      .then(res => {
        if (res.ok) return res.json()
        // If there is an error then make sure we catch that
        return res.json().then(e => Promise.reject(e))
      }).then(({ team }) => {
        const dataTest = team.agents;
        setTeamData(team);
        setUsers(dataTest.map(u => ({ ...u, isSelected: false, show: true })));
      })
    } catch (error) {
      console.log(error)
      setPageLoading(false);
    }
  }

  const changeSearchValue = (e) => {
    const newSearchValue = e.target.value;
    const newUsers = users.map(u => ({ ...u, show: u.firstName.toLowerCase().includes(newSearchValue.toLowerCase()) }));

    setSearchValue(newSearchValue);
    setUsers(newUsers);
  };

  const changeStatusFilter = (e) => {
    const newStatusFilter = e.target.value;
    const newUsers = users.map(u => ({ ...u, show: u.status === newStatusFilter || newStatusFilter === "all" }));
    setStatusFilter(newStatusFilter);
    setUsers(newUsers);
  };


  const changeTeam = (e) => {

    const newTeamUuid = e.target.value;
    setActiveTeamUuid(newTeamUuid);
    getSelectedTeam(newTeamUuid);
    // const newUsers = users.map(u => ({ ...u, show: u.status === newStatusFilter || newStatusFilter === "all" }));
    // setStatusFilter(newStatusFilter);
    // setUsers(newUsers);
  };

  const selectAllUsers = () => {
    const newUsers = selectedUsersIds.length === totalUsers ?
      users.map(u => ({ ...u, isSelected: false })) :
      users.map(u => ({ ...u, isSelected: true }));

    setUsers(newUsers);
  };

  const selectUser = (uuid) => {
    const newUsers = users.map(u => u.uuid === uuid ? ({ ...u, isSelected: !u.isSelected }) : u);
    setUsers(newUsers);
  };

  const deleteUsers = async (ids) => {
    const usersToBeDeleted = ids ? ids : selectedUsersIds;
    const usersNr = usersToBeDeleted.length;
    const textMessage = usersNr === 1
      ? "Are you sure do you want to delete this user?"
      : `Are you sure do you want to delete these ${usersNr} users?`;

    const result = await SwalWithBootstrapButtons.fire({
      icon: "error",
      title: "Confirm deletion",
      text: textMessage,
      showCancelButton: true,
      confirmButtonText: "Yes",
      cancelButtonText: "Cancel"
    });

    if (result.isConfirmed) {
      // const newUsers = users.filter(f => !usersToBeDeleted.includes(f.id));
      deleteAgent(ids[0]);
      const confirmMessage = usersNr === 1 ? "The user has been deleted." : "The users have been deleted.";

      // setUsers(newUsers);
      await SwalWithBootstrapButtons.fire('Deleted', confirmMessage, 'success');
      setTimeout(() => {
        refreshTeam()
      }, 3000);
    }
  };

  return (
      pageLoading ? <div className="page-loading">
          <div className="d-flex justify-content-center">
            <div className="spinner-border" role="status">
              <span className="visually-hidden">Loading...</span>
            </div>
          </div>
      </div>
      : 
      <>
      <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-4">
        <div className="d-block mb-4 mb-md-0 team-left">
          <Breadcrumb className="d-none d-md-inline-block" listProps={{ className: "breadcrumb-dark breadcrumb-transparent" }}>
            <Breadcrumb.Item><HomeIcon className="icon icon-xs" /></Breadcrumb.Item>
            <Breadcrumb.Item onClick={() => history.push({pathname: Routes.DashboardOverview.path})}>Home</Breadcrumb.Item>
          </Breadcrumb>
          <h5><strong className="fw-extrabold">Agency:</strong> {teamData.agency.agencyName}</h5>
          <h6><strong className="fw-extrabold">Team:</strong> {teamData.teamName}</h6>
          <p className="mb-0">{teamData.description}</p>
        </div>
        
        <div className="btn-toolbar mb-0 mb-md-0 team-right">
          <Button variant="gray-800" size="sm" className="d-inline-flex align-items-center" onClick={() => { setShowSignup(true); getSubscriptions();}}>
            <PlusIcon className="icon icon-xs me-2" /> Invite Agent
          </Button>
          {/* <Dropdown>
            <Dropdown.Toggle as={Button} variant="gray-800">
              <ClipboardListIcon className="icon icon-xs me-2" />
              Reports
              <ChevronDownIcon className="icon icon-xs ms-1" />
            </Dropdown.Toggle>
            <Dropdown.Menu className="dashboard-dropdown dropdown-menu-start mt-2 py-1">
              <Dropdown.Item className="d-flex align-items-center">
                <ArchiveIcon className="dropdown-icon text-gray-400 me-2" /> Products
              </Dropdown.Item>
              <Dropdown.Item className="d-flex align-items-center">
                <UsersIcon className="dropdown-icon text-gray-400 me-2" /> Customers
              </Dropdown.Item>
              <Dropdown.Item className="d-flex align-items-center">
                <ShoppingBagIcon className="dropdown-icon text-gray-400 me-2" /> Orders
              </Dropdown.Item>
              <Dropdown.Item className="d-flex align-items-center">
                <PresentationChartBarIcon className="dropdown-icon text-gray-400 me-2" /> Console
              </Dropdown.Item>

              <Dropdown.Divider as="div" className="my-1" />

              <Dropdown.Item className="d-flex align-items-center">
                <ClipboardListIcon className="dropdown-icon text-gray-800 me-2" /> All Reports
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown> */}
          <div className="p-2"></div>
            <ButtonGroup>
              <Dropdown>
                <Dropdown.Toggle split as={Button} variant="link" className="text-dark m-0 p-1">
                  <CogIcon className="icon icon-sm" />
                  <span className="visually-hidden">Toggle Dropdown</span>
                </Dropdown.Toggle>
                <Dropdown.Menu className="dropdown-menu-xs dropdown-menu-end  pb-0">
                  <Dropdown.Item className="fw-bold rounded-bottom">
                    Delete team
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </ButtonGroup>
        </div>
      </div>
      <div className="mb-4 mt-2">
        <div>
          <label className="mb-1">Select Team</label>
        </div>
        <Form.Select value={activeTeamUuid} className="fmxw-200 d-none d-md-inline" onChange={changeTeam}>
          {agencyData.teams.map(t => <option value={t.uuid} key={t.uuid}>{t.teamName}</option>)}
        </Form.Select>
      </div>
      <div className="mb-4 mt-2">
        <Button variant="primary" onClick={() => setShowAddTeam(true)}>Add Team</Button>
      </div>

      <div className="table-settings mb-4">
        <Row className="justify-content-between align-items-center">
          <Col xs={9} lg={8} className="d-md-flex">
            <InputGroup className="me-2 me-lg-3 fmxw-300">
              <InputGroup.Text>
                <SearchIcon className="icon icon-xs" />
              </InputGroup.Text>
              <Form.Control
                type="text"
                placeholder="Search agents"
                value={searchValue}
                onChange={changeSearchValue}
              />
            </InputGroup>
            <Form.Select value={statusFilter} className="fmxw-200 d-none d-md-inline" onChange={changeStatusFilter}>
              <option value="all">All</option>
              <option value="Accepted">Accepted</option>
              <option value="Invited">Invited</option>
            </Form.Select>
          </Col>
          <Col xs={3} lg={4} className="d-flex justify-content-end"></Col>
        </Row>
      </div>

      <UsersTable
        users={users.filter(u => u.show)}
        allSelected={allSelected}
        selectUser={selectUser}
        deleteUsers={deleteUsers}
        selectAllUsers={selectAllUsers}
      />

      <React.Fragment>
        <Modal  dialogClassName="wider-modal" centered show={showSignup} onHide={handleClose}>
          <Modal.Header className="border-0">
            <Button variant="close" aria-label="Close" onClick={handleClose} />
          </Modal.Header>
          <Modal.Body className="p-0">
            <Card border="light" className="px-0 px-md-4 py-0 border-0">
              <Card.Header className="text-center text-md-center border-0 mb-0 mt-md-0">
                <h4>Invite Agent</h4>
              </Card.Header>
              <Card.Body>
                {
                  modalLoading ? <Form action="#" onSubmit={handleSubmit(sendEmail)}>
                {
                    addingAgentStatus === "initial" && <><h5>Loading...</h5></>
                }
                {
                   addingAgentStatus === "billing" && <Row>
                     <Col xs={12} className="d-flex align-items-center justify-content-center pb-2">
                         <h5>Billing...</h5>
                      </Col></Row>
                }

                {
                    addingAgentStatus === "agency" && <Row>
                    <Col xs={12} className="d-flex align-items-center justify-content-center pb-2">
                      <h5>Confirming Agency...</h5>
                    </Col></Row>
                }

                {
                    addingAgentStatus === "team" && <Row>
                    <Col xs={12} className="d-flex align-items-center justify-content-center pb-2">
                      <h5>Confirming Team...</h5>
                    </Col></Row>
                }

                {
                    addingAgentStatus === "agent" && <>
                    <Row>
                    <Col md={4} className="mb-3">
                        <Form.Group id="firstName">
                            <Form.Label>First Name</Form.Label>

                            {errors.firstName && <label className="text-danger d-block mb-3">{errors.firstName?.message}</label>}

                            <Form.Control
                            type="text" 
                            name="firstName"
                            {...register("firstName")}
                            placeholder="First Name" 
                            />
                        </Form.Group>
                    </Col>
                    <Col md={4} className="mb-3">
                        <Form.Group id="lastName">
                            <Form.Label>Last Name</Form.Label>

                            {errors.lastName && <label className="text-danger d-block mb-3">{errors.lastName?.message}</label>}

                            <Form.Control
                            type="text" 
                            name="lastName"
                            {...register("lastName")}
                            placeholder="Last Name" 
                            />
                        </Form.Group>
                    </Col>
                    <Col md={4} className="mb-3">
                        <Form.Group id="email">
                            <Form.Label>Email</Form.Label>

                            {errors.email && <label className="text-danger d-block">{errors.email?.message}</label>} 

                            <InputGroup>
                                <Form.Control
                                autoFocus
                                type="email"
                                name="email"
                                {...register("email")}
                                placeholder="example@company.com"
                                />
                            </InputGroup>
                        </Form.Group>
                    </Col>
                    <Col md={12} className="mb-3">
                        <Form.Group id="phone">
                            <Form.Label>Phone</Form.Label>

                            {errors.phone && <label className="text-danger d-block mb-3">{errors.phone?.message}</label>}

                            <InputGroup>
                                <Controller
                                  name="phone"
                                  control={control}
                                  rules={{
                                  validate: (value) => isValidPhoneNumber(value)
                                  }}
                                  render={({ field: { onChange, value } }) => (
                                  <PhoneInput
                                    value={value}
                                    placeholder="Phone Number"
                                    onChange={onChange}
                                    defaultCountry="CA"
                                    id="phone-input"
                                  />
                                  )}
                                />
                            </InputGroup>
                            
                        </Form.Group>
                    </Col>
                    {/* <Col md={8} className="mb-3">
                        <Form.Group id="phone">
                            <Form.Label>Select a Role</Form.Label>

                            {errors.phone && <label className="text-danger d-block mb-3">{errors.phone?.message}</label>}

                            <Form.Select value={statusFilter} className="">
                              <option value="all">Real Estate Agent</option>
                              <option value="active">Active</option>
                              <option value="inactive">Inactive</option>
                              <option value="pending">Pending</option>
                              <option value="suspended">Suspended</option>
                          </Form.Select>
                        </Form.Group>
                    </Col> */}
                  </Row>
              
                  <div className="d-grid">
                    {
                      agentExists && <p className="text-center text-danger">Agent already exists</p>
                    }
                    {
                        btnloading ?
                          <Button variant="gray-800" type="button" disabled>
                            <span className="sr-only">Loading...</span>
                            <span className="loading-spacer"></span>
                            <span className="spinner-border spinner-border-sm float-right" role="status" aria-hidden="true"></span>
                          </Button>
                          :
                          <Button variant="gray-800" type="submit">Add Agent</Button>
                    }
                  </div>
                    </>
                }
                
                </Form>
                : <div>
                      <div className="p-3">
                        <h3 className="text-success text-center">Agent Added!</h3>
                      </div>
                      <div className="d-grid">
                      <Button variant="gray-800" onClick={() => {
                        handleClose();
                        refreshTeam();
                      }}>Close</Button>
                    </div>
                  </div>
                }
        
              </Card.Body>
            </Card>
          </Modal.Body>
        </Modal>

        <Modal centered size="lg" className="modal-tertiary" show={showAddTeam} onHide={handleCloseTeamModal}>
            <Modal.Header>
              <Button variant="close" className="btn-close-white text-white" aria-label="Close" onClick={handleCloseTeamModal} />
            </Modal.Header>
            <Modal.Body className="py-3">
              
            <Col xs={12} lg={12}>
              <Card border="0" className="shadow mb-4">
              <Card.Body>
                  {
                    teamFormState ? <>
                      <h5 className="mb-3 fw-extrabold">Create Team</h5>
                      <h6 className="mb-4 fw-extrabold">General information</h6>
                      <Form onSubmit={handleSubmit1(createTeam)}>
                        <Row>
                            <Col md={4} className="mb-3">
                            <Form.Group id="team_name">
                                <Form.Label>Team Name</Form.Label>
      
                                {errors1.team_name && <label className="text-danger d-block mb-3">{errors1.team_name?.message}</label>}
      
                                <Form.Control
                                type="text" 
                                name="team_name"
                                {...register1("team_name")}
                                placeholder="Team Name" 
                                />
                            </Form.Group>
                            </Col>
                            <Col md={4} className="mb-3">
                                <Form.Group id="email">
                                    <Form.Label>Email</Form.Label>
      
                                    {errors1.email && <label className="text-danger d-block">{errors1.email?.message}</label>} 
      
                                    <InputGroup>
                                        <Form.Control
                                        autoFocus
                                        type="email"
                                        name="email"
                                        {...register1("email")}
                                        placeholder="example@company.com"
                                        />
                                    </InputGroup>
                                </Form.Group>
                            </Col>
                            <Col md={4} className="mb-3">
                                <Form.Group id="phone">
                                    <Form.Label>Phone</Form.Label>
      
                                    {errors1.phone && <label className="text-danger d-block mb-3">{errors1.phone?.message}</label>}
      
                                    <InputGroup>
                                      <Controller
                                        name="phone"
                                        control={control1}
                                        rules={{
                                        validate: (value) => isValidPhoneNumber(value)
                                        }}
                                        render={({ field: { onChange, value } }) => (
                                        <PhoneInput
                                          value={value}
                                          placeholder="Phone Number"
                                          onChange={onChange}
                                          defaultCountry="CA"
                                          id="phone-input"
                                        />
                                        )}
                                      />
                                    
                                    </InputGroup>
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col md={12} className="mb-3">
                                <Form.Group controlId="description">
                                    <Form.Label>Description</Form.Label>
      
                                    {errors1.description && <label className="text-danger d-block mb-3">{errors1.description?.message}</label>}
      
                                    <Form.Control 
                                        as="textarea" 
                                        rows={3} 
                                        name="description"
                                        {...register1("description")}
                                        placeholder="Description"
                                    />
                                </Form.Group>
                            </Col>
                        </Row>
      
                        <h6 className="my-4 fw-extrabold">Location</h6>
                        <Row>
                            <Col sm={6} className="mb-3">
                            <Form.Group id="address1">
                                <Form.Label>Address 1</Form.Label>
      
                                {errors1.address1 && <label className="text-danger d-block mb-3">{errors1.address1?.message}</label>}
      
                                <Form.Control 
                                type="text" 
                                placeholder="Address 1"
                                name="address1"
                                {...register1("address1")}
                                />
                            </Form.Group>
                            </Col>
                            <Col sm={6} className="mb-3">
                            <Form.Group id="address2">
                                <Form.Label>Address 2</Form.Label>
      
                                {errors1.address2 && <label className="text-danger d-block mb-3">{errors1.address2?.message}</label>}
      
                                <Form.Control 
                                type="text" 
                                placeholder="Address 2 (Optional)" 
                                name="address2"
                                {...register1("address2")}
                                />
                            </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col sm={6} className="mb-3">
                            <Form.Group id="city">
                                <Form.Label>City</Form.Label>
      
                                {errors1.city && <label className="text-danger d-block mb-3">{errors1.city?.message}</label>}
      
                                <Form.Control 
                                type="text" 
                                placeholder="City" 
                                name="city"
                                {...register1("city")}
                                />
                            </Form.Group>
                            </Col>
                            <Col sm={6} className="mb-3">
                            <Form.Group className="mb-2">
                                <Form.Label>Select  Province</Form.Label>
      
                                {errors1.select && <label className="text-danger d-block mb-3">{errors1.select?.message}</label>}
      
                                <Form.Select 
                                id="state" 
                                defaultValue="0"
                                name="select"
                                {...register1("select")}
                                >
                                <option value="">State</option>
                                <option value="Alberta">Alberta</option>
                                <option value="BritishColumbia">British Columbia</option>
                                <option value="Manitoba">Manitoba</option>
                                <option value="NewBrunswick">New Brunswick</option>
                                <option value="NewfoundlandandLabrador">Newfoundland and Labrador</option>
                                <option value="NovaScotia">Nova Scotia</option>
                                <option value="Ontario">Ontario</option>
                                <option value="PrinceEdwardIsland">Prince Edward Island</option>
                                <option value="Quebec">Quebec</option>
                                <option value="Saskatchewan">Saskatchewan</option>
                                </Form.Select>
                            </Form.Group>
                            </Col>
                        </Row>
                        <div className="mt-3 text-center">
                          {
                            teambtnloading ?
                              <Button variant="gray-800" type="button" disabled>
                                <span className="sr-only">Loading...</span>
                                <span className="loading-spacer"></span>
                                <span className="spinner-border spinner-border-sm float-right" role="status" aria-hidden="true"></span>
                              </Button>
                              :
                              <Button variant="gray-800" type="submit" className="mt-2 animate-up-2">Create Team</Button>
                          }
                        </div>
                      </Form>
                    </>
                    :
                    <div className="flex-column justify-content-center align-items-center text-center">
                      <div className="d-inline-block">
                        <h6>Team Added Successfully</h6>
                        <Button variant="success" className="text-white" onClick={handleCloseTeamModal}>Close</Button>
                      </div>
                    </div>
                  }
                  
              </Card.Body>
              </Card>
            </Col>

            </Modal.Body>
          </Modal>
      </React.Fragment>
    </>
  );
};
