
import React, { useState, useEffect, useContext } from "react";
import moment from "moment-timezone";
import { BellIcon, CogIcon, InboxIcon, MenuAlt1Icon, SearchIcon, SupportIcon, UserCircleIcon } from "@heroicons/react/solid";
import { LogoutIcon } from "@heroicons/react/outline";
import { Row, Col, Nav, Form, Image, Button, Navbar, Dropdown, Container, ListGroup, InputGroup } from 'react-bootstrap';
import { UserContext } from "Context/UserContext";
import { LeadsDataContext } from "Context/LeadsDataContext";
import { AgencyContext } from "Context/AgencyContext";
import { AppointmentsDataContext } from "Context/AppointmentsDataContext";
import { AgencyLeadsDataContext } from "Context/AgencyLeadsContext";
import { userNotifications } from "data/notifications";
import { Auth } from 'aws-amplify';
import { useHistory } from "react-router-dom";
import { Routes } from "routes";


export default (props) => {
  const [notifications, setNotifications] = useState(userNotifications);
  const allNotificationsRead = notifications.reduce((acc, notif) => acc && notif.read, true);
  const bellIconClasses = !allNotificationsRead ? "unread" : "";
  const [loggedUser, setLoggedUser] = useContext(UserContext);
  const [leadsData, setLeadsData] = useContext(LeadsDataContext);
  const [AgencyAgentInfo, setAgencyAgentInfo] = useContext(AgencyContext);
  const [appointmentsData, setAppointmentsData] = useContext(AppointmentsDataContext);
  const [agencyleadsData, setAgencyLeadsData] = useContext(AgencyLeadsDataContext);
  const [profileImage, setProfileImage] = useState(null);
  const history = useHistory();

  const markNotificationsAsRead = () => {
    setTimeout(() => {
      setNotifications(notifications.map(n => ({ ...n, read: true })));
    }, 400);
  };
  

  const toggleContracted = () => props.toggleContracted && props.toggleContracted();

  const getFirstLetterOfEachWord = (text) => (
    text.match(/\b\w/g).join('')
  );

  useEffect(() => {
    AgentDetails()
  }, []);

  const agentUuid = loggedUser.user.username;
  const token = loggedUser.user.signInUserSession.idToken.jwtToken;

  // create agent when we have none

  async function AgentDetails() {
    try {
      const values = await fetch(`${process.env.REACT_APP_BASE_URL}/agent/${agentUuid}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          'Accept': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify()
      })
      .then(res => {
        if (res.ok) return res.json()
        // If there is an error then make sure we catch that
        return res.json().then(
          e => Promise.reject(e)
        )
      }).then(({ agent }) => {
        setProfileImage(agent.avatarUrl);
      })
    } catch (error) {
      // console.log(error);
    }
  }

  async function signOut() {
    try {
        await Auth.signOut();
        await setLoggedUser({
          isAuthenticated: false,
          user: null,
          licenseDetails: null
        });
        await setLeadsData(null);
        await setAgencyAgentInfo({});
        await setAppointmentsData(null);
        await setAgencyLeadsData(null);
        history.push(Routes.Signin.path);
    } catch (error) {
        console.log('error signing out: ', error);
    }
  }

  return (
    <Navbar expand variant="dark" className="navbar-top navbar-dashboard ps-0 pe-2 pb-0 border-bottom">
      <Container fluid className="px-0">
        <div className="d-flex justify-content-between w-100">
          <div className="d-flex align-items-center">
            <Button
              size="lg"
              id="sidebar-toggle"
              variant="icon-only"
              className="sidebar-toggle d-none d-lg-inline-block align-items-center justify-content-center me-3 invisible"
              onClick={toggleContracted}
            >
              <MenuAlt1Icon className="toggle-icon" />
            </Button>
          </div>
          <Nav className="align-items-center">

            <Dropdown as={Nav.Item} className="ms-lg-3">
              <Dropdown.Toggle as={Nav.Link} className="pt-1 px-0">
                <div className="media d-flex align-items-center">

                 {profileImage
                    ? (
                      <Image
                        src={profileImage}
                        className="avatar rounded-circle"
                      />
                    ) : (
                      <div className="avatar d-flex align-items-center justify-content-center fw-bold rounded-circle bg-secondary">
                        <span>{getFirstLetterOfEachWord(`${loggedUser.user.attributes.family_name} ${loggedUser.user.attributes.given_name}`)}</span>
                      </div>
                  )}
                  <div className="media-body ms-2 text-dark align-items-center d-none d-lg-block">
                    <span className="mb-0 font-small fw-bold text-gray-900">{`${loggedUser.user.attributes.family_name} ${loggedUser.user.attributes.given_name}`}</span>
                  </div>
                </div>
              </Dropdown.Toggle>
              <Dropdown.Menu className="dashboard-dropdown dropdown-menu-end mt-2 py-1">
                <Dropdown.Item className="d-flex align-items-center" onClick={signOut}>
                  <LogoutIcon className="dropdown-icon text-danger me-2" /> Logout
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </Nav>
        </div>
      </Container>
    </Navbar>
  );
};
