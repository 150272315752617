import React, { useState, useEffect, useContext } from "react";
import { useHistory, useLocation } from "react-router-dom";

import { HomeIcon, BellIcon } from "@heroicons/react/solid";
import { Col, Row, Alert, Breadcrumb, Card } from 'react-bootstrap';
import { UserContext } from "Context/UserContext";
import { IndividualAgentContext } from "Context/IndividualAgentContext";
import { Routes } from "routes";


export default () => {

  const [loggedUser, setLoggedUser] = useContext(UserContext);
  const [AgentInfo, setAgentInfo] = useContext(IndividualAgentContext);
  const [loading, setLoading] = useState(false);

  const token = loggedUser.user.signInUserSession.idToken.jwtToken;


  return (
    <>
      <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
        <div className="d-block mb-2 mb-md-0">
          <Breadcrumb className="d-none d-md-inline-block" listProps={{ className: "breadcrumb-dark breadcrumb-transparent" }}>
            <Breadcrumb.Item><HomeIcon className="icon icon-xs" /></Breadcrumb.Item>
            <Breadcrumb.Item>Harvast</Breadcrumb.Item>
            <Breadcrumb.Item active>Create Agent</Breadcrumb.Item>
          </Breadcrumb>
        </div>
      </div>

      <React.Fragment>
        <Col xs={12} lg={12} xl={12}>
          <Alert variant="danger">
            Your Subscription is Cancelled
          </Alert>
        </Col>
      </React.Fragment>

      <Card border="0" className="shadow my-4 ">
          <Card.Body>
            <Row className="text-gray">

            <React.Fragment>
                  <Col xs={12} lg={12} xl={12}>
                      <Alert variant="info" className="mb-0">
                      <Alert.Heading className="d-flex align-items-center mt-2">
                        <BellIcon className="icon icon-md me-2" />
                           Dear Agent Your Subscription is Cancelled
                      </Alert.Heading>
                      <hr />
                    </Alert>
                </Col>
              </React.Fragment>
          
            </Row>
          </Card.Body>
        </Card>
    </>
  );
};
