import React, { useState, useEffect, useContext } from "react";
import { Col, Row, Alert } from 'react-bootstrap';
import moment from "moment-timezone";

import { AgencyLeadsWidget, AgencyAppointmentsWidget, TopDoorsWidget, TopAppointmentsWidget, TopLeadsWidget, CardWidget } from "components/Widgets";
import { UserContext } from "Context/UserContext";
import { AgencyLeadsDataContext } from "Context/AgencyLeadsContext";
import { AppointmentsDataContext } from "Context/AppointmentsDataContext";


export default () => {
  const [loggedUser, setLoggedUser] = useContext(UserContext);
  const [agencyleadsData, setAgencyLeadsData] = useContext(AgencyLeadsDataContext);
  const [paymentAlert, setPaymentAlert] = useState(false);
  const [pageLoading, setPageLoading] = useState(true);
  const [pageLoading2, setPageLoading2] = useState(true);
  const [noAgentData, setNoAgentData] = useState(true);
  const [appointmentStatus, setAppointmentStatus] = useState({
    total_appointments : null,
    leadRatePercentage: null
  })

  const [leadStatus, setleadStatus] = useState({
    leadRatePercentage: null
})


  useEffect(() => {
    if ((loggedUser.licenseDetails.subscriptionStatus == "pending_cancellation" && loggedUser.licenseDetails.paymentStatus == "paid")){
      setPaymentAlert(true);
    }
    // setAgencyLeadsData(null);
    getYearLeads();
    // calls data to check top agents
  }, []);


  useEffect(() => {
    if (agencyleadsData === null) {
      return
    }
    topAgents();
    checkLead();
    checkAppointment()
  }, [agencyleadsData]);


  const token = loggedUser.user.signInUserSession.idToken.jwtToken;

  const agencyUuid = loggedUser.licenseDetails.agencyUuid;

  async function getYearLeads() {
    try {
      const values = await fetch(`${process.env.REACT_APP_BASE_URL}/stats/${agencyUuid}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          'Accept': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify()
      })
      .then(res => {
        if (res.ok) return res.json()
        // If there is an error then make sure we catch that
        return res.json().then(
          e => Promise.reject(e)
        )
      })      
      .then(({ stats }) => {
        console.log(stats)
        sessionStorage.removeItem('leadsStats');
        sessionStorage.setItem("leadsStats", JSON.stringify(stats));
         //replace this with saving data on the local storage to prevent the data from being manipulated
         setAgencyLeadsData({
          doorsKnocked: stats.chartData.knocks.totals.year || 0,
          totalAppointments: stats.chartData.appointments.totals.year || 0,
          totalContacts: stats.chartData.contacts.totals.year || 0,
          totalLeads: stats.chartData.leads.totals.year || 0
        });
        getMonthLeads();
      })
    } catch (error) {
      setPageLoading(false);
      console.log(error);
    }
  }

  async function getMonthLeads() {
    try {
      const values = await fetch(`${process.env.REACT_APP_BASE_URL}/stats/${agencyUuid}?timeframe=month`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          'Accept': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify()
      })
      .then(res => {
        if (res.ok) return res.json()
        // If there is an error then make sure we catch that
        return res.json().then(
          e => Promise.reject(e)
        )
      })      
      .then(({ stats }) => {
        sessionStorage.setItem("leadsStatsMonth", JSON.stringify(stats));
        getWeekLeads();
      })
    } catch (error) {
      console.log(error);
    }
  }

  async function getWeekLeads() {
    try {
      const values = await fetch(`${process.env.REACT_APP_BASE_URL}/stats/${agencyUuid}?timeframe=week`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          'Accept': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify()
      })
      .then(res => {
        if (res.ok) return res.json()
        // If there is an error then make sure we catch that
        return res.json().then(
          e => Promise.reject(e)
        )
      })      
      .then(({ stats }) => {
        sessionStorage.setItem("leadsStatsWeek", JSON.stringify(stats));
        getDayLeads();
      })
    } catch (error) {
      console.log(error);
    }
  }

  async function getDayLeads() {
    try {
      const values = await fetch(`${process.env.REACT_APP_BASE_URL}/stats/${agencyUuid}?timeframe=day`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          'Accept': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify()
      })
      .then(res => {
        if (res.ok) return res.json()
        // If there is an error then make sure we catch that
        return res.json().then(
          e => Promise.reject(e)
        )
      })      
      .then(({ stats }) => {
        console.log(stats)
        sessionStorage.setItem("leadsStatsDay", JSON.stringify(stats));
      })
    } catch (error) {
      console.log(error);
    }
  }


  async function topAgents() {
    let year = moment().year();
    const today = moment().format("YYYY-MM-DD");

    try {
      await fetch(`${process.env.REACT_APP_BASE_URL}/stats/${agencyUuid}/agents?start=${year}-01-01&end=${today}&limit=10`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          'Accept': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify()
      })
      .then(res => {
        if (res.ok) return res.json()
        // If there is an error then make sure we catch that
        return res.json().then(
          e => Promise.reject(e)
        )
      })
      .then(({ top }) => {
        sessionStorage.removeItem('topAgents');
        sessionStorage.setItem("topAgents", JSON.stringify(top));
        setNoAgentData(false);
        setPageLoading(false);
     })
     setPageLoading2(false);
    } catch (error) {
      setPageLoading(false);
      console.log(error);
    }
  }



//   check leads status
  function checkLead() {    
      let generationRateRaw =  agencyleadsData.totalLeads /  agencyleadsData.totalContacts * 100;
      let generationRate =  Math.round(generationRateRaw);
      setleadStatus({
        leadRatePercentage: generationRate
      })
  }

  function checkAppointment() { 
    let totalAppointment = 0;

    let AppointmentsRateRaw = agencyleadsData.totalAppointments / agencyleadsData.totalLeads * 100;
    let AppointmentsRate = Math.round(AppointmentsRateRaw);

    setAppointmentStatus({
      total_appointments : totalAppointment,
      appointmentsRatePercentage: AppointmentsRate
    })
  }

  return (
    <>
    {
      paymentAlert &&
        <React.Fragment>
          <Col xs={12} lg={12} xl={12} className="mt-4 mb-lg-0">
            <Alert variant="danger">
              Your subscription is pending for cancellation.
            </Alert>
          </Col>
      </React.Fragment>
    }
    

    {
      pageLoading ? <div className="page-loading">
      <div className="d-flex justify-content-center">
          <div className="spinner-border" role="status">
            <span className="visually-hidden">Loading...</span>
          </div>
        </div>
      </div>
    : noAgentData ? <div className="page-loading">
        <div className="d-flex justify-content-center">
          <h1>No Data</h1>
        </div>
      </div>
    :
    <>

    <div className="mt-4 mb-lg-0">
      <h2 className="fw-extrabold display-4 mb-4">Agency Stats</h2>
    </div>

    <Row className="mb-4"> 

      <Col xs={12} sm={6} lg={3} className="mb-4 mb-xxl-0">
        <CardWidget
          title="Leads Created"
          value={agencyleadsData.totalLeads}
        />
      </Col>

      <Col xs={12} sm={6} lg={3} className="mb-4 mb-xxl-0">
        <CardWidget
          title="Contacts"
          value={agencyleadsData.totalContacts}
        />
      </Col>

      <Col xs={12} sm={6} lg={3} className="mb-4 mb-xxl-0">
        <CardWidget
          title="Doors Knocked"
          value={agencyleadsData.doorsKnocked}
        />
      </Col>

      <Col xs={12} sm={6} lg={3} className="mb-4 mb-xxl-0">
        <CardWidget
          title="Appointments"
          value={agencyleadsData.totalAppointments}
        />
      </Col>

    </Row>

    <Row className="justify-content-lg-center">
      <Col xs={12} className="mb-4">
        <AgencyLeadsWidget 
          title="Lead Generation"
          value={agencyleadsData.totalLeads}
          percentage={leadStatus.leadRatePercentage}
        />
      </Col>
    </Row>

    <Row className="justify-content-lg-center">
      <Col xs={12} className="mb-4">
        <AgencyAppointmentsWidget
          title="Appointments Generation"
          value={agencyleadsData.totalAppointments}
          percentage={appointmentStatus.appointmentsRatePercentage}
        />
      </Col>
    </Row>

    <Row>
        <Col xs={12} md={6} xl={4} xxl={4} className="mb-4">
          <TopDoorsWidget title="Most Doors Knocked" />
        </Col>

        <Col xs={12} md={6} xl={4} xxl={4} className="mb-4">
          <TopAppointmentsWidget title="Most Appointments Booked" />
        </Col>

        <Col xs={12} md={6} xl={4} xxl={4} className="mb-4">
          <TopLeadsWidget title="Most Leads Generated" />
        </Col>
    </Row>
    </>
    }
    
    </>
  );
};
