import React, {useState, useContext} from "react";
import { useHistory } from "react-router-dom";
import { Routes } from "routes";
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { Col, Row, Card, Form, Button, InputGroup} from 'react-bootstrap';
import { createTeamformOptions } from "components/validator/validator";
import { UserContext } from "Context/UserContext";
import { AgencyContext } from "Context/AgencyContext";
import {useForm, Controller} from "react-hook-form";
import { Link } from 'react-router-dom';

import PhoneInput, { isValidPhoneNumber } from "react-phone-number-input";
import "react-phone-number-input/style.css";

const SwalWithBootstrapButtons = withReactContent(Swal.mixin({
    customClass: {
      confirmButton: 'btn btn-primary me-3',
      cancelButton: 'btn btn-gray'
    },
    buttonsStyling: false
}));

export default () => {
    const [loggedUser, setLoggedUser] = useContext(UserContext);
    const [AgencyAgentInfo, setAgencyAgentInfo] = useContext(AgencyContext);
    const [creationStatus, setCreationStatus] = useState('initial');
    const [urlPath, setUrlPath] = useState(null);
    const [cognitoerror, setcognitoError] = useState(null);
    const token = loggedUser.user.signInUserSession.idToken.jwtToken;
    const history = useHistory();

    const { 
        register,
        handleSubmit, 
        reset,
        control,
        formState: { errors } 
      } = useForm(createTeamformOptions);
    
     async function createTeam(data) {
    //    setCreationStatus('checkingSubscription');
        try {
          const createAgentResponse = await fetch(`${process.env.REACT_APP_BASE_URL}/team`, {
            method: "PUT",
            headers: {
              "Content-Type": "application/json",
              'Accept': 'application/json',
              'Authorization': `Bearer ${token}`
            },
            body: JSON.stringify(
              {
                "teamName": data.team_name,
                "description": data.description,
                "phone": data.phone,
                "email": data.email,
                "address1": data.address1,
                "address2": data.address2,
                "city": data.city,
                "province": data.province,
                "agencyUuid": loggedUser.licenseDetails.agencyUuid
             }),
          })
          .then(res => {
            if (res.ok) return res.json()
            // If there is an error then make sure we catch that
            return res.json().then(e => Promise.reject(e))
          }).then(({ team }) => {
            // setLoading(true);
            // agencyCreated(agency);
            // getSubscriptions(agency);
            console.log(team)
            setTimeout(
                function() {
                  history.push(Routes.ManageTeam.path)
                },
                2500
            );
        
          })
        } catch (error) {
           console.log(error)
           setcognitoError(error.message);
        }
    }

  return (
    <>

      <Row className="mt-1">

        <Col xs={12} lg={12}>
        <Card border="0" className="shadow mb-4">
            <Card.Body>
                <h5 className="mb-3 fw-extrabold">Create Team</h5>
                {
                 cognitoerror && <div className="text-center text-md-center text-danger">Error: {cognitoerror}</div>
                }
                {
                  creationStatus === "initial" && <Card border="0" className="shadow mb-4">
                            <Card.Body>
                                <h6 className="mb-4 fw-extrabold">General information</h6>
                                <Form onSubmit={handleSubmit(createTeam)}>
                                <Row>
                                    <Col md={4} className="mb-3">
                                    <Form.Group id="team_name">
                                        <Form.Label>Team Name</Form.Label>

                                        {errors.team_name && <label className="text-danger d-block mb-3">{errors.team_name?.message}</label>}

                                        <Form.Control
                                        type="text" 
                                        name="team_name"
                                        {...register("team_name")}
                                        placeholder="Team Name" 
                                        />
                                    </Form.Group>
                                    </Col>
                                    <Col md={4} className="mb-3">
                                        <Form.Group id="email">
                                            <Form.Label>Email</Form.Label>

                                            {errors.email && <label className="text-danger d-block">{errors.email?.message}</label>} 

                                            <InputGroup>
                                                <Form.Control
                                                autoFocus
                                                type="email"
                                                name="email"
                                                {...register("email")}
                                                placeholder="example@company.com"
                                                />
                                            </InputGroup>
                                        </Form.Group>
                                    </Col>
                                    <Col md={4} className="mb-3">
                                        <Form.Group id="phone">
                                            <Form.Label>Phone</Form.Label>

                                            {errors.phone && <label className="text-danger d-block mb-3">{errors.phone?.message}</label>}

                                            <InputGroup>
                                              <Controller
                                                name="phone"
                                                control={control}
                                                rules={{
                                                validate: (value) => isValidPhoneNumber(value)
                                                }}
                                                render={({ field: { onChange, value } }) => (
                                                <PhoneInput
                                                  value={value}
                                                  placeholder="Phone Number"
                                                  onChange={onChange}
                                                  defaultCountry="CA"
                                                  id="phone-input"
                                                />
                                                )}
                                              />
                                            
                                            </InputGroup>
                                        </Form.Group>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md={12} className="mb-3">
                                        <Form.Group controlId="description">
                                            <Form.Label>Description</Form.Label>

                                            {errors.description && <label className="text-danger d-block mb-3">{errors.description?.message}</label>}

                                            <Form.Control 
                                                as="textarea" 
                                                rows={3} 
                                                name="description"
                                                {...register("description")}
                                                placeholder="Description"
                                            />
                                        </Form.Group>
                                    </Col>
                                </Row>

                                <h6 className="my-4 fw-extrabold">Location</h6>
                                <Row>
                                    <Col sm={6} className="mb-3">
                                    <Form.Group id="address1">
                                        <Form.Label>Address 1</Form.Label>

                                        {errors.address1 && <label className="text-danger d-block mb-3">{errors.address1?.message}</label>}

                                        <Form.Control 
                                        type="text" 
                                        placeholder="Address 1"
                                        name="address1"
                                        {...register("address1")}
                                        />
                                    </Form.Group>
                                    </Col>
                                    <Col sm={6} className="mb-3">
                                    <Form.Group id="address2">
                                        <Form.Label>Address 2</Form.Label>

                                        {errors.address2 && <label className="text-danger d-block mb-3">{errors.address2?.message}</label>}

                                        <Form.Control 
                                        type="text" 
                                        placeholder="Address 2 (Optional)" 
                                        name="address2"
                                        {...register("address2")}
                                        />
                                    </Form.Group>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col sm={6} className="mb-3">
                                    <Form.Group id="city">
                                        <Form.Label>City</Form.Label>

                                        {errors.city && <label className="text-danger d-block mb-3">{errors.city?.message}</label>}

                                        <Form.Control 
                                        type="text" 
                                        placeholder="City" 
                                        name="city"
                                        {...register("city")}
                                        />
                                    </Form.Group>
                                    </Col>
                                    <Col sm={6} className="mb-3">
                                    <Form.Group className="mb-2">
                                        <Form.Label>Select  Province</Form.Label>

                                        {errors.select && <label className="text-danger d-block mb-3">{errors.select?.message}</label>}

                                        <Form.Select 
                                        id="state" 
                                        defaultValue="0"
                                        name="select"
                                        {...register("select")}
                                        >
                                        <option value="">State</option>
                                        <option value="Alberta">Alberta</option>
                                        <option value="BritishColumbia">British Columbia</option>
                                        <option value="Manitoba">Manitoba</option>
                                        <option value="NewBrunswick">New Brunswick</option>
                                        <option value="NewfoundlandandLabrador">Newfoundland and Labrador</option>
                                        <option value="NovaScotia">Nova Scotia</option>
                                        <option value="Ontario">Ontario</option>
                                        <option value="PrinceEdwardIsland">Prince Edward Island</option>
                                        <option value="Quebec">Quebec</option>
                                        <option value="Saskatchewan">Saskatchewan</option>
                                        </Form.Select>
                                    </Form.Group>
                                    </Col>
                                </Row>
                                <div className="mt-3">
                                    <Button variant="gray-800" type="submit" className="mt-2 animate-up-2">
                                     Create Team
                                    </Button>
                                </div>
                                </Form>
                            </Card.Body>
                        </Card>      
                }

                {
                    creationStatus === "checkingSubscription" && <Row className="text-gray">

                      <Col xs={12} lg={12} xl={12}>
                          <Card border="0" className="">
                            <div className="border-bottom pb-2">
                              <h4 className="display-5 mb-2">...Confirming Subscription </h4> <div className="spinner-grow text-primary" role="status"></div>
                            </div>
                          </Card>
                      </Col>
                    </Row>
                }

                {
                    creationStatus === "linking" && <Row className="text-gray">

                      <Col xs={12} lg={12} xl={12}>
                          <Card border="0" className="">
                            <div className="border-bottom pb-2">
                              <h4 className="display-5 mb-2">...Creating Agency </h4> <div className="spinner-grow text-primary" role="status"></div>
                            </div>
                          </Card>
                      </Col>
                    </Row>
                }
                
            </Card.Body>
            </Card>
        </Col>

      </Row>
    </>
  );
};