
import React, { useState, useContext, } from "react";
import { ArrowNarrowLeftIcon, LockClosedIcon, MailIcon, PhoneIcon, UserIcon, ThumbUpIcon,EyeIcon, EyeOffIcon } from "@heroicons/react/solid";
import { Col, Row, Form, Card, Button, FormCheck, Container, Image, InputGroup } from 'react-bootstrap';
import { Link, useHistory } from 'react-router-dom';
import {useForm, Controller} from "react-hook-form";
import { Routes } from "routes";
import { Auth } from "aws-amplify";
import { formOptions, codeFormOptions } from "components/validator/validator";
import FormErrors from "components/validator/FormErrors";
import logo from "assets/img/brand/harvast-logo.svg";
import PhoneInput, { isValidPhoneNumber } from "react-phone-number-input";
import { UserContext } from "Context/UserContext";
import "react-phone-number-input/style.css";

export default () => {
  const [loggedUser, setLoggedUser] = useContext(UserContext);
  const [phoneinfo, setphoneinfo] = useState('');
  const [cognitoerror, setcognitoError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [activeForm, setactiveForm] = useState("signUpForm");
  const [codeStatus, setCodeStatus] = useState('');
  const [success, setSucesss] = useState(false);
  const [passwordInfo, setPasswordInfo] = useState('');
  const [ showPassword, setShowPassword ] = useState(false);
  const [ showConfirmPassword, setShowConfirmPassword ] = useState(false);

  const { 
    register, 
    handleSubmit, 
    reset, 
    control,
    formState: { errors } 
  } = useForm(formOptions);

  const { 
    register:register1, 
    handleSubmit:handleSubmit1, 
    reset:reset1,
    formState: { errors: errors1 }  
  } = useForm(codeFormOptions);  

  const AUTH_USER_TOKEN_KEY = "";
  const history = useHistory();
  
 
  async function signUp(data) {
    setLoading(true);
    let lowercase_email = data.email.toLowerCase();
    setphoneinfo(data.phone);
    setPasswordInfo(data.confirmPassword);
    try {
      await Auth.signUp({
        username: data.phone,
        password: data.confirmPassword,
        attributes: {
          email:lowercase_email,
          phone_number: data.phone,
          given_name: data.first_name,
          family_name: data.last_name
        }
      });
      setcognitoError(null);
      setactiveForm("CodeForm");
      setLoading(false);
    } catch (error) {
      // let err = null;
      // !error.message ? err = { "message": error } : err = error;
      console.log(error)
      setLoading(false);
      setcognitoError(error.message);
      // console.log(`error signing up: ${err}`);
    }
  }

  // code sending
  async function confirmSignUp(data) {
    setLoading(true);
    try {
      await Auth.confirmSignUp(phoneinfo, data.code);
      signIn();
      setcognitoError(null);
    } catch (error) {
      setLoading(false);
      setcognitoError(error.message);
      console.log('error confirming sign up', error);
    }
  }

  async function resendConfirmationCode(e) {
    e.preventDefault();
    try {
        await Auth.resendSignUp(phoneinfo);
        setCodeStatus('code resent successfully');
        console.log('code resent successfully');
    } catch (err) {
        setCodeStatus(err.message);
        console.log('error resending code: ', err);
    }
  }
  // code sending

   // signs in
    async function signIn() {
      try {
          const user = await Auth.signIn(phoneinfo, passwordInfo);
          localStorage.setItem(AUTH_USER_TOKEN_KEY, user.signInUserSession.idToken.jwtToken);
          setLoggedUser({
            ...loggedUser,
            isAuthenticated: true,
            user: user
          });
          getSubscriptions();
      } catch (error) {
          setLoading(false);
          setcognitoError(error.message);
      }
  }

  // Check subsripttion status
  async function getSubscriptions() {
    let token = localStorage.getItem(AUTH_USER_TOKEN_KEY);
    let phoneData = phoneinfo.substring(1);
    try {
      await fetch(`${process.env.REACT_APP_BASE_URL}/subscriptions?phone=%2B${phoneData}`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            'Accept': 'application/json',
            'Authorization': `Bearer ${token}`
          },
          body: JSON.stringify(),
        })
        .then(res => {
          if (res.ok) return res.json()
          // If there is an error then make sure we catch that
          return res.json().then(
            e => Promise.reject(e)
          )
        }).then(({subscription}) => {
          setLoggedUser({
            ...loggedUser,
            licenseDetails: subscription
          })

          if(subscription.allotedSeats > 1 && !subscription.subscriptionStatus.toLowerCase().includes("cancelled") && !subscription.licenseType.includes("free")){
            history.push(Routes.DashboardOverview.path);
          } else if (subscription.allotedSeats == 1 && !subscription.subscriptionStatus.toLowerCase().includes("cancelled") && !subscription.licenseType.includes("free")){
            history.push(Routes.DashboardOverview.path);
          } else if((subscription.allotedSeats == 1 && subscription.subscriptionStatus.toLowerCase().includes("cancelled")) || (subscription.allotedSeats > 1 && subscription.subscriptionStatus.toLowerCase().includes("cancelled")) ){
            history.push(Routes.Cancelled.path);
          } else if (subscription.allotedSeats == 1 && !subscription.subscriptionStatus.toLowerCase().includes("cancelled") && subscription.licenseType.includes("free")){
            history.push(Routes.Welcome.path);
          } else{
            history.push(Routes.Welcome.path);
          }
          setSucesss(true);
          setLoading(false);
        })
      } catch (error) {
        console.log(error)
        setSucesss(true);
        setLoading(false);
        history.push(Routes.Transactions.path);
      }
    }

  return (
    <main>
      <section className="vh-lg-100 mt-5 mt-lg-0 bg-soft d-flex align-items-center overflow-auto">
        <Container>
          <p className="text-center">
            <a href="https://harvast.com" target='_blank' className="d-flex align-items-center justify-content-center">
              <ArrowNarrowLeftIcon className="icon icon-xs me-2" /> Back to homepage
            </a>
          </p>
          <div className="justify-content-center form-bg-image">
            <div className="d-flex align-items-center justify-content-center">
              <div className="bg-white shadow border-0 rounded border-light p-4 p-lg-5 w-100 fmxw-720">
                <div className="text-center text-md-center mb-2 mt-md-0">
                    <Image fluid rounded src={logo} className="logo-img"  />
                </div>
                <div className="text-center text-md-center mb-4 mt-md-0">
                  <h3 className="mb-0">Create an account</h3>
                </div>

                {
                  activeForm === "signUpForm" && <Form className="mt-4" onSubmit={handleSubmit(signUp)}> 
                  <Row>

                   <Col md={6} className="mb-4">
                      <Form.Group id="first_name">
                        <Form.Label>First Name</Form.Label>

                        {errors.first_name && <label className="text-danger d-block">{errors.first_name?.message}</label>}

                        <InputGroup>
                          <InputGroup.Text>
                            <UserIcon className="icon icon-xs text-gray-600" />
                          </InputGroup.Text>
                          <Form.Control
                            autoFocus
                            type="text"
                            name="first_name"
                            {...register("first_name")}
                            placeholder="First Name"
                          />
                        </InputGroup>
                      </Form.Group>
                    </Col>
                    <Col md={6} className="mb-4">
                      <Form.Group id="last_name">
                        <Form.Label>Last Name</Form.Label>

                        {errors.last_name && <label className="text-danger d-block">{errors.last_name?.message}</label>}

                        <InputGroup>
                          <InputGroup.Text>
                            <UserIcon className="icon icon-xs text-gray-600" />
                          </InputGroup.Text>
                          <Form.Control
                            autoFocus
                            type="text"
                            name="last_name"
                            {...register("last_name")}
                            placeholder="Last Name"
                          />
                        </InputGroup>
                      </Form.Group>
                    </Col>

                    <Col md={6} className="mb-4">
                      <Form.Group id="email">
                        <Form.Label>Email</Form.Label>

                        {errors.email && <label className="text-danger d-block">{errors.email?.message}</label>}

                        <InputGroup>
                          <InputGroup.Text>
                            <MailIcon className="icon icon-xs text-gray-600" />
                          </InputGroup.Text>
                          <Form.Control
                            autoFocus
                            type="email"
                            name="email"
                            {...register("email")}
                            placeholder="example@company.com"
                          />
                        </InputGroup>
                      </Form.Group>
                    </Col>
                    
                    <Col md={6} className="mb-4">
                      <Form.Group id="phone">
                        <Form.Label>Phone Number</Form.Label>

                        {errors.phone && <label className="text-danger d-block">{errors.phone?.message}</label>}

                        <InputGroup>
                          <Controller
                            name="phone"
                            control={control}
                            rules={{
                            validate: (value) => isValidPhoneNumber(value)
                            }}
                            render={({ field: { onChange, value } }) => (
                            <PhoneInput
                              value={value}
                              placeholder="Phone Number"
                              onChange={onChange}
                              defaultCountry="CA"
                              id="phone-input"
                            />
                            )}
                          />
                        
                        </InputGroup>
                      </Form.Group>
                    </Col>

                    <Col md={6} className="mb-4">
                      <Form.Group id="password">
                        <Form.Label>Password</Form.Label>

                        {errors.password && <label className="text-danger d-block">{errors.password?.message}</label>}

                        <InputGroup>
                          <InputGroup.Text>
                            <LockClosedIcon className="icon icon-xs text-gray-600" />
                          </InputGroup.Text>
                          <Form.Control
                            type={showPassword ? "text" : "password"}
                            name="password"
                            placeholder="Password"
                            {...register("password")}
                          />
                           <InputGroup.Text>
                               {
                                  showPassword ? <EyeIcon className="icon icon-xs text-gray-600" onClick={() => setShowPassword(!showPassword)} /> 
                                : <EyeOffIcon className="icon icon-xs text-gray-600" onClick={() => setShowPassword(!showPassword)} />
                              }
                            </InputGroup.Text>
                        </InputGroup>
                      </Form.Group>
                    </Col>
 
                    <Col md={6} className="mb-4">
                      <Form.Group id="confirmPassword">
                          <Form.Label>Confirm Password</Form.Label>

                          {errors.confirmPassword && <label className="text-danger d-block">{errors.confirmPassword?.message}</label>}

                          <InputGroup>
                            <InputGroup.Text>
                              <LockClosedIcon className="icon icon-xs text-gray-600" />
                            </InputGroup.Text>
                              <Form.Control 
                              type={showConfirmPassword ? "text" : "password"}
                              name="confirmPassword"
                              placeholder="Confirm Password"
                              {...register("confirmPassword")}
                              />
                             <InputGroup.Text>
                               {
                                  showConfirmPassword ? <EyeIcon className="icon icon-xs text-gray-600" onClick={() => setShowConfirmPassword(!showConfirmPassword)} /> 
                                : <EyeOffIcon className="icon icon-xs text-gray-600" onClick={() => setShowConfirmPassword(!showConfirmPassword)} />
                              }
                            </InputGroup.Text>
                          </InputGroup>
                      </Form.Group>
                    </Col>

                    <Col md={12} className="mb-2">
                      <Card.Link className="fw-bold" href="https://harvast.com/terms/" target='_blank'>Read full Terms and Conditions</Card.Link>
                    </Col>

                    <Col md={12} className="mb-4">

                      {errors.checkbox && <label className="text-danger d-block">{errors.acceptTerms?.message}</label>}

                      <FormCheck type="checkbox">
                        <FormCheck.Input 
                        id="terms" 
                        className="me-2"
                        {...register("acceptTerms")}
                        />
                        <FormCheck.Label htmlFor="terms" className="fw-normal mb-0">
                          I agree to the <Card.Link className="fw-bold">terms and conditions</Card.Link>
                        </FormCheck.Label>
                      </FormCheck>
                    
                    </Col>

                    <Col md={12}>
                      <FormErrors formerrors={cognitoerror} />
                    </Col>

                    </Row>

                    <div className="d-grid">
                      
                      {
                      loading ? 
                      <Button variant="gray-800" type="button" disabled>
                          <span className="sr-only">Loading...</span>
                          <span className="loading-spacer"></span>
                          <span className="spinner-border spinner-border-sm float-right" role="status" aria-hidden="true"></span>
                      </Button>
                       : 
                       <Button variant="gray-800" type="submit">
                        Sign up
                       </Button>
                      }

                    </div>

                  </Form>
                }

                {
                  activeForm === "CodeForm" && <Form className="mt-4" onSubmit={handleSubmit1(confirmSignUp)}>
                    <Form.Group id="code">
                      <Form.Label>Confirmation Code</Form.Label>

                      {errors1.code && <label className="text-danger d-block">{errors1.code?.message}</label>}

                      <InputGroup>
                        <InputGroup.Text>
                          <ThumbUpIcon className="icon icon-xs text-gray-600" />
                        </InputGroup.Text>
                        <Form.Control
                          autoFocus
                          type="number"
                          name="code"
                          placeholder="Confirmation Code"
                          {...register1("code")}
                        />
                      </InputGroup>
                    </Form.Group>

                    <div className="d-flex justify-content-end align-items-center">
                      <a className="my-3 text-primary text-right" onClick={resendConfirmationCode}>Resend Code</a>
                    </div>

                    {codeStatus  && <label className="text-danger d-block">{codeStatus}</label>}

                    {success ? 
                  <div className="d-inline-flex"> <p className="text-success ">Account Confirmed Successfully:</p> <span className="loading-spacer"></span> <Card.Link as={Link} to={Routes.Signin.path} className="fw-bold">{` Login here `}</Card.Link></div>
                  : ` `}

                    <FormErrors formerrors={cognitoerror} />

                    <div className="d-grid">
                     {
                      loading ? 
                      <Button variant="gray-800" type="button" disabled>
                          <span className="sr-only">Loading...</span>
                          <span className="loading-spacer"></span>
                          <span className="spinner-border spinner-border-sm float-right" role="status" aria-hidden="true"></span>
                      </Button>
                       : 
                       <Button variant="gray-800" type="submit">
                         Confirm Code
                       </Button>
                      }
                    </div>
                  </Form>
                }

                <div className="d-flex justify-content-center align-items-center mt-4">
                  <span className="fw-normal">
                    Already have an account?
                    <Card.Link as={Link} to={Routes.Signin.path} className="fw-bold">
                      {` Login here `}
                    </Card.Link>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </Container>
      </section>
    </main>
  );
};
