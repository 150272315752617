import React, { useState, useEffect, useContext } from "react";
import { useHistory, useLocation } from "react-router-dom";

import { HomeIcon, BellIcon } from "@heroicons/react/solid";
import { Col, Row, Alert, Breadcrumb, Card, Image } from 'react-bootstrap';
import { UserContext } from "Context/UserContext";
import { AgencyContext } from "Context/AgencyContext";
import { Routes } from "routes";
import GooglePlayImage from "assets/img/icons/google-play.png";
import AppleStoreImage from "assets/img/icons/apple-store.png";


export default () => {

  const [loggedUser, setLoggedUser] = useContext(UserContext);
  const [AgencyAgentInfo, setAgencyAgentInfo] = useContext(AgencyContext);
  const [loading, setLoading] = useState(false);
  const [showDownloadLink, setShowDownloadLink] = useState(false);
  const token = loggedUser.user.signInUserSession.idToken.jwtToken;
  const history = useHistory();
  const location = useLocation();

  //   if(location.state == undefined || location.state == null || location.state == ''){
  //   history.push("/");   
  //   }

  useEffect(() => {
    createAgent();
  }, []);

  async function createAgent() {
    try {
      await fetch(`${process.env.REACT_APP_BASE_URL}/agent`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          'Accept': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify(
          {
            "firstName": loggedUser.user.attributes.given_name,
            "lastName": loggedUser.user.attributes.family_name,
            "phone": loggedUser.user.attributes.phone_number,
            "email": loggedUser.user.attributes.email,
            "uuid": loggedUser.user.attributes.sub,
            "agencyUuid": AgencyAgentInfo.uuid,
            "accountType": "admin"
          }),
      })
        .then(res => {
          if (res.ok) return res.json()
          // If there is an error then make sure we catch that
          return res.json().then(e => Promise.reject(e))
        }).then(({ agent, message }) => {
          setLoading(true);
          addDefaultAgent();
          setTimeout(
            function () {
              if (loggedUser.licenseDetails.allotedSeats > 1) {
                history.push(Routes.CreateTeam.path)
              } else if (loggedUser.licenseDetails.allotedSeats == 1) {
                setShowDownloadLink(true);
                getSubscriptions(agent);
              }
            },
            1500
          );
        })
    } catch (error) {
      console.log(error)
    }
  }

  // create default agency agent

  async function addDefaultAgent() {
    let agencyUUID = AgencyAgentInfo.uuid;
    try {
      const defaultAgent = await fetch(`${process.env.REACT_APP_BASE_URL}/agency/${agencyUUID}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          'Accept': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify(
          {
            "agents": [
              loggedUser.user.attributes.sub
            ]
          }),
      })
      .then(res => {
        if (res.ok) return res.json()
        // If there is an error then make sure we catch that
        return res.json().then(e => Promise.reject(e))
      })
    } catch (error) {
      console.log(error)
    }
  }

  async function getSubscriptions(agent) {
    let phoneData = loggedUser.user.attributes.phone_number;
    phoneData = phoneData.substring(1);
    try {
      await fetch(`${process.env.REACT_APP_BASE_URL}/subscriptions?phone=%2B${phoneData}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          'Accept': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify(),
      })
      .then(res => {
        if (res.ok) return res.json()
        // If there is an error then make sure we catch that
        return res.json().then(e => Promise.reject(e))
      }).then(({subscription}) => {
         linkSubscriptions(subscription, agent);
      })
    } catch (error) {
       console.log(error)
    }
  }

  async function linkSubscriptions(data, agent) {
    try {
      await fetch(`${process.env.REACT_APP_BASE_URL}/subscriptions/${data.subscriptionId}`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          'Accept': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify({
          "agentUuid": agent.uuid
        }),
      })
      .then(res => {
        if (res.ok) return console.log("Success");
        // If there is an error then make sure we catch that
        return res.json().then(e => Promise.reject(e))
      })
    } catch (error) {
       console.log(error)
    }
  }

  return (
    <>
      <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
        <div className="d-block mb-2 mb-md-0">
          <Breadcrumb className="d-none d-md-inline-block" listProps={{ className: "breadcrumb-dark breadcrumb-transparent" }}>
            <Breadcrumb.Item><HomeIcon className="icon icon-xs" /></Breadcrumb.Item>
            <Breadcrumb.Item>Harvast</Breadcrumb.Item>
            <Breadcrumb.Item active>Create Agent</Breadcrumb.Item>
          </Breadcrumb>
        </div>
      </div>

      <Card border="0" className="shadow my-4 ">
        <Card.Body>
          <Row className="text-gray">

            {
              loading ?
                <React.Fragment>
                  <Col xs={12} lg={12} xl={12}>
                    <Alert variant="info" className="mb-3ot">
                      <Alert.Heading className="d-flex align-items-center mt-2">
                        <BellIcon className="icon icon-md me-2" />
                        Agent Created Successfully!
                      </Alert.Heading>
                      <hr />
                    </Alert>
                    {
                      showDownloadLink && <div className="apps-section text-center">
                              <h3 className="mb-2">Download  Harvast</h3>
                              <p className="mb-4">The Harvast app is available for download for both Android and iOS.</p>
                              <div className="d-flex align-items-center justify-content-center mb-3">
                                  <a className="d-block app-icon app-icon-left" href="https://play.google.com/store/apps/details?id=com.wocketship.harvast.android" target="_blank">
                                    <Image src={GooglePlayImage} className="img-fluid" />
                                  </a>
                                  <a className="d-block app-icon app-icon-right" href="https://apps.apple.com/ca/app/harvast/id1644409949" target="_blank">
                                    <Image src={AppleStoreImage} className="img-fluid" />
                                  </a>
                            </div>
                          </div>
                    }
                  </Col>
                </React.Fragment>
                :
                <React.Fragment>
                  <Col xs={12} lg={12} xl={12}>
                    <Alert variant="primary" className="mb-0">
                      <Alert.Heading className="d-flex align-items-center mt-2">
                        <BellIcon className="icon icon-md me-2" />
                        Please Be Patient!
                      </Alert.Heading>

                      <p>We are adding you as an agent</p>

                      <div>
                        <div className="spinner-border" role="status">
                          <span className="sr-only"></span>
                        </div>
                      </div>

                      <hr />
                      <p className="mb-0">Thanks.</p>
                    </Alert>
                  </Col>
                </React.Fragment>
            }

          </Row>
        </Card.Body>
      </Card>
    </>
  );
};