import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';

// const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/

// sign up form validation rules 
const validationSchema = Yup.object().shape({
    first_name: Yup.string()
        .required('First Name is required'),
    last_name: Yup.string()
        .required('Last name is required'),
    email: Yup.string()
        .required('Email is required')
        .email('Email is invalid'),
    phone: Yup.string()
        .required('Phone Number is required'),
    // .matches(phoneRegExp, 'Phone number is not valid'),
    password: Yup.string()
        .min(8, 'Password must be at least 8 characters')
        .required('Password is required')
        .test(
            'isValidPass',
            'Password must be 8 char (One UpperCase, One Lowercase & one Number)',
            (value, context) => {
                const hasUpperCase = /[A-Z]/.test(value);
                const hasNumber = /[0-9]/.test(value);
                const hasLowerCase = /[a-z]/.test(value);
                let validConditions = 0;
                const numberOfMustBeValidConditions = 3;
                const conditions = [hasUpperCase, hasLowerCase, hasNumber];
                conditions.forEach(condition => (condition ? validConditions++ : null));
                if (validConditions >= numberOfMustBeValidConditions) {
                    return true;
                }
                return false;
            },
        ),
    confirmPassword: Yup.string()
        .oneOf([Yup.ref('password')], 'Passwords must match')
        .required('Confirm Password is required'),
    acceptTerms: Yup.bool()
        .oneOf([true], 'Accept Ts & Cs is required')
});

export const formOptions = { resolver: yupResolver(validationSchema) };


// confirm code form validation rules 
const validationSchema1 = Yup.object().shape({
    code: Yup.string()
        .required('Confirmation Code is required'),
});

export const codeFormOptions = { resolver: yupResolver(validationSchema1) };


// Login form validation rules 
const validationSchema2 = Yup.object().shape({
    phone: Yup.string()
        .required('Phone Number is required'),
    password: Yup.string()
        .min(8, 'Password must be at least 8 characters')
        .required('Password is required')
        .test(
            'isValidPass',
            'Password must be 8 char (One UpperCase, One Lowercase & one Number)',
            (value, context) => {
                const hasUpperCase = /[A-Z]/.test(value);
                const hasNumber = /[0-9]/.test(value);
                const hasLowerCase = /[a-z]/.test(value);
                let validConditions = 0;
                const numberOfMustBeValidConditions = 3;
                const conditions = [hasUpperCase, hasLowerCase, hasNumber];
                conditions.forEach(condition => (condition ? validConditions++ : null));
                if (validConditions >= numberOfMustBeValidConditions) {
                    return true;
                }
                return false;
            },
        ),
});

export const loginFormOptions = { resolver: yupResolver(validationSchema2) };


// Forgot Password form validation rules 
const validationSchema3 = Yup.object().shape({
    phone: Yup.string()
        .required('Phone Number is required')
});

export const ForgotPasswordFormOptions = { resolver: yupResolver(validationSchema3) };

// Reset form validation rules 
const validationSchema4 = Yup.object().shape({
    code: Yup.string()
        .required('Confirmation Code is required'),
    password: Yup.string()
        .min(8, 'Password must be at least 8 characters')
        .required('Password is required')
        .test(
            'isValidPass',
            'Password must be 8 char (One UpperCase, One Lowercase & one Number)',
            (value, context) => {
                const hasUpperCase = /[A-Z]/.test(value);
                const hasNumber = /[0-9]/.test(value);
                const hasLowerCase = /[a-z]/.test(value);
                let validConditions = 0;
                const numberOfMustBeValidConditions = 3;
                const conditions = [hasUpperCase, hasLowerCase, hasNumber];
                conditions.forEach(condition => (condition ? validConditions++ : null));
                if (validConditions >= numberOfMustBeValidConditions) {
                    return true;
                }
                return false;
            },
        ),
    confirmPassword: Yup.string()
        .oneOf([Yup.ref('password'), null], 'Passwords must match')
        .required('Confirm Password is required'),
});

export const ResetPasswordformOptions = { resolver: yupResolver(validationSchema4) };

// Reset Agency creation validation rules 
const validationSchema5 = Yup.object().shape({
    agency_name: Yup.string()
        .required('Create Agency Name before proceeding'),
});

export const AgencyNameformOptions = { resolver: yupResolver(validationSchema5) };

// create team form validation rules 
const validationSchema6 = Yup.object().shape({
    team_name: Yup.string()
        .required('Name of Team is required'),
    email: Yup.string()
        .required('Email is required')
        .email('Email is invalid'),
    phone: Yup.string()
        .required('Phone Number is required'),
    // .matches(phoneRegExp, 'Phone number is not valid'),
    description: Yup.string()
        .required('Enter Short Description'),
    address1: Yup.string()
        .required('Enter your Address'),
    address2: Yup.string()
        .notRequired(),
    city: Yup.string()
        .required('Enter City'),
    select: Yup.string()
        .required('Select Province')
});

export const createTeamformOptions = { resolver: yupResolver(validationSchema6) };


// Add agent form validation rules 
const validationSchema7 = Yup.object().shape({
    firstName: Yup.string()
        .required('Enter Agent First Name'),
    lastName: Yup.string()
        .required('Enter Agent Last Name'),
    email: Yup.string()
        .required('Email is required')
        .email('Email is invalid'),
    phone: Yup.string()
        .required('Phone Number is required')
        // .matches(phoneRegExp, 'Phone number is not valid'),
});

export const addAgentformOptions = { resolver: yupResolver(validationSchema7) };


// confirmation code validation rules 
const validationSchema8 = Yup.object().shape({
    phone: Yup.string()
        .required('Phone Number is required')
});

export const confirmationcodeFormOptions = { resolver: yupResolver(validationSchema8) };

// preRegister form validation rules 
const validationSchema9 = Yup.object().shape({
    first_name: Yup.string()
        .required('First Name is required'),
    last_name: Yup.string()
        .required('Last name is required'),
    email: Yup.string()
        .required('Email is required')
        .email('Email is invalid'),
    phone: Yup.string()
        .required('Phone Number is required')
});

export const preOrderformOptions = { resolver: yupResolver(validationSchema9) };