import React, { useState, createContext } from 'react';

export const IndividualAgentContext = createContext();

export const IndividualAgentProvider = props => {
    const [AgentInfo, setAgentInfo] = useState({})

    return (
       <IndividualAgentContext.Provider value={[AgentInfo, setAgentInfo]}>
          {props.children}
       </IndividualAgentContext.Provider>
    );
}