import React, { useState, useEffect, useContext } from "react";
import moment from 'moment-timezone';
import ApexChart from "react-apexcharts";
import { LeadsDataContext } from "Context/LeadsDataContext";
import { AgencyLeadsDataContext } from "Context/AgencyLeadsContext"
import { AppointmentsDataContext } from "Context/AppointmentsDataContext";

import { dailySales, weeklySales, monthlySales, yearlySales, customers, revenue, users, weeklyReport } from "data/charts";

// leads chart
export const SalesValueChart = ({ period, chartType }) => {
    const [leadsData, setLeadsData] = useContext(LeadsDataContext);
    const [dailyLeads, setDailyLeads] = useState([]);
    const [weekLeads, setWeekLeads] = useState([]);
    const [monthlyLeads, setMonthlyLeads] = useState([]);
    const [yearLeads, setYearLeads] = useState([]);
    const [monthLabelsData, setMonthLabelsData] = useState([]);
    let periodData, periodLabels;

    useEffect(() => {

        var weeksInAMonth = calcWeeksInMonth();
        let monthLabels = []; //convert to state
        for (var i = 0; i < weeksInAMonth; i++) {
            let addOne = i + 1;
            monthLabels.push(`Week ${addOne}`);
        }
        setMonthLabelsData(monthLabels)

    }, []);


    useEffect(() => {
        if (period === "year") {
            yearlyLeads();
        } else if (period === "week") {
            weeklyLeads();
        } else if (period === "month") {
            weeks_in_month();
        } else if (period === "day") {
            todayLeads();
        }
    }, [period]);

    var leadsDataConst = JSON.parse(sessionStorage.getItem("leadsLocation"));

    // get weekly leads
    const weeklyLeads = () => {
        let filteredWeekLeads = leadsDataConst.filter(weekCal)
        setLeadsData(filteredWeekLeads);
        let weekLeadsInterested = filteredWeekLeads.filter(interestedLead)
        let weekData = [];
        let startdate = moment().clone().endOf('week').format("MMM Do YY");
        let date = startdate;
        for (var i = 1; i < 8; i++) {

            let count = 0;

            weekLeadsInterested.forEach(element => {
                // let test = moment(element.createdAt).format("MMM Do YY");
                // console.log(test);
                if (moment(element.createdAt).format("MMM Do YY") === date) {
                    count += 1;
                }
            });
            date = moment().endOf('week').subtract(6 - i, 'days').format("MMM Do YY");
            weekData.push(count);
        }
        setWeekLeads(weekData);
    }

    const today = moment();
    let weekStart = today.clone().startOf('week');
    let weekEnd = today.clone().endOf('week');
    const firstDayYear = moment().month(0);

    const startOfMonth = moment().startOf('month');
    const endOfMonth = moment().endOf('month');

    // get yearly leads

    const yearlyLeads = () => {
        let filteredYearLeads = leadsDataConst.filter(yearlyCal)
        setLeadsData(filteredYearLeads);
        let yearLeadsInterested = filteredYearLeads.filter(interestedLead)
        let yearData = [];
        let startMonth = moment().month(0)
        let startMonthFormated = startMonth.format('MMM');
        let month = startMonthFormated;
        for (var i = 1; i < 13; i++) {

            let count = 0;

            yearLeadsInterested.forEach(element => {
                if (moment(element.createdAt).format("MMM") === month) {
                    count += 1;
                }
                // console.log(element.createdAt);
            });
            let nextMonth = moment().month(i)
            let nextMonthFormated = nextMonth.format('MMM');
            month = nextMonthFormated;

            yearData.push(count);
        }
        setYearLeads(yearData);
    }


    // monthly calculation
    function calcWeeksInMonth() {
        var date = moment();
        const dateFirst = moment(date).date(1);
        const dateLast = moment(date).date(date.daysInMonth());
        const startWeek = dateFirst.isoWeek();
        const endWeek = dateLast.isoWeek();

        if (endWeek < startWeek) {
            // Yearly overlaps, month is either DEC or JAN
            if (dateFirst.month() === 0) {
                // January
                return endWeek + 1;
            } else {
                // December
                if (dateLast.isoWeekday() === 7) {
                    // Sunday is last day of year
                    return endWeek - startWeek + 1;
                } else {
                    // Sunday is NOT last day of year
                    return dateFirst.isoWeeksInYear() - startWeek + 1;
                }
            }
        } else {
            return endWeek - startWeek + 1;
        }
    }

    function weeks_in_month() {
        let year = moment().year(); // change year
        let month = moment().format("M");
        let startDate = moment([year, month - 1])
        let endDate = moment(startDate).endOf('month');
        let currentmonth = moment().format("M");
        let currentyear = moment().format("Y");

        var dates = [];
        var weeks = [];

        var per_week = [];
        var difference = endDate.diff(startDate, 'days');

        per_week.push(startDate.toDate())
        let index = 0;
        let last_week = false;
        while (startDate.add(1, 'days').diff(endDate) < 0) {
            if (startDate.day() != 0) {
                per_week.push(startDate.toDate())
            } else {
                if ((startDate.clone().add(7, 'days').month() == (month - 1))) {
                    weeks.push(per_week)
                    per_week = []
                    per_week.push(startDate.toDate())
                } else if (Math.abs(index - difference) > 0) {
                    if (!last_week) {
                        weeks.push(per_week);
                        per_week = [];
                    }
                    last_week = true;
                    per_week.push(startDate.toDate());
                }
            }
            index += 1;
            if ((last_week == true && Math.abs(index - difference) == 0) ||
                (Math.abs(index - difference) == 0 && per_week.length == 1)) {
                weeks.push(per_week)
            }
            dates.push(startDate.clone().toDate());
        }

        // gets and groups days in weeks

        let filteredMonthLeads = leadsDataConst.filter(monthCal);
        setLeadsData(filteredMonthLeads);
        let monthLeadsInterested = filteredMonthLeads.filter(interestedLead)
        let leadsArray = [];
        let date = "";
        let totalDays = 1
        for (var i = 0; i < weeks.length; i++) {
            // track leads here
            let leadsCount = 0;
            for (var j = 0; j < weeks[i].length; j++) {
                date = moment([currentyear, currentmonth - 1]).add(totalDays - 1, 'days').format("MMM Do YY");
                monthLeadsInterested.forEach(element => {
                    if (moment(element.createdAt).format("MMM Do YY") === date) {
                        leadsCount += 1;
                    }
                });
                totalDays += 1;
            }
            leadsArray.push(leadsCount);
        }
        setMonthlyLeads(leadsArray);
    }


    const todayLeads = () => {
        let filteredTodayLeads = leadsDataConst.filter(dailyCal)
        setLeadsData(filteredTodayLeads);
        let todayLeadsInterested = filteredTodayLeads.filter(interestedLead)
        let todayData = [];
        let todaysDate = moment().format("MMM Do YYYY");
        for (var i = 1; i < 2; i++) {

            let count = 0;

            todayLeadsInterested.forEach(element => {
                if (moment(element.createdAt).format("MMM Do YYYY") === todaysDate) {
                    count += 1;
                }
            });
            todayData.push(count, 0, 0, 0, 0);
        }
        setDailyLeads(todayData);
    }


    function interestedLead(lead) {
        return lead.interestLevel === 'Interested';
    }

    function dailyCal(lead) {
        return moment(lead.createdAt).isBetween(today, today, 'day', '[]');
    }

    function weekCal(lead) {
        return moment(lead.createdAt).isBetween(weekStart, weekEnd, 'day', '[]');
    }

    function monthCal(lead) {
        return moment(lead.createdAt).isBetween(startOfMonth, endOfMonth, 'day', '[]');
    }

    function yearlyCal(lead) {
        return moment(lead.createdAt).isBetween(firstDayYear, today, 'day', '[]');
    }

    switch (period) {
        case "day":
            periodData = dailyLeads;
            periodLabels = ['Today', '', ' ', '', ''];
            break;
        case "month":
            periodData = monthlyLeads;
            periodLabels = monthLabelsData;
            break;
        case "year":
            periodData = yearLeads;
            periodLabels = yearlySales.labels;
            break;
        default:
            periodData = weekLeads;
            periodLabels = weeklySales.labels;
            break;
    }

    const chartSeries = [{
        name: "Leads",
        data: periodData,
    }];

    const chartOptions = {
        chart: {
            fontFamily: 'Inter',
            foreColor: '#262B40',
            toolbar: {
                show: true,
                offsetX: 0,
                offsetY: 0,
                tools: {
                    download: false,
                    selection: false,
                    zoom: false,
                    zoomin: true,
                    zoomout: true,
                    pan: false,
                    reset: false | '<img src="/static/icons/reset.png" width="20">',
                    customIcons: []
                },
                export: {
                    csv: {
                        filename: undefined,
                        columnDelimiter: ',',
                        headerCategory: 'category',
                        headerValue: 'value',
                        dateFormatter(timestamp) {
                            return new Date(timestamp).toDateString()
                        }
                    }
                },
                autoSelected: 'zoom'
            },
        },
        dataLabels: {
            enabled: false
        },
        tooltip: {
            style: {
                fontSize: '14px',
                fontFamily: 'Inter',
            },
        },
        theme: {
            monochrome: {
                enabled: true,
                color: '#17a5ce',
            }
        },
        grid: {
            show: true,
            borderColor: '#61DAFB',
            strokeDashArray: 1,
        },
        markers: {
            size: 5,
            strokeColors: '#17a5ce',
            hover: {
                size: undefined,
                sizeOffset: 3
            }
        },
        xaxis: {
            categories: periodLabels,
            labels: {
                style: {
                    fontSize: '12px',
                    fontWeight: 500,
                },
            },
            axisBorder: {
                color: '#61DAFB',
            },
            axisTicks: {
                color: '#61DAFB',
            }
        },
        yaxis: {
            labels: {
                style: {
                    colors: ['#262B40'],
                    fontSize: '12px',
                    fontWeight: 500,
                },
            },
        },
        responsive: [{
            breakpoint: 768,
            options: {
                yaxis: {
                    show: false,
                }
            }
        }]
    };

    return ( <
        ApexChart type = { chartType }
        height = { 420 }
        series = { chartSeries }
        options = { chartOptions }
        />
    );
};

// leads  appointment generation
export const AppointmentsChart = ({ period, chartType }) => {
    const [leadsData, setLeadsData] = useContext(LeadsDataContext);
    const [appointmentsData, setAppointmentsData] = useContext(AppointmentsDataContext);
    const [dailyLeads, setDailyLeads] = useState([]);
    const [weekLeads, setWeekLeads] = useState([]);
    const [monthlyLeads, setMonthlyLeads] = useState([]);
    const [yearLeads, setYearLeads] = useState([]);
    const [monthLabelsData, setMonthLabelsData] = useState([]);
    let periodData, periodLabels;

    useEffect(() => {

        var weeksInAMonth = calcWeeksInMonth();
        let monthLabels = []; //convert to state
        for (var i = 0; i < weeksInAMonth; i++) {
            let addOne = i + 1;
            monthLabels.push(`Week ${addOne}`);
        }
        setMonthLabelsData(monthLabels)

    }, []);


    useEffect(() => {
        if (period === "year") {
            yearlyLeads();
        } else if (period === "week") {
            weeklyLeads();
        } else if (period === "month") {
            weeks_in_month();
        } else if (period === "day") {
            todayLeads();
        }
    }, [period]);

    var leadsDataConst = JSON.parse(sessionStorage.getItem("leadsLocation"));

    // get weekly leads
    const weeklyLeads = () => {
        let filteredWeekLeads = leadsDataConst.filter(weekCal)
        let weekLeadsInterested = filteredWeekLeads.filter(interestedLead)
        setAppointmentsData(weekLeadsInterested);
        let weekData = [];
        let startdate = moment().clone().endOf('week').format("MMM Do YY");
        let date = startdate;
        for (var i = 1; i < 8; i++) {

            let count = 0;

            weekLeadsInterested.forEach(element => {
                // let test = moment(element.createdAt).format("MMM Do YY");
                // console.log(test);
                if (moment(element.createdAt).format("MMM Do YY") === date) {
                    count += 1;
                }
            });
            date = moment().endOf('week').subtract(6 - i, 'days').format("MMM Do YY");
            weekData.push(count);
        }
        setWeekLeads(weekData);
    }

    const today = moment();
    let weekStart = today.clone().startOf('week');
    let weekEnd = today.clone().endOf('week');
    const firstDayYear = moment().month(0);

    const startOfMonth = moment().startOf('month');
    const endOfMonth = moment().endOf('month');

    // get yearly leads

    const yearlyLeads = () => {
        let filteredYearLeads = leadsDataConst.filter(yearlyCal)
        let yearLeadsInterested = filteredYearLeads.filter(interestedLead)
        setAppointmentsData(yearLeadsInterested);
        let yearData = [];
        let startMonth = moment().month(0)
        let startMonthFormated = startMonth.format('MMM');
        let month = startMonthFormated;
        for (var i = 1; i < 13; i++) {

            let count = 0;

            yearLeadsInterested.forEach(element => {
                if (moment(element.createdAt).format("MMM") === month) {
                    count += 1;
                }
                // console.log(element.createdAt);
            });
            let nextMonth = moment().month(i)
            let nextMonthFormated = nextMonth.format('MMM');
            month = nextMonthFormated;

            yearData.push(count);
        }
        setYearLeads(yearData);
    }


    // monthly calculation
    function calcWeeksInMonth() {
        var date = moment();
        const dateFirst = moment(date).date(1);
        const dateLast = moment(date).date(date.daysInMonth());
        const startWeek = dateFirst.isoWeek();
        const endWeek = dateLast.isoWeek();

        if (endWeek < startWeek) {
            // Yearly overlaps, month is either DEC or JAN
            if (dateFirst.month() === 0) {
                // January
                return endWeek + 1;
            } else {
                // December
                if (dateLast.isoWeekday() === 7) {
                    // Sunday is last day of year
                    return endWeek - startWeek + 1;
                } else {
                    // Sunday is NOT last day of year
                    return dateFirst.isoWeeksInYear() - startWeek + 1;
                }
            }
        } else {
            return endWeek - startWeek + 1;
        }
    }

    function weeks_in_month() {
        let year = moment().year(); // change year
        let month = moment().format("M");
        let startDate = moment([year, month - 1])
        let endDate = moment(startDate).endOf('month');
        let currentmonth = moment().format("M");
        let currentyear = moment().format("Y");

        var dates = [];
        var weeks = [];

        var per_week = [];
        var difference = endDate.diff(startDate, 'days');

        per_week.push(startDate.toDate())
        let index = 0;
        let last_week = false;
        while (startDate.add(1, 'days').diff(endDate) < 0) {
            if (startDate.day() != 0) {
                per_week.push(startDate.toDate())
            } else {
                if ((startDate.clone().add(7, 'days').month() == (month - 1))) {
                    weeks.push(per_week)
                    per_week = []
                    per_week.push(startDate.toDate())
                } else if (Math.abs(index - difference) > 0) {
                    if (!last_week) {
                        weeks.push(per_week);
                        per_week = [];
                    }
                    last_week = true;
                    per_week.push(startDate.toDate());
                }
            }
            index += 1;
            if ((last_week == true && Math.abs(index - difference) == 0) ||
                (Math.abs(index - difference) == 0 && per_week.length == 1)) {
                weeks.push(per_week)
            }
            dates.push(startDate.clone().toDate());
        }

        // gets and groups days in weeks

        let filteredMonthLeads = leadsDataConst.filter(monthCal);
        let monthLeadsInterested = filteredMonthLeads.filter(interestedLead)
        setAppointmentsData(monthLeadsInterested);
        let leadsArray = [];
        let date = "";
        let totalDays = 1
        for (var i = 0; i < weeks.length; i++) {
            // track leads here
            let leadsCount = 0;
            for (var j = 0; j < weeks[i].length; j++) {
                date = moment([currentyear, currentmonth - 1]).add(totalDays - 1, 'days').format("MMM Do YY");
                monthLeadsInterested.forEach(element => {
                    if (moment(element.createdAt).format("MMM Do YY") === date) {
                        leadsCount += 1;
                    }
                });
                totalDays += 1;
            }
            leadsArray.push(leadsCount);
        }
        setMonthlyLeads(leadsArray);
    }


    const todayLeads = () => {
        let filteredTodayLeads = leadsDataConst.filter(dailyCal)
        let todayLeadsInterested = filteredTodayLeads.filter(interestedLead)
        setAppointmentsData(todayLeadsInterested);
        let todayData = [];
        let todaysDate = moment().format("MMM Do YYYY");
        for (var i = 1; i < 2; i++) {

            let count = 0;

            todayLeadsInterested.forEach(element => {
                if (moment(element.createdAt).format("MMM Do YYYY") === todaysDate) {
                    count += 1;
                }
            });
            todayData.push(count, 0, 0, 0, 0);
        }
        setDailyLeads(todayData);
    }


    function interestedLead(lead) {
        return lead.followupSettingsAsJson.appointment === true;
    }

    function dailyCal(lead) {
        return moment(lead.createdAt).isBetween(today, today, 'day', '[]');
    }

    function weekCal(lead) {
        return moment(lead.createdAt).isBetween(weekStart, weekEnd, 'day', '[]');
    }

    function monthCal(lead) {
        return moment(lead.createdAt).isBetween(startOfMonth, endOfMonth, 'day', '[]');
    }

    function yearlyCal(lead) {
        return moment(lead.createdAt).isBetween(firstDayYear, today, 'day', '[]');
    }

    switch (period) {
        case "day":
            periodData = dailyLeads;
            periodLabels = ['Today', '', ' ', '', ''];
            break;
        case "month":
            periodData = monthlyLeads;
            periodLabels = monthLabelsData;
            break;
        case "year":
            periodData = yearLeads;
            periodLabels = yearlySales.labels;
            break;
        default:
            periodData = weekLeads;
            periodLabels = weeklySales.labels;
            break;
    }

    const chartSeries = [{
        name: "Leads",
        data: periodData,
    }];

    const chartOptions = {
        chart: {
            fontFamily: 'Inter',
            foreColor: '#31316A',
            toolbar: {
                show: true,
                offsetX: 0,
                offsetY: 0,
                tools: {
                    download: false,
                    selection: false,
                    zoom: false,
                    zoomin: true,
                    zoomout: true,
                    pan: false,
                    reset: false | '<img src="/static/icons/reset.png" width="20">',
                    customIcons: []
                },
                export: {
                    csv: {
                        filename: undefined,
                        columnDelimiter: ',',
                        headerCategory: 'category',
                        headerValue: 'value',
                        dateFormatter(timestamp) {
                            return new Date(timestamp).toDateString()
                        }
                    }
                },
                autoSelected: 'zoom'
            },
        },
        dataLabels: {
            enabled: false
        },
        tooltip: {
            style: {
                fontSize: '14px',
                fontFamily: 'Inter',
            },
        },
        theme: {
            monochrome: {
                enabled: true,
                color: '#10B981',
            }
        },
        grid: {
            show: true,
            borderColor: '#10B981',
            strokeDashArray: 1,
        },
        markers: {
            size: 5,
            strokeColors: '#10B981',
            hover: {
                size: undefined,
                sizeOffset: 3
            }
        },
        xaxis: {
            categories: periodLabels,
            labels: {
                style: {
                    fontSize: '12px',
                    fontWeight: 500,
                },
            },
            axisBorder: {
                color: '#61DAFB',
            },
            axisTicks: {
                color: '#61DAFB',
            }
        },
        yaxis: {
            labels: {
                style: {
                    colors: ['#262B40'],
                    fontSize: '12px',
                    fontWeight: 500,
                },
            },
        },
        responsive: [{
            breakpoint: 768,
            options: {
                yaxis: {
                    show: false,
                }
            }
        }]
    };

    return ( <
        ApexChart type = { chartType }
        height = { 420 }
        series = { chartSeries }
        options = { chartOptions }
        />
    );
};

// leads  agency appointment generation
export const AgencyAppointmentsChart = ({ period, chartType }) => {
    const [leadsData, setLeadsData] = useContext(LeadsDataContext);
    const [dailyLeads, setDailyLeads] = useState([]);
    const [weekLeads, setWeekLeads] = useState([]);
    const [monthlyLeads, setMonthlyLeads] = useState([]);
    const [yearLeads, setYearLeads] = useState([]);
    const [monthLabelsData, setMonthLabelsData] = useState([]);
    let periodData, periodLabels;


    useEffect(() => {
        if (period === "year") {
            yearlyAgencyAppointments();
        } else if (period === "week") {
            weeklyAgencyAppointments();
        } else if (period === "month") {
            monthlyAgencyAppointments();
        } else if (period === "day") {
            dayAgencyAppointments();
        }
    }, [period]);

    var AgencyAppointmentsChartData = JSON.parse(sessionStorage.getItem("leadsStats"));

    var AgencyAppointmentsDataConst2 = AgencyAppointmentsChartData.chartData.appointments.year || [];
    var AgencyAppointmentsDataConst3 = AgencyAppointmentsChartData.chartData.appointments.month || [];
    var AgencyAppointmentsDataConst4 = AgencyAppointmentsChartData.chartData.appointments.week || [];
    var AgencyAppointmentsDataConst5 = AgencyAppointmentsChartData.chartData.appointments.day || [];


    const yearlyAgencyAppointments = () => {
        // get the Agencies years data
        let yearData = [];
        AgencyAppointmentsDataConst2.map(
            (month) => {
                const firstValue = Object.values(month)[0];
                yearData.push(firstValue);
            }
        )
        setYearLeads(yearData);
        // get the Agencies years data
    }

    const monthlyAgencyAppointments = () => {
        // get Agencies monthly data
        let monthData = [];
        AgencyAppointmentsDataConst3.map(
            (week) => {
                const firstValue = Object.values(week)[0];
                monthData.push(firstValue);
            }
        )
        setMonthlyLeads(monthData)
        //  get Agencies monthly data
    }

    const weeklyAgencyAppointments = () => {
        // get Agencies weekly data
        let weekData = [];
        AgencyAppointmentsDataConst4.map(
            (day) => {
                const firstValue = Object.values(day)[0];
                weekData.push(firstValue);
            }
        )
        setWeekLeads(weekData)
        //  get Agencies weekly data
    }

    const dayAgencyAppointments = () => {
        // get Agencies daily data
        let dayDataRaw = [];
        let dayData = [];
        AgencyAppointmentsDataConst5.map(
            (day) => {
                const firstValue = Object.values(day)[0];
                dayDataRaw.push(firstValue);
            }
        )
        if (dayDataRaw[0] == undefined || dayDataRaw[0] == 0){
            dayData = [];
        }else {
            dayData.push(dayDataRaw[0], 0, 0, 0, 0);
        }
        setDailyLeads(dayData)
        //  get Agencies daily data
    }

    switch (period) {
        case "day":
            periodData = dailyLeads;
            periodLabels = ['Today', '', ' ', '', ''];
            break;
        case "month":
            periodData = monthlyLeads;
            periodLabels = monthLabelsData;
            break;
        case "year":
            periodData = yearLeads;
            periodLabels = yearlySales.labels;
            break;
        default:
            periodData = weekLeads;
            periodLabels = weeklySales.labels;
            break;
    }

    const chartSeries = [{
        name: "Leads",
        data: periodData,
    }];

    const chartOptions = {
        chart: {
            fontFamily: 'Inter',
            foreColor: '#31316A',
            toolbar: {
                show: true,
                offsetX: 0,
                offsetY: 0,
                tools: {
                    download: false,
                    selection: false,
                    zoom: false,
                    zoomin: true,
                    zoomout: true,
                    pan: false,
                    reset: false | '<img src="/static/icons/reset.png" width="20">',
                    customIcons: []
                },
                export: {
                    csv: {
                        filename: undefined,
                        columnDelimiter: ',',
                        headerCategory: 'category',
                        headerValue: 'value',
                        dateFormatter(timestamp) {
                            return new Date(timestamp).toDateString()
                        }
                    }
                },
                autoSelected: 'zoom'
            },
        },
        dataLabels: {
            enabled: false
        },
        noData: {
            text: "No data available",
            align: "center",
            verticalAlign: "top",
            style: {
                color: 'red',
                fontSize: '20px',
            }
        },
        tooltip: {
            style: {
                fontSize: '14px',
                fontFamily: 'Inter',
            },
        },
        theme: {
            monochrome: {
                enabled: true,
                color: '#10B981',
            }
        },
        grid: {
            show: true,
            borderColor: '#10B981',
            strokeDashArray: 1,
        },
        markers: {
            size: 5,
            strokeColors: '#10B981',
            hover: {
                size: undefined,
                sizeOffset: 3
            }
        },
        xaxis: {
            categories: periodLabels,
            labels: {
                style: {
                    fontSize: '12px',
                    fontWeight: 500,
                },
            },
            axisBorder: {
                color: '#61DAFB',
            },
            axisTicks: {
                color: '#61DAFB',
            }
        },
        yaxis: {
            labels: {
                style: {
                    colors: ['#262B40'],
                    fontSize: '12px',
                    fontWeight: 500,
                },
            },
        },
        responsive: [{
            breakpoint: 768,
            options: {
                yaxis: {
                    show: false,
                }
            }
        }]
    };

    return ( <
        ApexChart type = { chartType }
        height = { 420 }
        series = { chartSeries }
        options = { chartOptions }
        />
    );
};

// agency leads appointment generation 
// export const AppointmentsChart = ({ period, chartType }) => {
//     const [leadsData, setLeadsData] = useContext(LeadsDataContext);
//     const [appointmentsData, setAppointmentsData] = useContext(AppointmentsDataContext);
//     const [dailyLeads, setDailyLeads] = useState([]);
//     const [weekLeads, setWeekLeads] = useState([]);
//     const [monthlyLeads, setMonthlyLeads] = useState([]);
//     const [yearLeads, setYearLeads] = useState([]);
//     const [monthLabelsData, setMonthLabelsData] = useState([]);
//     let periodData, periodLabels;

//     useEffect(() => {

//         var weeksInAMonth = calcWeeksInMonth();
//         let monthLabels = []; //convert to state
//         for (var i = 0; i < weeksInAMonth; i++) {
//             let addOne = i + 1;
//             monthLabels.push(`Week ${addOne}`);
//         }
//         setMonthLabelsData(monthLabels)

//     }, []);


//     useEffect(() => {
//         if (period === "year") {
//             yearlyLeads();
//         } else if (period === "week") {
//             weeklyLeads();
//         } else if (period === "month") {
//             weeks_in_month();
//         } else if (period === "day") {
//             todayLeads();
//         }
//     }, [period]);

//     var leadsDataConst = JSON.parse(sessionStorage.getItem("leadsLocation"));

//     // get weekly leads
//     const weeklyLeads = () => {
//         let filteredWeekLeads = leadsDataConst.filter(weekCal)
//         setLeadsData(filteredWeekLeads);
//         let weekLeadsInterested = filteredWeekLeads.filter(interestedLead)
//         let weekData = [];
//         let startdate = moment().clone().endOf('week').format("MMM Do YY");
//         let date = startdate;
//         for (var i = 1; i < 8; i++) {

//             let count = 0;

//             weekLeadsInterested.forEach(element => {
//                 // let test = moment(element.createdAt).format("MMM Do YY");
//                 // console.log(test);
//                 if (moment(element.createdAt).format("MMM Do YY") === date) {
//                     count += 1;
//                 }
//             });
//             date = moment().endOf('week').subtract(6 - i, 'days').format("MMM Do YY");
//             weekData.push(count);
//         }
//         setWeekLeads(weekData);
//     }

//     const today = moment();
//     let weekStart = today.clone().startOf('week');
//     let weekEnd = today.clone().endOf('week');
//     const firstDayYear = moment().month(0);

//     const startOfMonth = moment().startOf('month');
//     const endOfMonth = moment().endOf('month');

//     // get yearly leads

//     const yearlyLeads = () => {
//         let filteredYearLeads = leadsDataConst.filter(yearlyCal)
//         setAppointmentsData(filteredYearLeads);
//         let yearLeadsInterested = filteredYearLeads.filter(interestedLead)
//         console.log(yearLeadsInterested);
//         let yearData = [];
//         let startMonth = moment().month(0)
//         let startMonthFormated = startMonth.format('MMM');
//         let month = startMonthFormated;
//         for (var i = 1; i < 13; i++) {

//             let count = 0;

//             yearLeadsInterested.forEach(element => {
//                 if (moment(element.createdAt).format("MMM") === month) {
//                     count += 1;
//                 }
//                 // console.log(element.createdAt);
//             });
//             let nextMonth = moment().month(i)
//             let nextMonthFormated = nextMonth.format('MMM');
//             month = nextMonthFormated;

//             yearData.push(count);
//         }
//         setYearLeads(yearData);
//     }


//     // monthly calculation
//     function calcWeeksInMonth() {
//         var date = moment();
//         const dateFirst = moment(date).date(1);
//         const dateLast = moment(date).date(date.daysInMonth());
//         const startWeek = dateFirst.isoWeek();
//         const endWeek = dateLast.isoWeek();

//         if (endWeek < startWeek) {
//             // Yearly overlaps, month is either DEC or JAN
//             if (dateFirst.month() === 0) {
//                 // January
//                 return endWeek + 1;
//             } else {
//                 // December
//                 if (dateLast.isoWeekday() === 7) {
//                     // Sunday is last day of year
//                     return endWeek - startWeek + 1;
//                 } else {
//                     // Sunday is NOT last day of year
//                     return dateFirst.isoWeeksInYear() - startWeek + 1;
//                 }
//             }
//         } else {
//             return endWeek - startWeek + 1;
//         }
//     }

//     function weeks_in_month() {
//         let year = moment().year(); // change year
//         let month = moment().format("M");
//         let startDate = moment([year, month - 1])
//         let endDate = moment(startDate).endOf('month');
//         let currentmonth = moment().format("M");
//         let currentyear = moment().format("Y");

//         var dates = [];
//         var weeks = [];

//         var per_week = [];
//         var difference = endDate.diff(startDate, 'days');

//         per_week.push(startDate.toDate())
//         let index = 0;
//         let last_week = false;
//         while (startDate.add(1, 'days').diff(endDate) < 0) {
//             if (startDate.day() != 0) {
//                 per_week.push(startDate.toDate())
//             } else {
//                 if ((startDate.clone().add(7, 'days').month() == (month - 1))) {
//                     weeks.push(per_week)
//                     per_week = []
//                     per_week.push(startDate.toDate())
//                 } else if (Math.abs(index - difference) > 0) {
//                     if (!last_week) {
//                         weeks.push(per_week);
//                         per_week = [];
//                     }
//                     last_week = true;
//                     per_week.push(startDate.toDate());
//                 }
//             }
//             index += 1;
//             if ((last_week == true && Math.abs(index - difference) == 0) ||
//                 (Math.abs(index - difference) == 0 && per_week.length == 1)) {
//                 weeks.push(per_week)
//             }
//             dates.push(startDate.clone().toDate());
//         }

//         // gets and groups days in weeks

//         let filteredMonthLeads = leadsDataConst.filter(monthCal);
//         setLeadsData(filteredMonthLeads);
//         let monthLeadsInterested = filteredMonthLeads.filter(interestedLead)
//         let leadsArray = [];
//         let date = "";
//         let totalDays = 1
//         for (var i = 0; i < weeks.length; i++) {
//             // track leads here
//             let leadsCount = 0;
//             for (var j = 0; j < weeks[i].length; j++) {
//                 date = moment([currentyear, currentmonth - 1]).add(totalDays - 1, 'days').format("MMM Do YY");
//                 monthLeadsInterested.forEach(element => {
//                     if (moment(element.createdAt).format("MMM Do YY") === date) {
//                         leadsCount += 1;
//                     }
//                 });
//                 totalDays += 1;
//             }
//             leadsArray.push(leadsCount);
//         }
//         setMonthlyLeads(leadsArray);
//     }


//     const todayLeads = () => {
//         let filteredTodayLeads = leadsDataConst.filter(dailyCal)
//         setLeadsData(filteredTodayLeads);
//         let todayLeadsInterested = filteredTodayLeads.filter(interestedLead)
//         let todayData = [];
//         let todaysDate = moment().format("MMM Do YYYY");
//         for (var i = 1; i < 2; i++) {

//             let count = 0;

//             todayLeadsInterested.forEach(element => {
//                 if (moment(element.createdAt).format("MMM Do YYYY") === todaysDate) {
//                     count += 1;
//                 }
//             });
//             todayData.push(count, 0, 0, 0, 0);
//         }
//         setDailyLeads(todayData);
//     }


//     function interestedLead(lead) {
//         return lead.followupSettingsAsJson.appointment === true;
//     }

//     function dailyCal(lead) {
//         return moment(lead.createdAt).isBetween(today, today, 'day', '[]');
//     }

//     function weekCal(lead) {
//         return moment(lead.createdAt).isBetween(weekStart, weekEnd, 'day', '[]');
//     }

//     function monthCal(lead) {
//         return moment(lead.createdAt).isBetween(startOfMonth, endOfMonth, 'day', '[]');
//     }

//     function yearlyCal(lead) {
//         return moment(lead.createdAt).isBetween(firstDayYear, today, 'day', '[]');
//     }

//     switch (period) {
//         case "day":
//             periodData = dailyLeads;
//             periodLabels = ['Today', '', ' ', '', ''];
//             break;
//         case "month":
//             periodData = monthlyLeads;
//             periodLabels = monthLabelsData;
//             break;
//         case "year":
//             periodData = yearLeads;
//             periodLabels = yearlySales.labels;
//             break;
//         default:
//             periodData = weekLeads;
//             periodLabels = weeklySales.labels;
//             break;
//     }

//     const chartSeries = [{
//         name: "Leads",
//         data: periodData,
//     }];

//     const chartOptions = {
//         chart: {
//             fontFamily: 'Inter',
//             foreColor: '#31316A',
//             toolbar: {
//                 show: true,
//                 offsetX: 0,
//                 offsetY: 0,
//                 tools: {
//                     download: false,
//                     selection: false,
//                     zoom: false,
//                     zoomin: true,
//                     zoomout: true,
//                     pan: false,
//                     reset: false | '<img src="/static/icons/reset.png" width="20">',
//                     customIcons: []
//                 },
//                 export: {
//                     csv: {
//                         filename: undefined,
//                         columnDelimiter: ',',
//                         headerCategory: 'category',
//                         headerValue: 'value',
//                         dateFormatter(timestamp) {
//                             return new Date(timestamp).toDateString()
//                         }
//                     }
//                 },
//                 autoSelected: 'zoom'
//             },
//         },
//         dataLabels: {
//             enabled: false
//         },
//         tooltip: {
//             style: {
//                 fontSize: '14px',
//                 fontFamily: 'Inter',
//             },
//         },
//         theme: {
//             monochrome: {
//                 enabled: true,
//                 color: '#10B981',
//             }
//         },
//         grid: {
//             show: true,
//             borderColor: '#10B981',
//             strokeDashArray: 1,
//         },
//         markers: {
//             size: 5,
//             strokeColors: '#10B981',
//             hover: {
//                 size: undefined,
//                 sizeOffset: 3
//             }
//         },
//         xaxis: {
//             categories: periodLabels,
//             labels: {
//                 style: {
//                     fontSize: '12px',
//                     fontWeight: 500,
//                 },
//             },
//             axisBorder: {
//                 color: '#61DAFB',
//             },
//             axisTicks: {
//                 color: '#61DAFB',
//             }
//         },
//         yaxis: {
//             labels: {
//                 style: {
//                     colors: ['#262B40'],
//                     fontSize: '12px',
//                     fontWeight: 500,
//                 },
//             },
//         },
//         responsive: [{
//             breakpoint: 768,
//             options: {
//                 yaxis: {
//                     show: false,
//                 }
//             }
//         }]
//     };

//     return ( <
//         ApexChart type = { chartType }
//         height = { 420 }
//         series = { chartSeries }
//         options = { chartOptions }
//         />
//     );
// };

// leads chart
export const AgencyLeadChart = ({ period, chartType }) => {
    const [leadsData, setLeadsData] = useContext(LeadsDataContext);
    const [agencyleadsData, setAgencyLeadsData] = useContext(AgencyLeadsDataContext);
    const [dailyLeads, setDailyLeads] = useState([]);
    const [weekLeads, setWeekLeads] = useState([]);
    const [monthlyLeads, setMonthlyLeads] = useState([]);
    const [yearLeads, setYearLeads] = useState([]);
    const [monthLabelsData, setMonthLabelsData] = useState([]);
    let periodData, periodLabels;

    useEffect(() => {
        if (period === "year") {
            yearlyAgencyLeads();
        } else if (period === "week") {
            weeklyAgencyLeads();
        } else if (period === "month") {
            monthlyAgencyLeads();
        } else if (period === "day") {
            dayAgencyLeads();
        }
    }, [period]);

    var AgencyChartData = JSON.parse(sessionStorage.getItem("leadsStats"));
    var AgencyLeadsDataConst2 = AgencyChartData.chartData.leads.year || [];
    // var AgencyLeadsDataConst3 = AgencyChartData.chartData.leads.month || [];
    // var AgencyLeadsDataConst4 = AgencyChartData.chartData.leads.week || [];
    // var AgencyLeadsDataConst5 = AgencyChartData.chartData.leads.day || [];
   

    const yearlyAgencyLeads = () => {
        // get the Agencies years data
        setAgencyLeadsData({
            doorsKnocked: AgencyChartData.chartData.knocks.totals.year,
            totalAppointments: AgencyChartData.chartData.appointments.totals.year,
            totalContacts: AgencyChartData.chartData.contacts.totals.year,
            totalLeads: AgencyChartData.chartData.leads.totals.year
        });
        let yearData = [];
        AgencyLeadsDataConst2.map(
            (month) => {
                const firstValue = Object.values(month)[0];
                yearData.push(firstValue);
            }
        )
        setYearLeads(yearData);
        // get the Agencies years data
    }

    const monthlyAgencyLeads = () => {
        var AgencyleadsDataMonth = JSON.parse(sessionStorage.getItem("leadsStatsMonth"));
        var AgencyLeadsDataConst3 = AgencyleadsDataMonth.chartData.leads.month || [];
        // get Agencies monthly data
        setAgencyLeadsData({
            doorsKnocked: AgencyleadsDataMonth.chartData.knocks.totals.month,
            totalAppointments: AgencyleadsDataMonth.chartData.appointments.totals.month,
            totalContacts: AgencyleadsDataMonth.chartData.contacts.totals.month,
            totalLeads: AgencyleadsDataMonth.chartData.leads.totals.month
        });
        let monthData = [];
        AgencyLeadsDataConst3.map(
            (week) => {
                const firstValue = Object.values(week)[0];
                monthData.push(firstValue);
            }
        )
        setMonthlyLeads(monthData)
        //  get Agencies monthly data
    }

    const weeklyAgencyLeads = () => {
        var AgencyleadsDataWeek = JSON.parse(sessionStorage.getItem("leadsStatsWeek"));
        var AgencyLeadsDataConst4 = AgencyleadsDataWeek.chartData.leads.week || [];

        // get Agencies weekly data
        setAgencyLeadsData({
            doorsKnocked: AgencyleadsDataWeek.chartData.knocks.totals.week,
            totalAppointments: AgencyleadsDataWeek.chartData.appointments.totals.week,
            totalContacts: AgencyleadsDataWeek.chartData.contacts.totals.week,
            totalLeads: AgencyleadsDataWeek.chartData.leads.totals.week
        });
        let weekData = [];
        AgencyLeadsDataConst4.map(
            (day) => {
                const firstValue = Object.values(day)[0];
                weekData.push(firstValue);
            }
        )
        setWeekLeads(weekData)
        //  get Agencies weekly data
    }

    const dayAgencyLeads = () => {          
        var AgencyleadsDataDay = JSON.parse(sessionStorage.getItem("leadsStatsDay"));
        var AgencyLeadsDataConst5 = AgencyleadsDataDay.chartData.leads.day || [];

        // get Agencies daily data
        let dayDataRaw = [];
        let dayData = [];
        AgencyLeadsDataConst5.map(
            (day) => {
                const firstValue = Object.values(day)[0];
                dayDataRaw.push(firstValue);
            }
        )
        if (dayDataRaw[0] == undefined || dayDataRaw[0] == 0){
            dayData = [];
        }else {
            dayData.push(dayDataRaw[0], 0, 0, 0, 0);
        }
        setDailyLeads(dayData)
        //  get Agencies daily data
    }

     //start of remove

    // get yearly leads

    // const yearlyLeads = () => {
    //     let filteredYearLeads = AgencyLeadsDataConst2.filter(yearlyCal)
    //     console.log(filteredYearLeads);
    //     // setAgencyLeadsData(filteredYearLeads); //change to show doors knocked and appointments
    //     let yearLeadsInterested = filteredYearLeads.filter(interestedLead)
    //     let yearData = [];
    //     let startMonth = moment().month(0)
    //     let startMonthFormated = startMonth.format('MMM');
    //     let month = startMonthFormated;
    //     for (var i = 1; i < 13; i++) {

    //         let count = 0;

    //         yearLeadsInterested.forEach(element => {
    //             if (moment(element.location.createdAt).format("MMM") === month) {
    //                 count += 1;
    //             }
    //             // console.log(element.createdAt);
    //         });
    //         let nextMonth = moment().month(i)
    //         let nextMonthFormated = nextMonth.format('MMM');
    //         month = nextMonthFormated;

    //         yearData.push(count);
    //     }
    //     setYearLeads(yearData);
    // }
    // const todayLeads = () => {
    //     let filteredTodayLeads = leadsDataConst.filter(dailyCal)
    //     setLeadsData(filteredTodayLeads);
    //     let todayLeadsInterested = filteredTodayLeads.filter(interestedLead)
    //     let todayData = [];
    //     let todaysDate = moment().format("MMM Do YYYY");
    //     for (var i = 1; i < 2; i++) {

    //         let count = 0;

    //         todayLeadsInterested.forEach(element => {
    //             if (moment(element.createdAt).format("MMM Do YYYY") === todaysDate) {
    //                 count += 1;
    //             }
    //         });
    //         todayData.push(count, 0, 0, 0, 0);
    //     }
    //     setDailyLeads(todayData);
    // }


    // function interestedLead(lead) {
    //     return lead.location.interestLevel === 'Interested';
    // }

    // function dailyCal(lead) {
    //     return moment(lead.createdAt).isBetween(today, today, 'day', '[]');
    // }

    // function weekCal(lead) {
    //     return moment(lead.location.createdAt).isBetween(weekStart, weekEnd, 'day', '[]');
    // }

    // function monthCal(lead) {
    //     return moment(lead.createdAt).isBetween(startOfMonth, endOfMonth, 'day', '[]');
    // }

    // function yearlyCal(lead) {
    //     return moment(lead.createdAt).isBetween(firstDayYear, today, 'day', '[]');
    // }

    // end of remove

    switch (period) {
        case "day":
            periodData = dailyLeads;
            periodLabels = ['Today', '', ' ', '', ''];
            break;
        case "month":
            periodData = monthlyLeads;
            periodLabels = monthLabelsData;
            break;
        case "year":
            periodData = yearLeads;
            periodLabels = yearlySales.labels;
            break;
        default:
            periodData = weekLeads;
            periodLabels = weeklySales.labels;
            break;
    }

    const chartSeries = [{
        name: "Leads",
        data: periodData,
    }];

    const chartOptions = {
        chart: {
            fontFamily: 'Inter',
            foreColor: '#262B40',
            toolbar: {
                show: true,
                offsetX: 0,
                offsetY: 0,
                tools: {
                    download: false,
                    selection: false,
                    zoom: false,
                    zoomin: true,
                    zoomout: true,
                    pan: false,
                    reset: false | '<img src="/static/icons/reset.png" width="20">',
                    customIcons: []
                },
                export: {
                    csv: {
                        filename: undefined,
                        columnDelimiter: ',',
                        headerCategory: 'category',
                        headerValue: 'value',
                        dateFormatter(timestamp) {
                            return new Date(timestamp).toDateString()
                        }
                    }
                },
                autoSelected: 'zoom'
            },
        },
        dataLabels: {
            enabled: false
        },
        noData: {
            text: "No data available",
            align: "center",
            verticalAlign: "top",
            style: {
                color: 'red',
                fontSize: '20px',
            }
        },
        tooltip: {
            style: {
                fontSize: '14px',
                fontFamily: 'Inter',
            },
        },
        theme: {
            monochrome: {
                enabled: true,
                color: '#17a5ce',
            }
        },
        grid: {
            show: true,
            borderColor: '#61DAFB',
            strokeDashArray: 1,
        },
        markers: {
            size: 5,
            strokeColors: '#17a5ce',
            hover: {
                size: undefined,
                sizeOffset: 3
            }
        },
        xaxis: {
            categories: periodLabels,
            labels: {
                style: {
                    fontSize: '12px',
                    fontWeight: 500,
                },
            },
            axisBorder: {
                color: '#61DAFB',
            },
            axisTicks: {
                color: '#61DAFB',
            }
        },
        yaxis: {
            labels: {
                style: {
                    colors: ['#262B40'],
                    fontSize: '12px',
                    fontWeight: 500,
                },
            },
        },
        responsive: [{
            breakpoint: 768,
            options: {
                yaxis: {
                    show: false,
                }
            }
        }]
    };

    return ( <
        ApexChart type = { chartType }
        height = { 420 }
        series = { chartSeries }
        options = { chartOptions }
        />
    );
};

export const CustomersChart = () => {
    const chartSeries = [{
        name: 'Customers',
        data: customers.data,
    }];

    const chartOptions = {
        labels: customers.labels,
        chart: {
            sparkline: {
                enabled: true
            }
        },
        theme: {
            monochrome: {
                enabled: true,
                color: '#31316A',
            }
        },
        tooltip: {
            fillSeriesColor: false,
            onDatasetHover: {
                highlightDataSeries: false,
            },
            theme: 'light',
            style: {
                fontSize: '12px',
                fontFamily: 'Inter',
            },
        },
    };

    return ( <
        ApexChart type = "area"
        height = { 140 }
        width = "100%"
        series = { chartSeries }
        options = { chartOptions }
        />
    );
};

export const RevenueChart = () => {
    const chartSeries = [{
        name: 'Revenue',
        data: revenue.data,
    }];

    const chartOptions = {
        chart: {
            sparkline: {
                enabled: true
            }
        },
        theme: {
            monochrome: {
                enabled: true,
                color: '#31316A',
            }
        },
        plotOptions: {
            bar: {
                columnWidth: '25%',
                borderRadius: 5,
                radiusOnLastStackedBar: true,
                colors: {
                    backgroundBarColors: ['#F2F4F6', '#F2F4F6', '#F2F4F6', '#F2F4F6'],
                    backgroundBarRadius: 5,
                },
            }
        },
        xaxis: {
            categories: revenue.labels,
            crosshairs: {
                width: 1
            },
        },
        tooltip: {
            fillSeriesColor: false,
            onDatasetHover: {
                highlightDataSeries: false,
            },
            theme: 'light',
            style: {
                fontSize: '12px',
                fontFamily: 'Inter',
            },
            y: {
                formatter: function(val) {
                    return "$ " + val + "k"
                }
            }
        },
    };

    return ( <
        ApexChart type = "bar"
        height = { 140 }
        width = "100%"
        series = { chartSeries }
        options = { chartOptions }
        />
    );
};

export const UsersChart = () => {
    const chartSeries = [{
        name: 'Users',
        data: users.data,
    }];

    const chartOptions = {
        labels: users.labels,
        chart: {
            sparkline: {
                enabled: true
            }
        },
        theme: {
            monochrome: {
                enabled: true,
                color: '#31316A',
            }
        },
        tooltip: {
            fillSeriesColor: false,
            onDatasetHover: {
                highlightDataSeries: false,
            },
            theme: 'light',
            style: {
                fontSize: '12px',
                fontFamily: 'Inter',
            },
        },
    };

    return ( <
        ApexChart type = "area"
        height = { 140 }
        width = "100%"
        series = { chartSeries }
        options = { chartOptions }
        />
    );
};

export const WeeklyReportChart = () => {
    const chartSeries = [{
        name: 'Weekly Sales',
        data: weeklyReport.data,
    }];

    const chartOptions = {
        chart: {
            sparkline: {
                enabled: true
            }
        },
        theme: {
            monochrome: {
                enabled: true,
                color: '#31316A',
            }
        },
        plotOptions: {
            bar: {
                columnWidth: '20%',
                borderRadius: 5,
                radiusOnLastStackedBar: true,
                horizontal: false,
                distributed: false,
                endindShape: 'rounded',
                colors: {
                    backgroundBarColors: ['#F2F4F6', '#F2F4F6', '#F2F4F6', '#F2F4F6'],
                    backgroundBarRadius: 5,
                },
            }
        },
        labels: [1, 2, 3, 4, 5, 6, 7, 8],
        xaxis: {
            categories: weeklyReport.labels,
            crosshairs: {
                width: 1
            },
        },
        tooltip: {
            fillSeriesColor: false,
            onDatasetHover: {
                highlightDataSeries: false,
            },
            theme: 'light',
            style: {
                fontSize: '12px',
                fontFamily: 'Inter',
            },
            y: {
                formatter: function(val) {
                    return "$ " + val + "k"
                }
            }
        },
    };

    return ( <
        ApexChart type = "bar"
        height = { 260 }
        width = "100%"
        series = { chartSeries }
        options = { chartOptions }
        />
    );
};

export const BarChartHorizontal = (props) => {
    const { title, data = [] } = props;

    const chartSeries = [{
        name: title,
        data: data.map(d => d.value)
    }];

    const chartOptions = {
        chart: {
            foreColor: '#4B5563',
            fontFamily: 'Inter',
        },
        plotOptions: {
            bar: {
                horizontal: true,
                distributed: false,
                barHeight: '90%',
                borderRadius: 10,
                colors: {
                    backgroundBarColors: ['#fff'],
                    backgroundBarOpacity: .2,
                    backgroundBarRadius: 10,
                },
            }
        },
        colors: ['#4D4AE8'],
        dataLabels: {
            enabled: true,
            textAnchor: 'middle',
            formatter: function(val, opt) {
                return opt.w.globals.labels[opt.dataPointIndex]
            },
            offsetY: -1,
            dropShadow: {
                enabled: false,
            },
            style: {
                fontSize: '12px',
                fontFamily: 'Inter',
                fontWeight: '500',
            }
        },
        grid: {
            show: false,
            borderColor: '#f2f2f2',
            strokeDashArray: 1,
        },
        legend: {
            show: false,
        },
        yaxis: {
            labels: {
                show: false
            },
        },
        tooltip: {
            fillSeriesColor: false,
            onDatasetHover: {
                highlightDataSeries: false,
            },
            theme: 'light',
            style: {
                fontSize: '12px',
                fontFamily: 'Inter',
            },
            y: {
                formatter: function(val) {
                    return val + "%"
                }
            },
        },
        xaxis: {
            categories: data.map(d => d.label),
            labels: {
                style: {
                    fontSize: '12px',
                    fontWeight: 500,
                },
                offsetY: 5
            },
            axisBorder: {
                color: '#ffffff',
            },
            axisTicks: {
                color: '#ffffff',
                offsetY: 5
            },
        }
    };

    return ( <
        ApexChart type = "bar"
        height = { 500 }
        series = { chartSeries }
        options = { chartOptions }
        />
    );
};

export const BarChart = (props) => {
    const { data = [] } = props;

    const chartSeries = data.map(d => ({
        name: d.label,
        data: d.values.map(v => v.value),
    }));

    const chartOptions = {
        chart: {
            fontFamily: 'Inter',
            foreColor: '#4B5563',
        },
        colors: data.map(d => d.color),
        plotOptions: {
            bar: {
                horizontal: false,
                columnWidth: '50%',
                borderRadius: 5,
                colors: {
                    backgroundBarColors: ['#fff'],
                    backgroundBarOpacity: .2,
                    backgroundBarRadius: 10,
                },
            },
        },
        grid: {
            show: false,
        },
        dataLabels: {
            enabled: false
        },
        legend: {
            show: true,
            fontSize: '14px',
            fontFamily: 'Inter',
            fontWeight: 500,
            height: 40,
            tooltipHoverFormatter: undefined,
            offsetY: 10,
            markers: {
                width: 14,
                height: 14,
                strokeWidth: 1,
                strokeColor: '#ffffff',
                radius: 50,
            },
        },
        stroke: {
            show: true,
            width: 2,
            colors: ['transparent']
        },
        xaxis: {
            categories: data.reduce((_, d) => [...new Set(d.values.map(v => v.date))], []),
            labels: {
                style: {
                    fontSize: '12px',
                    fontWeight: 500,
                },
            },
            axisBorder: {
                color: '#EBE3EE',
            },
            axisTicks: {
                color: '#f1f1f1',
            }
        },
        yaxis: {
            show: false,
        },
        fill: {
            opacity: 1
        },
        responsive: [{
            breakpoint: 1499,
            options: {
                chart: {
                    height: '400px',
                },
            },
        }]
    };

    return ( <
        ApexChart type = "bar"
        height = { 400 }
        series = { chartSeries }
        options = { chartOptions }
        />
    );
};

export const PieChart = (props) => {
    const { data = [] } = props;

    const chartSeries = data.map(d => ({
        name: d.label,
        data: d.values.map(v => v.value),
    }));

    const chartOptions = {
        colors: data.map(d => d.color),
        chart: {
            fontFamily: 'Inter',
            foreColor: '#4B5563',
            toolbar: {
                show: true,
                offsetX: 0,
                offsetY: 0,
                tools: {
                    download: false,
                    selection: false,
                    zoom: false,
                    zoomin: true,
                    zoomout: true,
                    pan: false,
                    reset: false | '<img src="/static/icons/reset.png" width="20">',
                    customIcons: []
                },
                export: {
                    csv: {
                        filename: undefined,
                        columnDelimiter: ',',
                        headerCategory: 'category',
                        headerValue: 'value',
                        dateFormatter(timestamp) {
                            return new Date(timestamp).toDateString()
                        }
                    }
                },
                autoSelected: 'zoom'
            },
        },
        dataLabels: {
            enabled: false
        },
        stroke: {
            curve: 'smooth'
        },
        grid: {
            show: true,
            borderColor: '#f2f2f2',
            strokeDashArray: 1,
        },
        xaxis: {
            categories: data.reduce((_, d) => [...new Set(d.values.map(v => v.date))], []),
            labels: {
                style: {
                    fontSize: '12px',
                    fontWeight: 500,
                },
            },
            axisBorder: {
                color: '#ffffff',
            },
            axisTicks: {
                color: '#ffffff',
            }
        },
        yaxis: {
            labels: {
                style: {
                    colors: ['#4B5563'],
                    fontSize: '12px',
                    fontWeight: 500,
                },
            },
        },
        legend: {
            show: true,
            fontSize: '14px',
            fontFamily: 'Inter',
            fontWeight: 400,
            height: 60,
            tooltipHoverFormatter: undefined,
            offsetY: 20,
            markers: {
                width: 14,
                height: 14,
                strokeWidth: 1,
                strokeColor: '#fff',
                radius: 50,
            },
        },
        responsive: [{
            breakpoint: 768,
            options: {
                yaxis: {
                    show: false,
                }
            }
        }]
    };

    return ( <
        ApexChart type = "pie"
        height = { 400 }
        series = { chartSeries }
        options = { chartOptions }
        />
    );
};

export const DognutChart = (props) => {
    const { data = [] } = props;

    const chartSeries = data.map(d => ({
        name: d.label,
        data: d.values.map(v => v.value),
    }));

    const chartOptions = {
        colors: data.map(d => d.color),
        chart: {
            fontFamily: 'Inter',
            foreColor: '#4B5563',
            toolbar: {
                show: true,
                offsetX: 0,
                offsetY: 0,
                tools: {
                    download: false,
                    selection: false,
                    zoom: false,
                    zoomin: true,
                    zoomout: true,
                    pan: false,
                    reset: false | '<img src="/static/icons/reset.png" width="20">',
                    customIcons: []
                },
                export: {
                    csv: {
                        filename: undefined,
                        columnDelimiter: ',',
                        headerCategory: 'category',
                        headerValue: 'value',
                        dateFormatter(timestamp) {
                            return new Date(timestamp).toDateString()
                        }
                    }
                },
                autoSelected: 'zoom'
            },
        },
        dataLabels: {
            enabled: false
        },
        stroke: {
            curve: 'smooth'
        },
        grid: {
            show: true,
            borderColor: '#f2f2f2',
            strokeDashArray: 1,
        },
        xaxis: {
            categories: data.reduce((_, d) => [...new Set(d.values.map(v => v.date))], []),
            labels: {
                style: {
                    fontSize: '12px',
                    fontWeight: 500,
                },
            },
            axisBorder: {
                color: '#ffffff',
            },
            axisTicks: {
                color: '#ffffff',
            }
        },
        yaxis: {
            labels: {
                style: {
                    colors: ['#4B5563'],
                    fontSize: '12px',
                    fontWeight: 500,
                },
            },
        },
        legend: {
            show: true,
            fontSize: '14px',
            fontFamily: 'Inter',
            fontWeight: 400,
            height: 60,
            tooltipHoverFormatter: undefined,
            offsetY: 20,
            markers: {
                width: 14,
                height: 14,
                strokeWidth: 1,
                strokeColor: '#fff',
                radius: 50,
            },
        },
        responsive: [{
            breakpoint: 768,
            options: {
                yaxis: {
                    show: false,
                }
            }
        }]
    };

    return ( <
        ApexChart type = "pie"
        height = { 400 }
        series = { chartSeries }
        options = { chartOptions }
        />
    );
};

export const LineGraphChart = (props) => {
    const { data = [] } = props;

    const chartSeries = data.map(d => ({
        name: d.label,
        data: d.values.map(v => v.value),
    }));

    const chartOptions = {
        colors: data.map(d => d.color),
        chart: {
            fontFamily: 'Inter',
            foreColor: '#4B5563',
            toolbar: {
                show: true,
                offsetX: 0,
                offsetY: 0,
                tools: {
                    download: false,
                    selection: false,
                    zoom: false,
                    zoomin: true,
                    zoomout: true,
                    pan: false,
                    reset: false | '<img src="/static/icons/reset.png" width="20">',
                    customIcons: []
                },
                export: {
                    csv: {
                        filename: undefined,
                        columnDelimiter: ',',
                        headerCategory: 'category',
                        headerValue: 'value',
                        dateFormatter(timestamp) {
                            return new Date(timestamp).toDateString()
                        }
                    }
                },
                autoSelected: 'zoom'
            },
        },
        dataLabels: {
            enabled: false
        },
        stroke: {
            curve: 'smooth'
        },
        grid: {
            show: true,
            borderColor: '#f2f2f2',
            strokeDashArray: 1,
        },
        xaxis: {
            categories: data.reduce((_, d) => [...new Set(d.values.map(v => v.date))], []),
            labels: {
                style: {
                    fontSize: '12px',
                    fontWeight: 500,
                },
            },
            axisBorder: {
                color: '#ffffff',
            },
            axisTicks: {
                color: '#ffffff',
            }
        },
        yaxis: {
            labels: {
                style: {
                    colors: ['#4B5563'],
                    fontSize: '12px',
                    fontWeight: 500,
                },
            },
        },
        legend: {
            show: true,
            fontSize: '14px',
            fontFamily: 'Inter',
            fontWeight: 400,
            height: 60,
            tooltipHoverFormatter: undefined,
            offsetY: 20,
            markers: {
                width: 14,
                height: 14,
                strokeWidth: 1,
                strokeColor: '#fff',
                radius: 50,
            },
        },
        responsive: [{
            breakpoint: 768,
            options: {
                yaxis: {
                    show: false,
                }
            }
        }]
    };

    return ( <
        ApexChart type = "line"
        height = { 420 }
        series = { chartSeries }
        options = { chartOptions }
        />
    );
};