import React, { useState, createContext } from 'react';

export const LeadsDataContext = createContext();

export const LeadsDataContextProvider = props => {
    const [leadsData, setLeadsData] = useState(null);

    return (
       <LeadsDataContext.Provider value={[leadsData, setLeadsData]}>
          {props.children}
       </LeadsDataContext.Provider>
    );
}