
import React, {useState, useContext, useEffect} from "react";
import { Link, useHistory } from "react-router-dom";
import { LockClosedIcon, EyeIcon, EyeOffIcon  } from "@heroicons/react/solid";
import { Form, Card, Button, InputGroup, Image, FormCheck } from 'react-bootstrap';
import {useForm, Controller} from "react-hook-form";
import { Routes } from "routes";
import BgImage from "assets/img/login.jpg";
import logo from "assets/img/brand/harvast-logo.svg";

import { Auth } from "aws-amplify";
import { loginFormOptions } from "components/validator/validator";
import FormErrors from "components/validator/FormErrors";
import { UserContext } from "Context/UserContext";

import PhoneInput, { isValidPhoneNumber } from "react-phone-number-input";
import "react-phone-number-input/style.css";


export default () => {
  const [loggedUser, setLoggedUser] = useContext(UserContext);
  const [cognitoerror, setcognitoError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [userConfirmed, setuserConfirmed] = useState(true);
  const [ showPassword, setShowPassword ] = useState(false);

  const { 
    register, 
    handleSubmit, 
    reset, 
    control,
    formState: { errors } 
  } = useForm(loginFormOptions);

  const AUTH_USER_TOKEN_KEY = "";
  const UserDATA = null;

  const history = useHistory();


    // ensure loggedUser cant access sign up page
    useEffect(() => {
      async function checkAuthStatus () {
        try{
            const session = await Auth.currentSession();
            const user = await Auth.currentAuthenticatedUser();
            history.push(Routes.Transactions.path);
        }catch(error){
          console.log(error);
        }
    }
      checkAuthStatus();
    }, []);
  

    // signs in
    async function signIn(data) {
       setLoading(true); 
        try {
            const user = await Auth.signIn(data.phone, data.password);
            localStorage.setItem(AUTH_USER_TOKEN_KEY, user.signInUserSession.idToken.jwtToken);
            setLoggedUser({
              ...loggedUser,
              isAuthenticated: true,
              user: user
            });
            getSubscriptions(data);
        } catch (error) {
            setLoading(false);
            setcognitoError(error.message);
            if (error.message == "User is not confirmed."){
              setuserConfirmed(false);
            }
        }
    }

    // Check subsripttion status
    async function getSubscriptions(data) {
      let phoneNo = data.phone;
      let token = localStorage.getItem(AUTH_USER_TOKEN_KEY);
      let phoneData = phoneNo.substring(1);
     try {
      await fetch(`${process.env.REACT_APP_BASE_URL}/subscriptions?phone=%2B${phoneData}`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            'Accept': 'application/json',
            'Authorization': `Bearer ${token}`
          },
          body: JSON.stringify(),
        })
        .then(res => {
          if (res.ok) return res.json()
          // If there is an error then make sure we catch that
          return res.json().then(
            e => Promise.reject(e)
          )
        }).then(({subscription}) => {
           setLoggedUser({
            ...loggedUser,
            licenseDetails: subscription
          })

          if(subscription.allotedSeats > 1 && !subscription.subscriptionStatus.toLowerCase().includes("cancelled") && !subscription.licenseType.includes("free")){
            history.push(Routes.DashboardOverview.path);
          } else if (subscription.allotedSeats == 1 && !subscription.subscriptionStatus.toLowerCase().includes("cancelled") && !subscription.licenseType.includes("free")){
            history.push(Routes.DashboardOverview.path);
          } else if((subscription.allotedSeats == 1 && subscription.subscriptionStatus.toLowerCase().includes("cancelled")) || (subscription.allotedSeats > 1 && subscription.subscriptionStatus.toLowerCase().includes("cancelled")) ){
            history.push(Routes.Cancelled.path);
          } else if (subscription.allotedSeats == 1 && !subscription.subscriptionStatus.toLowerCase().includes("cancelled") && subscription.licenseType.includes("free")){
            history.push(Routes.Welcome.path);
          } else{
            history.push(Routes.Welcome.path);
          }
           setLoading(false);
        })
      } catch (error) {
         console.log(error)
         setLoading(false);
         history.push(Routes.Transactions.path);
      }
    }


  return (
    <main>
      <section className="d-flex flex-row align-items-center vh-lg-100  bg-soft login-section">

            <div className="d-flex flex-column-reverse flex-md-row align-items-center justify-content-center w-100">

              <div className="w-65 d-flex align-items-center justify-content-center col-right mx-3 py-4 py-lg-5">

                <div className="bg-white shadow border-0 rounded border-light py-4 py-lg-5 w-100 fmxw-500">
                  <div className="px-4 px-lg-5 w-100">
                      <div className="text-center text-md-center mb-4 mt-md-0">
                          <Image fluid rounded src={logo} className="logo-img"  />
                      </div>
                      <div className="text-md-left mb-3 mt-md-0">
                        <h3 className="mb-0 display-5">Sign in</h3>
                      </div>
                      <Form className="" onSubmit={handleSubmit(signIn)}>
                        <Form.Group id="phone" className="mb-4">
                            <Form.Label>Phone Number</Form.Label>

                            {errors.phone && <label className="text-danger d-block">{errors.phone?.message}</label>}

                            <InputGroup>
                            <Controller
                                name="phone"
                                control={control}
                                rules={{
                                validate: (value) => isValidPhoneNumber(value)
                                }}
                                render={({ field: { onChange, value } }) => (
                                <PhoneInput
                                value={value}
                                placeholder="Phone Number"
                                onChange={onChange}
                                defaultCountry="CA"
                                id="phone-input"
                                />
                                )}
                            />
                            
                            </InputGroup>
                        </Form.Group>
                        <Form.Group>
                          <Form.Group id="password" className="mb-4">
                            <Form.Label>Password</Form.Label>

                            {errors.password && <label className="text-danger d-block">{errors.password?.message}</label>}

                            <InputGroup>
                              <InputGroup.Text>
                                <LockClosedIcon className="icon icon-xs text-gray-600" />
                              </InputGroup.Text>
                              <Form.Control 
                                type={showPassword ? "text" : "password"}
                                name="password"
                                placeholder="Password"
                                {...register("password")}
                                
                              />
                              <InputGroup.Text>
                               {
                                  showPassword ? <EyeIcon className="icon icon-xs text-gray-600" onClick={() => setShowPassword(!showPassword)} /> 
                                  : <EyeOffIcon className="icon icon-xs text-gray-600" onClick={() => setShowPassword(!showPassword)} />
                               }
                              </InputGroup.Text>
                            </InputGroup>
                          </Form.Group>
                          <div className="d-flex justify-content-between align-items-top mb-4">
                            <Form.Check type="checkbox">
                              <FormCheck.Input id="defaultCheck5" className="me-2" />
                              <FormCheck.Label htmlFor="defaultCheck5" className="mb-0">Remember me</FormCheck.Label>
                            </Form.Check>
                            <Card.Link  as={Link} to={Routes.ForgotPassword.path} className="small text-end">Forgot password?</Card.Link>
                          </div>
                        </Form.Group>

                        <FormErrors formerrors={cognitoerror} />

                      {
                        !userConfirmed && <div className="mb-4 text-center">
                        <Card.Link  as={Link} to={Routes.GetCode.path} className="small text-end">Get Verification code?</Card.Link>
                      </div>
                      }
                        

                        <div className="d-grid">
                          {
                        loading ? 
                          <Button variant="gray-800" type="button" disabled>
                              <span className="sr-only">Loading...</span>
                              <span className="loading-spacer"></span>
                              <span className="spinner-border spinner-border-sm float-right" role="status" aria-hidden="true"></span>
                          </Button>
                          : 
                          <Button variant="gray-800" type="submit">
                            Sign in
                          </Button>
                        }
                        </div>
                      </Form>
                  </div>

                  <div className="d-flex justify-content-center align-items-center mt-4">
                    <span className="fw-normal">
                      Not registered?
                      <Card.Link as={Link} to={Routes.Signup.path} className="fw-bold">
                        {` Create account `}
                      </Card.Link>
                    </span>
                  </div>
                </div>

              </div>

              <div className="flex-grow-1 vh-md-100 col-left p-5 d-flex" style={{ backgroundImage: `url(${BgImage})` }}>
                <h2 className="fw-extrabold display-3 mb-md-4 mb-0 align-self-end text-white">
                  Powering Real Estate Sales
                </h2>
              </div>

            </div>
  
      </section>
    </main>
  );
};
