import React, { useState, useEffect, useContext } from "react";
import { useHistory, useLocation } from "react-router-dom";

import { HomeIcon, BellIcon } from "@heroicons/react/solid";
import { Col, Row, Alert, Breadcrumb, Card } from 'react-bootstrap';
import { UserContext } from "Context/UserContext";
import { IndividualAgentContext } from "Context/IndividualAgentContext";
import { Routes } from "routes";


export default () => {

  const [loggedUser, setLoggedUser] = useContext(UserContext);
  const [AgentInfo, setAgentInfo] = useContext(IndividualAgentContext);
  const [loading, setLoading] = useState(false);

  const token = loggedUser.user.signInUserSession.idToken.jwtToken;
  const history = useHistory();
  const location = useLocation();

  // if(location.state == undefined || location.state == null || location.state == ''){
  // history.push("/");   
  // }
  
  useEffect(() => { 
    createAgent();
  }, []);


  async function createAgent() {
    try {
      const createAgentResponse = await fetch(`${process.env.REACT_APP_BASE_URL}/agent`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          'Accept': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify(
          {
            "firstName": loggedUser.user.attributes.given_name,
            "lastName": loggedUser.user.attributes.family_name,
            "phone": loggedUser.user.attributes.phone_number,
            "email": loggedUser.user.attributes.email,
            "uuid": loggedUser.user.attributes.sub,
            "accountType": "admin"
         }),
      })
      .then(res => {
        if (res.ok) return res.json()
        // If there is an error then make sure we catch that
        return res.json().then(e => Promise.reject(e))
      }).then(({ agent, message }) => {
        setLoading(true);
        console.log(agent, message)
        setAgentInfo(agent);
        setTimeout(
          function() {
            history.push(Routes.PaymentSuccesful.path)
          },
          2500
        );
      })
    } catch (error) {
       console.log(error)
    }
  }


  return (
    <>
      <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
        <div className="d-block mb-2 mb-md-0">
          <Breadcrumb className="d-none d-md-inline-block" listProps={{ className: "breadcrumb-dark breadcrumb-transparent" }}>
            <Breadcrumb.Item><HomeIcon className="icon icon-xs" /></Breadcrumb.Item>
            <Breadcrumb.Item>Harvast</Breadcrumb.Item>
            <Breadcrumb.Item active>Create Agent</Breadcrumb.Item>
          </Breadcrumb>
        </div>
      </div>

      <React.Fragment>
        <Col xs={12} lg={4} xl={4}>
          <Alert variant="success">
            Payment Successful
          </Alert>
        </Col>
      </React.Fragment>

      <Card border="0" className="shadow my-4 ">
          <Card.Body>
            <Row className="text-gray">

              {
                loading ? 
                <React.Fragment>
                  <Col xs={12} lg={12} xl={12}>
                      <Alert variant="info" className="mb-0">
                      <Alert.Heading className="d-flex align-items-center mt-2">
                        <BellIcon className="icon icon-md me-2" />
                          Agent Created Successfully!
                      </Alert.Heading>
                      <hr />
                      <p className="mb-0">Wait as we redirect you to your details. Thanks.</p>
                    </Alert>
                </Col>
              </React.Fragment>
              : 
              <React.Fragment>
                  <Col xs={12} lg={12} xl={12}>
                      <Alert variant="primary" className="mb-0">
                      <Alert.Heading className="d-flex align-items-center mt-2">
                        <BellIcon className="icon icon-md me-2" />
                          Please Be Patient! 
                      </Alert.Heading>

                        <p>We are adding you as an agent</p>

                        <div>
                          <div className="spinner-border" role="status">
                              <span className="sr-only"></span>
                          </div>
                        </div>

                      <hr />
                      <p className="mb-0">Thanks.</p>
                    </Alert>
                </Col>
              </React.Fragment>
              }
          
            </Row>
          </Card.Body>
        </Card>
    </>
  );
};
