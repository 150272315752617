import React, { useState, createContext } from 'react';

export const AgencyLeadsDataContext = createContext();

export const AgencyLeadsDataContextProvider = props => {
   const [agencyleadsData, setAgencyLeadsData] = useState(null);

   return (
       <AgencyLeadsDataContext.Provider value={[agencyleadsData, setAgencyLeadsData]}>
          {props.children}
       </AgencyLeadsDataContext.Provider>
   );
}