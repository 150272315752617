import { v4 as uuidv4 } from "uuid";

export default [{
    "id": uuidv4(),
    "priceMonthly": 59.99,
    "priceYearly": 199,
    "variant": "secondary",
    "title": "Agency",
    "btnText": "Purchase",
    "btnTextColor": "dark",
    "description": "The ideal plan for businesses and agencies.",
    "features": [
        "Unlimited Limited Leads & Contacts",
        "Streamlined Lead Capture Process",
        "Contact Management",
        "Performance Tracking",
        "Lead Generation and Farming Analytics",
        "iOS & Android apps"
    ]
}]