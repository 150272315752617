import React, { useState, useEffect, useContext } from "react";
import { HomeIcon, BellIcon } from "@heroicons/react/solid";
import { Col, Row, Alert, Breadcrumb, Card } from 'react-bootstrap';
import { UserContext } from "Context/UserContext";
import { IndividualAgentContext } from "Context/IndividualAgentContext";

export default () => {

  const [loggedUser, setLoggedUser] = useContext(UserContext);
  const [AgentInfo, setAgentInfo] = useContext(IndividualAgentContext);
  const [loading, setLoading] = useState(false);
  const [subscriptionStatus, setSubscriptionStatus] = useState({});
  const token = loggedUser.user.signInUserSession.idToken.jwtToken;

  useEffect(() => {
    getSubscriptions();
  }, []);

  async function getSubscriptions() {
    let phoneData = loggedUser.user.attributes.phone_number;
    phoneData = phoneData.substring(1);
    try {
      await fetch(`${process.env.REACT_APP_BASE_URL}/subscriptions?phone=%2B${phoneData}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          'Accept': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify(),
      })
      .then(res => {
        if (res.ok) return res.json()
        // If there is an error then make sure we catch that
        return res.json().then(e => Promise.reject(e))
      }).then(({subscription}) => {
         console.log(subscription);
         setSubscriptionStatus(subscription);
         setLoading(true);
      })
    } catch (error) {
       console.log(error)
    }
  }

  return (
    <>
      <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2">
        <div className="d-block mb-0 mb-md-0">
          <Breadcrumb className="d-none d-md-inline-block" listProps={{ className: "breadcrumb-dark breadcrumb-transparent" }}>
            <Breadcrumb.Item><HomeIcon className="icon icon-xs" /></Breadcrumb.Item>
            <Breadcrumb.Item>Harvast</Breadcrumb.Item>
            <Breadcrumb.Item active>Agent Details</Breadcrumb.Item>
          </Breadcrumb>
        </div>
      </div>

      <div className="d-flex justify-content-between w-100 flex-wrap">
        <div className="mb-0 mb-lg-0">
          <h4 className="display-5">New Agent Details</h4>
        </div>
      </div>

      <Card border="0" className="shadow my-4 ">
        <Card.Body>
          <Row className="text-gray">

            <Col xs={12} lg={12} xl={12}>
                <Card border="0" className="">
                  <div className="border-bottom pb-2">
                    <h4 className="display-5 mb-0">Agent Details</h4>
                  </div>
                </Card>
            </Col>

            <Col xs={12} lg={6} xl={6}>
              <Card border="0" className="pt-3">
                 <ul className="p-0 m-0">
                    <li className="d-flex align-items-center justify-content-between border-bottom pb-3">
                      <div className="d-flex align-items-center h6 mb-0">
                          <span className="fw-extrabold">First Name</span>: {loggedUser.user.attributes.given_name}
                      </div>
                    </li>
                 </ul>
              </Card>
            </Col>

            <Col xs={12} lg={6} xl={6}>
              <Card border="0" className="pt-3">
                 <ul className="p-0 m-0">
                    <li className="d-flex align-items-center justify-content-between border-bottom pb-3">
                      <div className="d-flex align-items-center h6 mb-0">
                          <span className="fw-extrabold">Last Name</span>: {loggedUser.user.attributes.family_name}
                      </div>
                    </li>
                 </ul>
              </Card>
            </Col>

            <Col xs={12} lg={6} xl={6}>
              <Card border="0" className="pt-3">
                 <ul className="p-0 m-0">
                    <li className="d-flex align-items-center justify-content-between border-bottom pb-3">
                      <div className="d-flex align-items-center h6 mb-0">
                          <span className="fw-extrabold">Email</span>: {loggedUser.user.attributes.email}
                      </div>
                    </li>
                 </ul>
              </Card>
            </Col>

            <Col xs={12} lg={6} xl={6}>
              <Card border="0" className="pt-3">
                 <ul className="p-0 m-0">
                    <li className="d-flex align-items-center justify-content-between border-bottom pb-3">
                      <div className="d-flex align-items-center h6 mb-0">
                          <span className="fw-extrabold">Phone</span>: {loggedUser.user.attributes.phone_number}
                      </div>
                    </li>
                 </ul>
              </Card>
            </Col>

          </Row>
        </Card.Body>
      </Card>

      <Card border="0" className="shadow my-4 ">
        <Card.Body>
          <Row className="text-gray">

            <Col xs={12} lg={12} xl={12}>
                <Card border="0" className="">
                  <div className="border-bottom pb-2">
                    <h4 className="display-5 mb-0">Subscription Status</h4>
                  </div>
                </Card>
            </Col>

             {
               loading ? 
               <React.Fragment>
  
              <Col xs={12} lg={6} xl={6}>
                <Card border="0" className="pt-3">
                  <ul className="p-0 m-0">
                      <li className="d-flex align-items-center justify-content-between border-bottom pb-3">
                        <div className="d-flex align-items-center h6 mb-0">
                            <span className="fw-extrabold">Payment Status</span>: {subscriptionStatus.paymentStatus}
                        </div>
                      </li>
                  </ul>
                </Card>
              </Col>

              <Col xs={12} lg={6} xl={6}>
                <Card border="0" className="pt-3">
                  <ul className="p-0 m-0">
                      <li className="d-flex align-items-center justify-content-between border-bottom pb-3">
                        <div className="d-flex align-items-center h6 mb-0">
                            <span className="fw-extrabold">Subscription Status</span>: {subscriptionStatus.subscriptionStatus}
                        </div>
                      </li>
                  </ul>
                </Card>
              </Col>
            </React.Fragment>
            :
              <Col xs={12} lg={12} xl={12}>
                <Card border="0" className="">
                  <div className="border-bottom py-2">
                    <h4 className="display-5 mb-0 text-success">Loading...</h4>
                  </div>
                </Card>
             </Col>
            }

          </Row>
        </Card.Body>
      </Card>
    </>
  );
};