
import React from "react";
import moment from "moment-timezone";
import { CogIcon, ShoppingCartIcon } from "@heroicons/react/solid";
import { Row, Col, Card, OverlayTrigger, Tooltip, Image, Button } from 'react-bootstrap';
import BS5Logo from "assets/img/technologies/bootstrap-5-logo.svg";
import ReactLogo from "assets/img/technologies/react-logo.svg";

export default (props) => {
  const currentYear = moment().get("year");

  return (
    <div>
      <footer className="bg-white rounded shadow p-2 mb-4">
        <Row>
          <Col xs={12} md={4} xl={6} className="mb-0 mb-md-0">
            <p className="mb-0 text-center text-lg-start">
              © {`${currentYear} `}
              <Card.Link href="" target="_blank" className="text-primary fw-normal">
                Harvast
              </Card.Link>
            </p>
          </Col>
          <Col xs={12} md={8} xl={6} className="text-center text-lg-start">
           
          </Col>
        </Row>
      </footer>
    </div>
  );
};
