import React, { useState, useEffect, useContext } from "react";
import { useHistory, Link } from "react-router-dom";
import { Routes } from "routes";
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { HomeIcon, CreditCardIcon } from "@heroicons/react/solid";
import { Col, Row, Form, Button, Breadcrumb, InputGroup, Card, Modal, Image } from 'react-bootstrap';
import { addAgentformOptions } from "components/validator/validator";
import { useForm, Controller } from "react-hook-form";
import { PriceCardWidget } from "components/Widgets";
import TRANSACTIONS_DATA from "data/transactions";
import pricings from "data/pricings";
import { UserContext } from "Context/UserContext";
import GooglePlayImage from "assets/img/icons/google-play.png";
import AppleStoreImage from "assets/img/icons/apple-store.png";

import PhoneInput, { isValidPhoneNumber } from "react-phone-number-input";
import "react-phone-number-input/style.css";

const SwalWithBootstrapButtons = withReactContent(Swal.mixin({
  customClass: {
    confirmButton: 'btn btn-primary me-3',
    cancelButton: 'btn btn-gray'
  },
  buttonsStyling: false
}));

export default () => {
  const [transactions, setTransactions] = useState(TRANSACTIONS_DATA.map(t => ({ ...t, show: true })));
  const [searchValue, setSearchValue] = useState("");
  const [statusValue, setStatusValue] = useState("all");
  const [isPriceMonthly, setIsPriceMonthly] = useState(true);
  const [activeStatus, setActiveStatus] = useState(false);
  const [licenseinfo, setLicenseInfo] = useState(null);
  const [teamData, setTeamData] = useState({});
  const [modalLoading, setModalLoading] = useState(true);
  const [showSignup, setShowSignup] = useState(false);
  const [addingAgentStatus, setAddingAgentStatus] = useState('initial');
  const [btnloading, setbtnLoading] = useState(false);
  const [agentbtnloading, setagentbtnLoading] = useState(false);
  const [payDetailsbtnload, setpayDetailsbtnload] = useState(false);
  const handleClose = () => setShowSignup(false);
  const [seats, setSeats] = useState(1);
  const [fetcherror, setFetchError] = useState(null);
  const priceType = isPriceMonthly ? "monthly" : "annual";


  const [loggedUser, setLoggedUser] = useContext(UserContext);

  const token = loggedUser.user.signInUserSession.idToken.jwtToken;

  const togglePriceType = () => {
    setIsPriceMonthly(!isPriceMonthly);
  };

  const changeSearchValue = (e) => {
    const newSearchValue = e.target.value;
    const newTransactions = transactions.map(t => {
      const subscription = t.subscription.toLowerCase();
      const shouldShow = subscription.includes(newSearchValue)
        || `${t.price}`.includes(newSearchValue)
        || t.status.includes(newSearchValue)
        || `${t.invoiceNumber}`.includes(newSearchValue);

      return ({ ...t, show: shouldShow });
    });

    setSearchValue(newSearchValue);
    setTransactions(newTransactions);
  };

  const changeStatusValue = (e) => {
    const newStatusValue = e.target.value;
    const newTransactions = transactions.map(u => ({ ...u, show: u.status === newStatusValue || newStatusValue === "all" }));

    setStatusValue(newStatusValue);
    setTransactions(newTransactions);
  };

  const history = useHistory();

  const {
    register,
    handleSubmit,
    reset,
    control,
    formState: { errors }
  } = useForm(addAgentformOptions);

  useEffect(() => {
    if (loggedUser.licenseDetails !== null && loggedUser.licenseDetails.licenseType !== 'free' ) {
      setActiveStatus(true);
    }  else if (loggedUser.licenseDetails !== null && loggedUser.licenseDetails.licenseType === 'free'){
      setActiveStatus(false);
    } else {
      setActiveStatus(false);
    }
  }, []);


  // buying licence

  const LicenseBought = (SeatsNo) => {
    if (SeatsNo == 1) {
      setLicenseInfo({
        "type": "agency",
        "firstName": loggedUser.user.attributes.given_name,
        "lastName": loggedUser.user.attributes.family_name,
        "phone": loggedUser.user.attributes.phone_number,
        "quantity": seats,
        "successUrl": "https://master.dmu5xszxbulza.amplifyapp.com/#/CreateAgency?session_id={CHECKOUT_SESSION_ID}",
        "cancelUrl": "https://master.dmu5xszxbulza.amplifyapp.com/#/transactions"
      });
    } else if (SeatsNo > 1) {
      setLicenseInfo({
        "type": "agency",
        "firstName": loggedUser.user.attributes.given_name,
        "lastName": loggedUser.user.attributes.family_name,
        "phone": loggedUser.user.attributes.phone_number,
        "quantity": seats,
        "successUrl": "https://master.dmu5xszxbulza.amplifyapp.com/#/CreateAgency?session_id={CHECKOUT_SESSION_ID}",
        "cancelUrl": "https://master.dmu5xszxbulza.amplifyapp.com/#/transactions"
      });
    }
  }

  const handleSeatsNumber = n => {
    if (n >= 1) {
      setSeats(n);
    } else {
      setSeats(1);
    }
  }


  useEffect(() => {
    if (licenseinfo === null) {
      return
    }
    fetchData(licenseinfo);
  }, [licenseinfo]);

  const noTeamAlert = () => {

    const result = SwalWithBootstrapButtons.fire({
      title: 'No Team Detected',
      text: "Create a team first. Agents are added in teams",
      icon: 'warning',
      confirmButtonColor: '#3085d6',
      confirmButtonText: 'Yes, Create!'
    }).then(function () {
      history.push({ pathname: Routes.CreateTeam.path, from: 'manageTeam' });
    });
  };

  const fetchData = (licenseinfo) => {
    fetch(`${process.env.REACT_APP_BASE_URL}/checkout`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        'Accept': "application/json"
      },
      // Send along all the information about the items
      body: JSON.stringify(
        licenseinfo
      ),
    })
      .then(res => {
        if (res.ok) return res.json()
        // If there is an error then make sure we catch that
        return res.json().then(e => Promise.reject(e))
      })
      .then(({ url }) => {
        // On success redirect the customer to the returned URL
        window.location = url
      })
      .catch(e => {
        console.error(e.error)
      })
  };


  // add agent
  async function getSubscriptions() {
    setAddingAgentStatus('details');
    setbtnLoading(true);
    let phoneData = loggedUser.user.attributes.phone_number;
    phoneData = phoneData.substring(1);
    try {
      await fetch(`${process.env.REACT_APP_BASE_URL}/subscriptions?phone=%2B${phoneData}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          'Accept': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify(),
      })
        .then(res => {
          if (res.ok) return res.json()
          // If there is an error then make sure we catch that
          return res.json().then(e => Promise.reject(e))
        }).then(({ subscription }) => {
          let agentSeats = subscription.allotedSeats - subscription.usedSeats;
          if (agentSeats <= 0) {
            modifyLicense(subscription);
          } else {
            getAgency();
          }
        })
    } catch (error) {
      setFetchError(error.message);
      setbtnLoading(false);
    }
  }

  async function modifyLicense(subscription) {
    setAddingAgentStatus('billing');
    try {
      const createAgentResponse = await fetch(`${process.env.REACT_APP_BASE_URL}/subscriptions/${subscription.subscriptionId}/modifyLicense`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          'Accept': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify({
          "additionalSeats": 1
        }),
      })
        .then(res => {
          if (res.ok) return getAgency();
          // If there is an error then make sure we catch that
          return res.json().then(e => Promise.reject(e))
        })
    } catch (error) {
      setFetchError(error.message);
      setbtnLoading(false);
    }
  }

  async function getAgency() {
    setAddingAgentStatus('agency');
    const agencyId = loggedUser.licenseDetails.agencyUuid;
    try {
      await fetch(`${process.env.REACT_APP_BASE_URL}/agency/${agencyId}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          'Accept': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify(),
      })
        .then(res => {
          if (res.ok) return res.json()
          // If there is an error then make sure we catch that
          return res.json().then(e => Promise.reject(e))
        }).then(({ agency }) => {
          // setAgencyData(agency);
          if (agency.teams.length === 0 || agency.teams.uuid === null) {
            return noTeamAlert()
          } else {
            getTeam(agency);
          }
        })
    } catch (error) {
      if (error.message === 'Agency not found') {
        history.push({ pathname: Routes.CreateAgency.path });
      }
    }
  }

  async function getTeam(agency_data) {
    setAddingAgentStatus('team');
    const teams = agency_data.teams[0].uuid;
    try {
      const createAgentResponse = await fetch(`${process.env.REACT_APP_BASE_URL}/team/${teams}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          'Accept': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify(),
      })
        .then(res => {
          if (res.ok) return res.json()
          // If there is an error then make sure we catch that
          return res.json().then(e => Promise.reject(e))
        }).then(({ team }) => {
          setTeamData(team);
          setShowSignup(true);
          setbtnLoading(false);
        })
    } catch (error) {
      setFetchError(error.message);
      setbtnLoading(false);
    }
  }

  // adding agent when clicked

  async function sendEmail(data) {
    setagentbtnLoading(true);
    try {
      await fetch(`${process.env.REACT_APP_BASE_URL}/email`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          'Accept': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify(
          {
            "subscriptionId": loggedUser.licenseDetails.subscriptionId,
            "action": "notifyInvitedAgent",
            "sendTo": data.email
          }),
      })
        .then(res => {
          if (res.ok) return addAgent(data);
          // If there is an error then make sure we catch that
          return res.json().then(e => Promise.reject(e))
        })
    } catch (error) {
      setbtnLoading(false);
    }
  }

  async function addAgent(data) {
    try {
      const createAgentResponse = await fetch(`${process.env.REACT_APP_BASE_URL}/agent`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          'Accept': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify(
          {
            "firstName": data.firstName,
            "lastName": data.lastName,
            "phone": data.phone,
            "email": data.email,
            "teamUuid": teamData.uuid
          }),
      })
        .then(res => {
          if (res.ok) return res.json()
          // If there is an error then make sure we catch that
          return res.json().then(e => Promise.reject(e))
        }).then(({ agent, message }) => {
          setagentbtnLoading(false);
          setModalLoading(false);
          // setAgentInfo(agent);
        })
    } catch (error) {
      setFetchError(error.message);
      setagentbtnLoading(false);
    }
  }

  // check payment details so to manage account
  const paymentDetails = () => {
    setpayDetailsbtnload(true);
    fetch(`${process.env.REACT_APP_BASE_URL}/portal`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        'Accept': "application/json",
        'Authorization': `Bearer ${token}`
      },
      // Send along all the information about the items
      body: JSON.stringify(
        {
          "customerId": loggedUser.licenseDetails.customerId,
          "returnUrl": "https://app.harvast.com/#/transactions"
        }),
    })
      .then(res => {
        if (res.ok) return res.json()
        // If there is an error then make sure we catch that
        return res.json().then(e => Promise.reject(e))
      })
      .then(({ url }) => {
        // On success redirect the customer to the returned URL
        window.location = url
        setpayDetailsbtnload(false);
      })
      .catch(e => {
        setpayDetailsbtnload(false);
        console.error(e.error)
      })
  };

  return (
    <>
      <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
        <div className="d-block mb-4 mb-md-0">
          <Breadcrumb className="d-none d-md-inline-block" listProps={{ className: "breadcrumb-dark breadcrumb-transparent" }}>
            <Breadcrumb.Item><HomeIcon className="icon icon-xs" /></Breadcrumb.Item>
            <Breadcrumb.Item onClick={() => history.push({pathname: Routes.DashboardOverview.path})}>Home</Breadcrumb.Item>
          </Breadcrumb>
          <h4>Buy License</h4>
        </div>
      </div>

      <Card border="0" className="shadow my-4 ">
        <Card.Body>
          <Row className="text-gray">

            {
              activeStatus ?
                <>
                  <Col xs={12} className="d-flex align-items-center justify-content-center pb-4">
                    <Card border="0" className="shadow w-100">
                      <Card.Body>
                        <div className="d-flex align-items-center justify-content-between border-bottom pb-3">
                          <div className="d-flex align-items-center h6 mb-0">
                            Subscription Status
                          </div>
                          <div>
                            <Card.Link href="#" className="d-flex align-items-center fw-bold">
                              No of Licenses
                            </Card.Link>
                          </div>
                        </div>
                        <div className="d-flex align-items-center justify-content-between py-3">
                          <div>
                            <div className="d-flex align-items-center h6 mb-0">
                              {loggedUser.licenseDetails.subscriptionStatus}
                            </div>
                          </div>
                          <div>
                            <Card.Link href="#top" className="d-flex align-items-center fw-bold">
                              {loggedUser.licenseDetails.allotedSeats}
                            </Card.Link>
                          </div>
                        </div>
                      </Card.Body>
                    </Card>
                  </Col>

                  <Col xs={12} className="d-flex align-items-center justify-content-center pb-4">
                    <Card border="0" className="shadow w-100">
                      <Card.Body>
                        <div className="d-flex align-items-center justify-content-center">
                          {
                            payDetailsbtnload ?
                              <Button variant="outline-gray-700" type="button" disabled>
                                <span className="sr-only">Check Payment Details ...</span>
                                <span className="loading-spacer"></span>
                                <span className="spinner-border spinner-border-sm float-right" role="status" aria-hidden="true"></span>
                              </Button>
                              :
                              <Button
                                variant="outline-gray-700 d-flex align-items-center"
                                className="m-1 animate-up-2"
                                onClick={paymentDetails}
                              >Check Payment Details  <CreditCardIcon className="icon icon-xs me-1 d-inline-block ml-2" />
                              </Button>
                          }
                        </div>
                      </Card.Body>
                    </Card>
                  </Col>

                  {
                    addingAgentStatus === "initial" && <></>
                  }

                  {
                    addingAgentStatus === "details" && <Col xs={12} className="d-flex align-items-center justify-content-center pb-2">
                      <h5>Confirming Details...</h5>
                    </Col>
                  }

                  {
                    addingAgentStatus === "billing" && <Col xs={12} className="d-flex align-items-center justify-content-center pb-2">
                      <h5>Billing...</h5>
                    </Col>
                  }

                  {
                    addingAgentStatus === "agency" && <Col xs={12} className="d-flex align-items-center justify-content-center pb-2">
                      <h5>Confirming Agency...</h5>
                    </Col>
                  }

                  {
                    addingAgentStatus === "team" && <Col xs={12} className="d-flex align-items-center justify-content-center pb-2">
                      <h5>Confirming team...</h5>
                    </Col>
                  }
                  

                  {/* remove add agent from payment  */}

                  <Col xs={12} className="d-flex align-items-center justify-content-center pb-2">
                    <div className="d-grid">
                      {
                        btnloading ?
                          <Button variant="gray-800" type="button" disabled>
                            <span className="sr-only">Loading...</span>
                            <span className="loading-spacer"></span>
                            <span className="spinner-border spinner-border-sm float-right" role="status" aria-hidden="true"></span>
                          </Button>
                          :
                          <Button variant="gray-800" onClick={getSubscriptions}>Add an Agent</Button>
                      }
                    </div>
                  </Col>

                  <Col xs={12}>
                      <div className="apps-section text-center">
                        <h3 className="mb-2">Download  Harvast</h3>
                        <p className="mb-4">The Harvast app is available for download for both Android and iOS.</p>
                        <div className="d-flex align-items-center justify-content-center mb-4">
                            <a className="d-block app-icon app-icon-left" href="https://play.google.com/store/apps/details?id=com.wocketship.harvast.android" target="_blank">
                              <Image src={GooglePlayImage} className="img-fluid" />
                            </a>
                            <a className="d-block app-icon app-icon-right" href="https://apps.apple.com/ca/app/harvast/id1644409949" target="_blank">
                              <Image src={AppleStoreImage} className="img-fluid" />
                            </a>
                        </div>
                      </div>
                  </Col>
                </>
                :
                <>
                  {pricings.map(pricing => (
                    <React.Fragment key={`pricing-${pricing.id}`}>
                      <Col xs={12} lg={4} xl={4}>
                        <PriceCardWidget priceType={priceType} {...pricing} LicenseBought={LicenseBought} onChange={handleSeatsNumber} SeatsNo={seats} />
                      </Col>
                    </React.Fragment>
                  ))}
                </>
            }

            {
              fetcherror && <div className="text-center text-md-center text-danger">{fetcherror}</div>
            }

          </Row>
        </Card.Body>
      </Card>

      <React.Fragment>
        <Modal dialogClassName="wider-modal" centered show={showSignup} onHide={handleClose}>
          <Modal.Header className="border-0">
            <Button variant="close" aria-label="Close" onClick={handleClose} />
          </Modal.Header>
          <Modal.Body className="p-0">
            <Card border="light" className="px-0 px-md-4 py-0 border-0">
              <Card.Header className="text-center text-md-center border-0 mb-0 mt-md-0">
                <h4>Invite Agent</h4>
              </Card.Header>
              <Card.Body>
                {
                  modalLoading ? <Form action="#" onSubmit={handleSubmit(sendEmail)}>

                    <Row>
                      <Col md={4} className="mb-3">
                        <Form.Group id="firstName">
                          <Form.Label>First Name</Form.Label>

                          {errors.firstName && <label className="text-danger d-block mb-3">{errors.firstName?.message}</label>}

                          <Form.Control
                            type="text"
                            name="firstName"
                            {...register("firstName")}
                            placeholder="First Name"
                          />
                        </Form.Group>
                      </Col>
                      <Col md={4} className="mb-3">
                        <Form.Group id="lastName">
                          <Form.Label>Last Name</Form.Label>

                          {errors.lastName && <label className="text-danger d-block mb-3">{errors.lastName?.message}</label>}

                          <Form.Control
                            type="text"
                            name="lastName"
                            {...register("lastName")}
                            placeholder="Last Name"
                          />
                        </Form.Group>
                      </Col>
                      <Col md={4} className="mb-3">
                        <Form.Group id="email">
                          <Form.Label>Email</Form.Label>

                          {errors.email && <label className="text-danger d-block">{errors.email?.message}</label>}

                          <InputGroup>
                            <Form.Control
                              autoFocus
                              type="email"
                              name="email"
                              {...register("email")}
                              placeholder="example@company.com"
                            />
                          </InputGroup>
                        </Form.Group>
                      </Col>
                      <Col md={12} className="mb-3">
                        <Form.Group id="phone">
                          <Form.Label>Phone</Form.Label>

                          {errors.phone && <label className="text-danger d-block mb-3">{errors.phone?.message}</label>}

                          <InputGroup>
                            <Controller
                              name="phone"
                              control={control}
                              rules={{
                                validate: (value) => isValidPhoneNumber(value)
                              }}
                              render={({ field: { onChange, value } }) => (
                                <PhoneInput
                                  value={value}
                                  placeholder="Phone Number"
                                  onChange={onChange}
                                  defaultCountry="CA"
                                  id="phone-input"
                                />
                              )}
                            />
                          </InputGroup>

                        </Form.Group>
                      </Col>
                    </Row>

                    <div className="d-grid">
                      {
                        agentbtnloading ?
                          <Button variant="gray-800" type="button" disabled>
                            <span className="sr-only">Loading...</span>
                            <span className="loading-spacer"></span>
                            <span className="spinner-border spinner-border-sm float-right" role="status" aria-hidden="true"></span>
                          </Button>
                          :
                          <Button variant="gray-800" type="submit">Add an Agent</Button>
                      }
                    </div>
                  </Form>
                    : <div>
                      <div className="p-3">
                        <h3 className="text-success text-center">Agent Added!</h3>
                      </div>
                      <div className="d-grid">
                        <Button variant="gray-800" onClick={handleClose}>Close</Button>
                      </div>
                    </div>
                }

              </Card.Body>
            </Card>
          </Modal.Body>
        </Modal>
      </React.Fragment>
    </>
  );
};