
import React, {useState} from "react";
import { ArrowNarrowLeftIcon } from "@heroicons/react/solid";
import { Col, Row, Form, Card, Button, Container, InputGroup } from 'react-bootstrap';
import { Link, Redirect} from 'react-router-dom';
import { Auth } from "aws-amplify";
import { Routes } from "routes";
import {useForm, Controller} from "react-hook-form";
import { ForgotPasswordFormOptions } from "components/validator/validator";

import PhoneInput, { isValidPhoneNumber } from "react-phone-number-input";
import "react-phone-number-input/style.css";

export default () => {
  const [phoneInfo, setPhoneInfo] = useState({
    phone: "",
    redirect: false
  });

  const { 
    register, 
    handleSubmit, 
    reset, 
    control,
    formState: { errors } 
  } = useForm(ForgotPasswordFormOptions);


  function onSubmit(data) {
    let username = data.phone;
    Auth.forgotPassword(username)
    .then(data => {
      setPhoneInfo({
        ...phoneInfo,
        phone: username,
        redirect: true
      });
    })
    .catch(err => console.log(err));
  }

  return (
    <main>
      <section className="vh-lg-100 mt-5 mt-lg-0 bg-soft d-flex align-items-center">
        <Container>
          <Row className="justify-content-center">
            <p className="text-center">
              <Card.Link as={Link} to={Routes.Signin.path} className="d-flex align-items-center justify-content-center">
                <ArrowNarrowLeftIcon className="icon icon-xs me-2" /> Back to sign in
              </Card.Link>
            </p>
            <Col xs={12} className="d-flex align-items-center justify-content-center">
              <div className="signin-inner my-3 my-lg-0 bg-white shadow border-0 rounded p-4 p-lg-5 w-100 fmxw-500">
                <h3>Forgot your password?</h3>
                <p className="mb-4">Don't fret! Just type in your phone number and we will send you a code to reset your password!</p>
                <Form onSubmit={handleSubmit(onSubmit)}>
                  <div className="mb-4">
                    <Form.Group id="phone" className="mb-4">

                      <Form.Label>Phone Number</Form.Label>

                      {errors.phone && <label className="text-danger d-block">{errors.phone?.message}</label>}

                      <InputGroup>
                          <Controller
                              name="phone"
                              control={control}
                              rules={{
                              validate: (value) => isValidPhoneNumber(value)
                              }}
                              render={({ field: { onChange, value } }) => (
                              <PhoneInput
                              value={value}
                              placeholder="Phone Number"
                              onChange={onChange}
                              defaultCountry="CA"
                              id="phone-input"
                              />
                              )}
                            />  
                      </InputGroup>
                    </Form.Group>
                    {/* <Form.Label htmlFor="email">Your Email</Form.Label>
                    
                    {errors.email && <label className="text-danger d-block">{errors.email?.message}</label>}

                    <InputGroup id="email">
                      <Form.Control 
                      autoFocus 
                      type="email" 
                      name="email"
                      {...register("email")}
                      placeholder="john@company.com"
                      />
                    </InputGroup> */}
                  </div>
                  <div className="d-grid">
                    <Button variant="gray-800" type="submit">
                      Recover password
                    </Button>
                  </div>
                </Form>

                {phoneInfo.redirect && (
                    <Redirect
                      to={{
                        pathname: Routes.ResetPassword.path,
                        phoneId: phoneInfo.phone
                      }}
                    />
                )}

              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </main>
  );
};
