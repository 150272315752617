import React, { useState, createContext } from 'react';

export const AppointmentsDataContext = createContext();

export const AppointmentsDataContextProvider = props => {
    const [appointmentsData, setAppointmentsData] = useState(null);

    return (
       <AppointmentsDataContext.Provider value={[appointmentsData, setAppointmentsData]}>
          {props.children}
       </AppointmentsDataContext.Provider>
    );
}