
import React from "react";
import { ChevronLeftIcon } from "@heroicons/react/solid";
import { Col, Row, Card, Image, Button, Container } from 'react-bootstrap';
import { Routes } from "routes";
import { Link } from 'react-router-dom';
import NotFoundImage from "assets/img/illustrations/404.svg";
import GooglePlayImage from "assets/img/icons/google-play.png";
import AppleStoreImage from "assets/img/icons/apple-store.png";
import PhonesImage from "assets/img/illustrations/phones.png";



export default () => {
  return (
    <main>
      <section className="main-inner-vh d-flex align-items-center justify-content-center py-3 py-md-5">
        <Container>
          <Row className="welcome-page">
            <Col xs={12} className="text-center d-flex align-items-center justify-content-center">
              <div>
                <h1 className="mb-3 header">WELCOME TO HARVAST!</h1>
                <p className="mb-4">
                    You have successfully completed your registration. You can now download Harvast and start farming.
                </p>       
              </div>
            </Col>
            <Col xs={12}>
                <div className="apps-section">
                    <Row>
                      <Col sm={4}>
                         <div className="img-holder">
                           <Image src={PhonesImage} className="img-fluid w-75" />
                         </div>
                          
                      </Col>
                      <Col sm={8}>
                          <div>
                            <h3 className="mb-2">Download Harvast</h3>
                            <p className="mb-3">Sign in using your current credentials</p>
                            <p className="mb-4">The Harvast app is available for download for both Android and iOS.</p>
                            <div className="d-flex align-items-center mb-4">
                                <a className="d-block app-icon app-icon-left" href="https://play.google.com/store/apps/details?id=com.wocketship.harvast.android" target="_blank">
                                  <Image src={GooglePlayImage} className="img-fluid" />
                                </a>
                                <a className="d-block app-icon app-icon-right" href="https://apps.apple.com/ca/app/harvast/id1644409949" target="_blank">
                                  <Image src={AppleStoreImage} className="img-fluid" />
                                </a>
                            </div>
                            <h3 className="mb-2">Upgrade to premium</h3>
                            <p className="mb-3">Get a lot more features by upgrading to premium</p>
                            <div className="d-flex align-items-center">
                                <Card.Link as={Link} to={Routes.Transactions.path} className="btn btn-gray-900 py-3 px-3">
                                  Unlock Premium
                                </Card.Link>
                            </div>
                          </div>
                      </Col>
                    </Row>
                </div>
            </Col>
          </Row>
        </Container>
      </section>
    </main>
  );
};
