
import React, {useState} from "react";
import { ArrowNarrowLeftIcon, LockClosedIcon, ThumbUpIcon} from "@heroicons/react/solid";
import { Col, Row, Form, Card, Button, Container, InputGroup } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import {useForm} from "react-hook-form";
import { Routes } from "routes";
import { Auth } from "aws-amplify";
import { codeFormOptions } from "components/validator/validator";
import FormErrors from "components/validator/FormErrors";


export default (props) => {

  const [userinfo, setuserinfo] = useState({
    username: props.location.phoneId,
    code: ""
  });
  const [cognitoerror, setcognitoError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [success, setSucesss] = useState(false)

  const { 
    register, 
    handleSubmit, 
    reset, 
    formState: { errors } 
  } = useForm(codeFormOptions);


  async function confirmSignUp(data) {
    setLoading(true);
    try {
      await Auth.confirmSignUp(userinfo.username, data.code);
      setLoading(false);
      setSucesss(true);
    } catch (error) {
      setLoading(false);
      setcognitoError(error.message);
      console.log('error confirming sign up', error);
    }
  }

  return (
    <main>
      <section className="vh-lg-100 mt-5 mt-lg-0 bg-soft d-flex align-items-center">
        <Container>
          <Row className="justify-content-center">
            <p className="text-center">
              <Card.Link as={Link} to={Routes.Signin.path} className="d-flex align-items-center justify-content-center">
                <ArrowNarrowLeftIcon className="icon icon-xs me-2" /> Back to sign in
              </Card.Link>
            </p>
            <Col xs={12} className="d-flex align-items-center justify-content-center">
              <div className="bg-white shadow border-0 rounded p-4 p-lg-5 w-100 fmxw-500">
                <h3 className="mb-4">Reset password</h3>
                <Form onSubmit={handleSubmit(confirmSignUp)}>
                  <Form.Group id="phone" className="mb-4">
                    <Form.Label>Your Phone Number</Form.Label>
                    <InputGroup>
                      <Form.Control 
                      disabled 
                      type="tel"  
                      value= {props.location.phoneId}
                      placeholder="example@company.com"
                    /> 
                   </InputGroup>
                  </Form.Group>

                  <Form.Group id="code" className="mb-4">
                    <Form.Label>Confirmation Code</Form.Label>

                    {errors.code && <label className="text-danger d-block">{errors.code?.message}</label>}

                    <InputGroup>
                      <InputGroup.Text>
                        <ThumbUpIcon className="icon icon-xs text-gray-600" />
                      </InputGroup.Text>
                      <Form.Control
                        autoFocus
                        type="number"
                        name="code"
                        placeholder="Confirmation Code"
                        {...register("code")}
                      />
                    </InputGroup>
                  </Form.Group>

                  
                  {success ? 
                  <div className="d-inline-flex"> <p className="text-success ">Account Confirmed Successfully:</p> <span className="loading-spacer"></span> <Card.Link as={Link} to={Routes.Signin.path} className="fw-bold">{` Login here `}</Card.Link></div>
                  : ` `}
                  <FormErrors formerrors={cognitoerror} />
                  <div className="d-grid">
                       {
                        loading ? 
                          <Button variant="gray-800" type="button" disabled>
                              <span className="sr-only">Loading...</span>
                              <span className="loading-spacer"></span>
                              <span className="spinner-border spinner-border-sm float-right" role="status" aria-hidden="true"></span>
                          </Button>
                          : 
                          <Button variant="primary" type="submit">
                            Send Code
                          </Button>
                        }
                  </div>
                </Form>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </main>
  );
};
