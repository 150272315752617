import React, { useState, useEffect, useContext } from "react";
import { useDropzone } from "react-dropzone";
import { Col, Row, Card, Form, Image, Button, Dropdown } from 'react-bootstrap';
import { ProfileCardWidget } from "components/Widgets";
import moment from "moment-timezone";
import { Routes } from "routes";
import Profile1 from "assets/img/team/profile-picture-1.jpg";
import Profile3 from "assets/img/team/profile-picture-3.jpg";
import ProfileCover from "assets/img/profile-cover.jpg";
import { UserContext } from "Context/UserContext";


export default () => {
  const [files, setFiles] = useState([]);
  const [loggedUser, setLoggedUser] = useContext(UserContext);
  const { getRootProps, getInputProps } = useDropzone({
    accept: 'image/*',
    onDrop: files => setFiles(files.map(file => ({
      ...file,
      preview: URL.createObjectURL(file)
    })))
  });


  const DropzoneFile = (props) => {
    const { path, preview } = props;

    return (
      <Col xs={6} className="dropzone-preview">
        <Image src={preview} className="dropzone-image" />
        <Card.Text className="dropzone-filename">
          {path}
        </Card.Text>
      </Col>
    );
  };

  
  return (
    <>

      <Row className="mt-3">

        <Col xs={12} lg={4}>
          <Row>
            <Col xs={12} className="mb-4">
              <ProfileCardWidget
                fullName= {loggedUser.licenseDetails.customerName}
                picture={Profile1}
                jobTitle="Real Estate Agent"
                email={loggedUser.licenseDetails.email}
                phone={loggedUser.licenseDetails.phone}
              />
            </Col>    
          </Row>
        </Col>

        <Col xs={12} lg={8}>
          <Card border="0" className="shadow mb-3">
            <Card.Body>
              <Row>
                
                <Col xs={12} lg={12} xl={12} className="mb-1">
                  <Card border="0">
                      <h5 className="fw-extrabold">Subscription Status</h5>
                  </Card>
                </Col>
                <Col xs={12} lg={12} xl={12}>
                  <div className="d-flex align-items-center justify-content-between border-bottom py-3">
                      <div className="d-flex align-items-center h6 mb-0">
                        <span className="fw-bold">License Type</span>
                      </div>
                      <div>
                        <Card.Link href="#" className="d-flex align-items-center fw-bold">
                         {loggedUser.licenseDetails.licenseType}
                        </Card.Link>
                      </div>
                  </div>
                </Col>
                <Col xs={12} lg={12} xl={12}>
                  <div className="d-flex align-items-center justify-content-between border-bottom py-3">
                      <div className="d-flex align-items-center h6 mb-0">
                        <span className="fw-bold">Subscription Status</span>
                      </div>
                      <div>
                        <Card.Link href="#" className="d-flex align-items-center fw-bold">
                         {loggedUser.licenseDetails.subscriptionStatus}
                        </Card.Link>
                      </div>
                  </div>
                </Col>
                <Col xs={12} lg={12} xl={12}>
                  <div className="d-flex align-items-center justify-content-between border-bottom py-3">
                      <div className="d-flex align-items-center h6 mb-0">
                        <span className="fw-bold">Payment Status</span>
                      </div>
                      <div>
                        <Card.Link href="#" className="d-flex align-items-center fw-bold">
                        {loggedUser.licenseDetails.paymentStatus}
                        </Card.Link>
                      </div>
                  </div>
                </Col>
                <Col xs={12} lg={12} xl={12}>
                  <div className="d-flex align-items-center justify-content-between border-bottom py-3">
                      <div className="d-flex align-items-center h6 mb-0">
                        <span className="fw-bold">Licenses</span>
                      </div>
                      <div>
                        <Card.Link href="#" className="d-flex align-items-center fw-bold">
                        {loggedUser.licenseDetails.allotedSeats}
                        </Card.Link>
                      </div>
                  </div>
                </Col>
                <Col xs={12} lg={12} xl={12}>
                  <div className="d-flex align-items-center justify-content-between border-bottom py-3">
                      <div className="d-flex align-items-center h6 mb-0">
                        <span className="fw-bold">Used Licenses</span>
                      </div>
                      <div>
                        <Card.Link href="#" className="d-flex align-items-center fw-bold">
                        {loggedUser.licenseDetails.usedSeats}
                        </Card.Link>
                      </div>
                  </div>
                </Col>
                <Col xs={12} lg={12} xl={12}>
                  <div className="d-flex align-items-center justify-content-between border-bottom py-3">
                      <div className="d-flex align-items-center h6 mb-0">
                        <span className="fw-bold">Registration</span>
                      </div>
                      <div>
                        <Card.Link href="#" className="d-flex align-items-center fw-bold">
                        {moment(loggedUser.licenseDetails.paidAt).format("MMMM D, Y")}
                        </Card.Link>
                      </div>
                  </div>
                </Col>
                <Col xs={12} lg={12} xl={12}>
                  <div className="d-flex align-items-center justify-content-between py-3">
                      <div className="d-flex align-items-center h6 mb-0">
                        <span className="fw-bold">Last Payment</span>
                      </div>
                      <div>
                        <Card.Link href="#" className="d-flex align-items-center fw-bold">
                        {moment(loggedUser.licenseDetails.updatedAt).format("MMMM D, Y")}
                        </Card.Link>
                      </div>
                  </div>
                </Col>
      
              </Row>
            </Card.Body>
          </Card>
        </Col>
{/* 
        <Col xs={12} lg={8}>
            <Card border="0" className="shadow mb-4">
              <Card.Body>
                <h5 className="mb-4 fw-extrabold">General information</h5>
                <Form>
                  <Row>
                    <Col md={6} className="mb-3">
                      <Form.Group id="firstName">
                        <Form.Label>First Name</Form.Label>
                        <Form.Control required type="text" placeholder="Enter your first name" />
                      </Form.Group>
                    </Col>
                    <Col md={6} className="mb-3">
                      <Form.Group id="lastName">
                        <Form.Label>Last Name</Form.Label>
                        <Form.Control required type="text" placeholder="Also your last name" />
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row className="align-items-center">
                    <Col md={6} className="mb-3">
                    <Form.Group id="lastName">
                        <Form.Label>Payment Status</Form.Label>
                        <Form.Control required type="text" placeholder="Payment Status" />
                      </Form.Group>
                    </Col>
                    <Col md={6} className="mb-3">
                      <Form.Group id="gender">
                        <Form.Label>Gender</Form.Label>
                        <Form.Select defaultValue="0" className="mb-0">
                          <option value="0">Gender</option>
                          <option value="1">Female</option>
                          <option value="2">Male</option>
                        </Form.Select>
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={6} className="mb-3">
                      <Form.Group id="email">
                        <Form.Label>Email</Form.Label>
                        <Form.Control required type="email" placeholder="name@company.com" />
                      </Form.Group>
                    </Col>
                    <Col md={6} className="mb-3">
                      <Form.Group id="phone">
                        <Form.Label>Phone</Form.Label>
                        <Form.Control required type="number" placeholder="+12-345 678 910" />
                      </Form.Group>
                    </Col>
                  </Row>

                  <h5 className="my-4 fw-extrabold">Location</h5>
                  <Row>
                    <Col sm={9} className="mb-3">
                      <Form.Group id="address">
                        <Form.Label>Address</Form.Label>
                        <Form.Control required type="text" placeholder="Enter your home address" />
                      </Form.Group>
                    </Col>
                    <Col sm={3} className="mb-3">
                      <Form.Group id="addressNumber">
                        <Form.Label>Number</Form.Label>
                        <Form.Control required type="number" placeholder="No." />
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col sm={4} className="mb-3">
                      <Form.Group id="city">
                        <Form.Label>City</Form.Label>
                        <Form.Control required type="text" placeholder="City" />
                      </Form.Group>
                    </Col>
                    <Col sm={4} className="mb-3">
                      <Form.Group className="mb-2">
                        <Form.Label>Select state</Form.Label>
                        <Form.Select id="state" defaultValue="0">
                          <option value="0">State</option>
                          <option value="AL">Alabama</option>
                          <option value="AK">Alaska</option>
                          <option value="AZ">Arizona</option>
                          <option value="AR">Arkansas</option>
                          <option value="CA">California</option>
                          <option value="CO">Colorado</option>
                          <option value="CT">Connecticut</option>
                          <option value="DE">Delaware</option>
                          <option value="DC">District Of Columbia</option>
                          <option value="FL">Florida</option>
                          <option value="GA">Georgia</option>
                          <option value="HI">Hawaii</option>
                          <option value="ID">Idaho</option>
                          <option value="IL">Illinois</option>
                          <option value="IN">Indiana</option>
                          <option value="IA">Iowa</option>
                          <option value="KS">Kansas</option>
                          <option value="KY">Kentucky</option>
                          <option value="LA">Louisiana</option>
                          <option value="ME">Maine</option>
                          <option value="MD">Maryland</option>
                          <option value="MA">Massachusetts</option>
                          <option value="MI">Michigan</option>
                          <option value="MN">Minnesota</option>
                          <option value="MS">Mississippi</option>
                          <option value="MO">Missouri</option>
                          <option value="MT">Montana</option>
                          <option value="NE">Nebraska</option>
                          <option value="NV">Nevada</option>
                          <option value="NH">New Hampshire</option>
                          <option value="NJ">New Jersey</option>
                          <option value="NM">New Mexico</option>
                          <option value="NY">New York</option>
                          <option value="NC">North Carolina</option>
                          <option value="ND">North Dakota</option>
                          <option value="OH">Ohio</option>
                          <option value="OK">Oklahoma</option>
                          <option value="OR">Oregon</option>
                          <option value="PA">Pennsylvania</option>
                          <option value="RI">Rhode Island</option>
                          <option value="SC">South Carolina</option>
                          <option value="SD">South Dakota</option>
                          <option value="TN">Tennessee</option>
                          <option value="TX">Texas</option>
                          <option value="UT">Utah</option>
                          <option value="VT">Vermont</option>
                          <option value="VA">Virginia</option>
                          <option value="WA">Washington</option>
                          <option value="WV">West Virginia</option>
                          <option value="WI">Wisconsin</option>
                          <option value="WY">Wyoming</option>
                        </Form.Select>
                      </Form.Group>
                    </Col>
                    <Col sm={4}>
                      <Form.Group id="zip">
                        <Form.Label>ZIP</Form.Label>
                        <Form.Control required type="tel" placeholder="ZIP" />
                      </Form.Group>
                    </Col>
                  </Row>
                  <div className="mt-3">
                    <Button variant="gray-800" type="submit" className="mt-2 animate-up-2">
                      Save All
                    </Button>
                  </div>
                </Form>
              </Card.Body>
            </Card>
        </Col> */}

      </Row>

    </>
  );
};
