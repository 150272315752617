import React, {useState, useContext} from "react";
import { useHistory } from "react-router-dom";
import { Routes } from "routes";
import Swal from 'sweetalert2';
import { Auth } from "aws-amplify"
import withReactContent from 'sweetalert2-react-content';
import { useDropzone } from "react-dropzone";
import { ArchiveIcon, CalendarIcon, ChatIcon, ChevronDownIcon, ClipboardListIcon, CloudUploadIcon, DocumentTextIcon, FireIcon, PlusIcon, PresentationChartBarIcon, ShoppingBagIcon, UsersIcon } from "@heroicons/react/solid";
import { Col, Row,  Alert, Card, Form, Button} from 'react-bootstrap';
import { AgencyNameformOptions } from "components/validator/validator";
import { UserContext } from "Context/UserContext";
import { AgencyContext } from "Context/AgencyContext";
import {useForm} from "react-hook-form";
import { ChoosePhotoWidget, ProfileCardWidget, NotificationsWidget } from "components/Widgets";
import { Link } from 'react-router-dom';

const SwalWithBootstrapButtons = withReactContent(Swal.mixin({
    customClass: {
      confirmButton: 'btn btn-primary me-3',
      cancelButton: 'btn btn-gray'
    },
    buttonsStyling: false
}));

export default () => {
    const [loggedUser, setLoggedUser] = useContext(UserContext);
    const [AgencyAgentInfo, setAgencyAgentInfo] = useContext(AgencyContext);
    const [creationStatus, setCreationStatus] = useState('initial');
    const [fetcherror, setFetchError] = useState(null);
    const token = loggedUser.user.signInUserSession.idToken.jwtToken;
    const history = useHistory();

    const { 
        register, 
        handleSubmit, 
        reset, 
        formState: { errors } 
      } = useForm(AgencyNameformOptions);


      const agencyCreated = (agency) => {
    
        const result = SwalWithBootstrapButtons.fire(
           `Agency: ${agency.agencyName}` , "Was Successfully Created!", "success"
        ).then(function() {
          history.push(Routes.CreateAgencyAgent.path);
        });

      };
      
     async function createAgency(data) {
       setCreationStatus('checkingSubscription');
        try {
           await fetch(`${process.env.REACT_APP_BASE_URL}/agency`, {
            method: "PUT",
            headers: {
              "Content-Type": "application/json",
              'Accept': 'application/json',
              'Authorization': `Bearer ${token}`
            },
            body: JSON.stringify(
              {
                "agencyName": data.agency_name
             }),
          })
          .then(res => {
            if (res.ok) return res.json()
            // If there is an error then make sure we catch that
            return res.json().then(e => Promise.reject(e))
          }).then(({ agency, message }) => {
            // setLoading(true);
            // agencyCreated(agency);
            getSubscriptions(agency);
        
          })
        } catch (error) {
           setFetchError(error.message);
        }
      }

      async function getSubscriptions(agency) {
        try {
          let phone = loggedUser.user.attributes.phone_number;
          phone = phone.substring(1);
          await fetch(`${process.env.REACT_APP_BASE_URL}/subscriptions?phone=%2B${phone}`, {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              'Accept': 'application/json',
              'Authorization': `Bearer ${token}`
            },
            body: JSON.stringify(),
          })
          .then(res => {
            if (res.ok) return res.json()
            // If there is an error then make sure we catch that
            return res.json().then(e => Promise.reject(e))
          }).then(({subscription}) => {
            //  localStorage.setItem("agencySubscription", JSON.stringify(subscription));
            setCreationStatus('linking');
             linkSubscriptions(subscription, agency);
          })
        } catch (error) {
          console.log(error);
           setFetchError(error.message);
        }
      }

      async function linkSubscriptions(data, agency) {
        try {
          const createAgentResponse = await fetch(`${process.env.REACT_APP_BASE_URL}/subscriptions/${data.subscriptionId}`, {
            method: "PUT",
            headers: {
              "Content-Type": "application/json",
              'Accept': 'application/json',
              'Authorization': `Bearer ${token}`
            },
            body: JSON.stringify({
              "agencyUuid": agency.uuid
            }),
          })
          .then(res => {
            if (res.ok) {
              setAgencyAgentInfo(agency);
              return checkAuthStatus(agency);
            }
            // If there is an error then make sure we catch that
            return res.json().then(e => Promise.reject(e))
          })
        } catch (error) {
          setFetchError(error.message);
        }
      }

      async function checkAuthStatus (agency) {
        try{
            const session = await Auth.currentSession();
            const user = await Auth.currentAuthenticatedUser();
            setLoggedUser({
              ...loggedUser,
              user: user
           });
           agencyCreated(agency)
        }catch(error){
          setFetchError(error.message);
        }
      }


  return (
    <>
      {
        fetcherror && <div className="text-center text-md-center text-danger">{fetcherror}</div>
      }
      <React.Fragment>
        <Col xs={12} lg={8} xl={8}>
          <Alert variant="success">
            Payment Successful
          </Alert>
        </Col>
      </React.Fragment>

      <Row className="mt-1">

        <Col xs={12} lg={8}>
        <Card border="0" className="shadow mb-4">
            <Card.Body>
                <h5 className="mb-3 fw-extrabold">Create Agency</h5>
                {
                  creationStatus === "initial" && <Form onSubmit={handleSubmit(createAgency)}>

                        <Row>
                            <Col md={12} className="mb-3">
                            <Form.Group id="agencyName">
                                <Form.Label>Agency Name</Form.Label>

                                {errors.agency_name && <label className="text-danger d-block mb-3">{errors.agency_name?.message}</label>}

                                <Form.Control
                                type="text" 
                                name="agency_name"
                                {...register("agency_name")}
                                placeholder="Agency Name" 
                                />
                            </Form.Group>
                            </Col>
                        </Row>
                    
                        <div className="mt-3">
                            <Button variant="gray-800" type="submit" className="mt-2 animate-up-2">
                                Create Agency
                            </Button>
                        </div>

                    </Form>
                  
                }

                {
                    creationStatus === "checkingSubscription" && <Row className="text-gray">

                      <Col xs={12} lg={12} xl={12}>
                          <Card border="0" className="">
                            <div className="border-bottom pb-2">
                              <h4 className="display-5 mb-2">...Confirming Subscription </h4> <div className="spinner-grow text-primary" role="status"></div>
                            </div>
                          </Card>
                      </Col>
                    </Row>
                }

                {
                    creationStatus === "linking" && <Row className="text-gray">

                      <Col xs={12} lg={12} xl={12}>
                          <Card border="0" className="">
                            <div className="border-bottom pb-2">
                              <h4 className="display-5 mb-2">...Creating Agency </h4> <div className="spinner-grow text-primary" role="status"></div>
                            </div>
                          </Card>
                      </Col>
                    </Row>
                }
                
            </Card.Body>
            </Card>
        </Col>

      </Row>
    </>
  );
};
