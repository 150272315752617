import { v4 as uuidv4 } from "uuid";
import moment from "moment-timezone";

import Profile1 from "assets/img/team/profile-picture-1.jpg"
import Profile2 from "assets/img/team/profile-picture-2.jpg"
import Profile3 from "assets/img/team/profile-picture-3.jpg"
import Profile4 from "assets/img/team/profile-picture-4.jpg"

export default [{
        "id": uuidv4(),
        "TeamAgents": { "teamUuid": '11caa379-20d9-4dbf-8b76-ee297f986f81', "agentUuid": '1b2a0c55-cb6a-43e6-84e3-1fafe7fec091' },
        "address1": "",
        "address2": "",
        "name": "Last Name",
        "avatarUrl": "",
        "businessName": "314 Hardwood Ave S",
        "city": "Toronto",
        "createdAt": "2022-02-24T13:29:55.527Z",
        "email": "qwqw@gmail.com",
        "firstName": "lewis",
        "lastName": "test",
        "phone": "+254796134021",
        "postal": "",
        "province": "",
        "updatedAt": "2022-02-24T13:29:55.527Z",
        "uuid": "1b2a0c55-cb6a-43e6-84e3-1fafe7fec091",
    },
    {
        "id": uuidv4(),
        "TeamAgents": { "teamUuid": '11caa379-20d9-4dbf-8b76-ee297f986f81', "agentUuid": '1b2a0c55-cb6a-43e6-84e3-1fafe7fec091' },
        "address1": "",
        "address2": "",
        "name": "Last Name",
        "avatarUrl": "",
        "businessName": "314 Hardwood Ave S",
        "city": "Toronto",
        "createdAt": "2022-02-24T13:29:55.527Z",
        "email": "qwqw@gmail.com",
        "firstName": "lewis",
        "lastName": "test",
        "phone": "+254796134021",
        "postal": "",
        "province": "",
        "updatedAt": "2022-02-24T13:29:55.527Z",
        "uuid": "1b2a0c55-cb6a-43e6-84e3-1fafe7fec091",
    },
    {
        "id": uuidv4(),
        "TeamAgents": { "teamUuid": '11caa379-20d9-4dbf-8b76-ee297f986f81', "agentUuid": '1b2a0c55-cb6a-43e6-84e3-1fafe7fec091' },
        "address1": "",
        "address2": "",
        "name": "Last Name",
        "avatarUrl": "",
        "businessName": "314 Hardwood Ave S",
        "city": "Toronto",
        "createdAt": "2022-02-24T13:29:55.527Z",
        "email": "qwqw@gmail.com",
        "firstName": "lewis",
        "lastName": "test",
        "phone": "+254796134021",
        "postal": "",
        "province": "",
        "updatedAt": "2022-02-24T13:29:55.527Z",
        "uuid": "1b2a0c55-cb6a-43e6-84e3-1fafe7fec091",
    },
    {
        "id": uuidv4(),
        "TeamAgents": { "teamUuid": '11caa379-20d9-4dbf-8b76-ee297f986f81', "agentUuid": '1b2a0c55-cb6a-43e6-84e3-1fafe7fec091' },
        "address1": "",
        "address2": "",
        "name": "Last Name",
        "avatarUrl": "",
        "businessName": "314 Hardwood Ave S",
        "city": "Toronto",
        "createdAt": "2022-02-24T13:29:55.527Z",
        "email": "qwqw@gmail.com",
        "firstName": "lewis",
        "lastName": "test",
        "phone": "+254796134021",
        "postal": "",
        "province": "",
        "updatedAt": "2022-02-24T13:29:55.527Z",
        "uuid": "1b2a0c55-cb6a-43e6-84e3-1fafe7fec091",
    }
]