import React, { useState, createContext } from 'react';

export const AgencyContext = createContext();

export const AgencyContextProvider = props => {
    const [AgencyAgentInfo, setAgencyAgentInfo] = useState({})

    return (
       <AgencyContext.Provider value={[AgencyAgentInfo, setAgencyAgentInfo]}>
          {props.children}
       </AgencyContext.Provider>
    );
}