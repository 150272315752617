import React, { useState, createContext } from 'react';

export const UserContext = createContext();

export const UserProvider = props => {
    const [loggedUser, setLoggedUser] = useState({
        isAuthenticated: false,
        user: null,
        licenseDetails: null
    })

    return (
       <UserContext.Provider value={[loggedUser, setLoggedUser]}>
          {props.children}
       </UserContext.Provider>
    );
}