import React from "react";

function FormErrors(props) {
    if (props.formerrors === 'An account with the given phone_number already exists.') {
      return (
        <div className="error container help text-danger pb-3">
          <div className="row justify-content-center">
            Sorry, this number is already registered with an existing account.
          </div>
        </div>
      );
    } else if (props.formerrors  && props.formerrors !== 'An account with the given phone_number already exists.'){
      return (
        <div className="error container help text-danger pb-3">
          <div className="row justify-content-center">
            {props.formerrors}
          </div>
        </div>
      );
    } else {
      return <div />;
    }
  }
  
export default FormErrors;